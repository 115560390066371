
.pageErrorWrap {
    min-height: calc(var(--doc-height) - var(--nav-height));
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: rgb(var(--background));
}
/* 
.pageErrorWrap .errorWrap {
    background-color: var(--card);
    width: 450px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    margin: auto 0;
    align-items: center;

} */

.errorWrap {
    background-color: rgb(var(--alpha) / 5%);
    width: 450px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    margin: auto 0;
    align-items: center;
}

.errorWrap .errorTitleWrap {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 0;
}

.errorWrap .title {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: white;
}

.errorWrap .description {
    font-weight: 400;
    margin-bottom: 12px;
    text-align: center;
    color: var(--gamma-text);
    font-size: 14px;
    width: unset;
    margin: 12px 0 16px 0;
}

.errorWrap .filled {
    padding: 0.5em 1em;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: rgb(var(--primary));
    color: white;
    font-weight: 400;
    font-size: 14px;
}
