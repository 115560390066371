/* base select */
.select{
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 1px solid var(--border-00);
    color: white;
    border-radius: 5px;
    height: 36px;
    font-size: 13px;
    font-weight: 400;
    width: 100%;
    text-align: left;
    position: relative;
    outline: none;
    transition-property: background-color, color, border;
    transition-duration: 100ms;
    padding: 0;
    overflow: hidden;
}

div.select{
    cursor: text;
    /* padding without changgin width */
    /* display: inline-block;
    padding: 0 8px 0 8px; */
}

div.select:hover input.selectInput::placeholder{
    color: rgb(var(--primary));
}

.select input.selectInput{
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    padding: 0 8px 0 8px;
    height: 100%;
}

button.select{
    cursor: pointer;
}

/* select active styles */

.select:hover, .select.isOpen{
    background-color: rgb(var(--primary) / 5%);
    color: rgb(var(--primary));
    border: 1px solid rgb(var(--primary) / 5%);
}

.select:hover .selectText, .select.isOpen .selectText, .select:hover .selectText.placeholder, .select.isOpen .selectText.placeholder{
    color: rgb(var(--primary));
}

.select:hover .arrowWrap, .select.isOpen .arrowWrap{
    color: rgb(var(--primary) / 80%);
}

.select.numeric:hover:not(.isOpen){
    background-color: rgb(var(--primary) / 5%);
    border: 1px solid rgb(var(--primary) / 5%);
}

.select.numeric:hover:not(.isOpen) .selectInput{
    color: rgb(var(--primary));
}

.select.numeric.isOpen{
    background-color: transparent;
    border: 1px solid rgb(var(--primary));
    box-shadow: 0 0 5px 3px rgb(var(--primary) / 10%);
}

.select.right{
    width: 250px;
}

.select .selectText{
    transition: color 100ms;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 24px 0 8px;

}

.select .selectText.placeholder{
    color: var(--input-placeholder);
}

.select .arrowWrap{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0px;
    top: 0;
    bottom: 0;
    border-radius: 4px;
    color: rgb(255 255 255 / 50%);
    transition: color 200ms;
    width: 24px;
}


.dropdown{
    background-color: rgb(var(--background));
    border: 1px solid rgb(255 255 255 / 5%);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: 300px;
    max-height: 350px;
    position: relative;
    overflow: hidden;
}

.dropdown .linearProgressWrap{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 4px;
    z-index: 2;
}

.dropdown .search{
    background-color: rgb(255 255 255 / 2.5%);
    border-radius: 8px;
    min-height: 36px;
    padding: 0 8px;
    outline: none;
    color: white;
    border: 1px solid transparent;
    transition-property: background-color, border;
    transition-duration: 200ms;
    margin: 8px 8px 4px 8px;
}

.dropdown .dropdownHeader{
    display: flex;
    align-items: center;
    padding: 4px 8px 4px 8px;
    margin-bottom: 4px;
}

.dropdown .dropdownHeader .dropdownButton{
    border: none;
    padding: 4px 6px;
    font-size: 11px;
    cursor: pointer;
    transition-property: color, background-color;
    transition-duration: 200ms;
    border-radius: 4px;
}

.dropdown .dropdownHeader .dropdownButton:hover{
    background-color: rgb(var(--primary) / 10%);
    color: rgb(var(--primary));
}

.dropdown .search:hover{
    border: 1px solid rgb(var(--primary) / 50%);
    background-color: rgb(var(--primary) / 5%);
}

.dropdown .search:focus{
    border: 1px solid rgb(var(--primary) / 50%);
    background-color: rgb(var(--primary) / 5%);
}

.dropdown .dropdownContent{
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    gap: 4px;
    height: 100%;
    padding-bottom: 8px;
    margin-top: 8px;
}

.dropdown .dropdownContent::-webkit-scrollbar{
    width: 8px;
    height: 8px;
}

.dropdown .dropdownContent::-webkit-scrollbar-track{
    background-color: rgb(var(--alpha) / 5%);
}

.dropdown .dropdownContent::-webkit-scrollbar-thumb{
    background-color: rgb(var(--alpha) / 15%);
    border-radius: 4px;
}

.dropdown .dropdownContent::-webkit-scrollbar-corner { 
    background-color: rgb(var(--alpha) / 5%)
}

.dropdown .dropdownContent .propertyValueOption.highlighted{
    background-color: rgb(var(--primary) / 5%);
    color: rgb(var(--primary));
}

.dropdown .dropdownContent .propertyValueOption.highlighted .icon{
    color: rgb(var(--primary));
}

.dropdown .add.notDisabled{
    background-color: rgb(var(--primary));
    cursor: pointer;
}

.dropdown .add{
    width: 100%;
    text-align: center;
    padding: 12px 0;
    background-color: rgb(var(--primary) / 50%);
    cursor: default;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: none;
    transition: background-color 200ms;
}

.dropdown .add.notDisabled:hover{
    background-color: rgb(var(--primary) / 80%);
}

.loading{
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.loading .loadingText{
    /* color: rgb(var(--primary)); */
    font-size: 13px;
}

.propertyValueOption{
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    padding: 8px;
    border-radius: 4px;
    margin: 0 8px;
    border: none;
    outline: none;
}

.propertyValueOption:hover{
    background-color: rgb(var(--primary) / 5%);
    color: rgb(var(--primary));
}

.propertyValueOption .checkboxWrap{
    min-width: 16px;
    min-height: 16px;
    width: 16px;
    height: 16px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(255 255 255 / 10%);
    color: white;
}

.propertyValueOption .checkboxWrap.checked{
    background-color: rgb(var(--primary));
    border: none;
}

.propertyValueOption .optionLabel{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}