path.linkPath {
    cursor: pointer;
}

.nodeCardV3{
    display: flex;
    flex-direction: column;
    margin: 16px 0 12px 0;
    font-size: 12px;
    position: relative;
    width: fit-content;
    max-width: 175px;
    overflow: hidden;
}

.nodeCardV3 .nodeCardInner{
    display: flex;
    gap: 4px;
    align-items: flex-start;
    z-index: 2;
}

.nodeCardV3 .nodeRect{
    background-color: rgb(var(--primary));
    width: 48px;
    margin: 4px;
    margin-top: 12px;
    border-radius: 4px;
    cursor: pointer;
}

.nodeCardV3 .nodeRect.target{
    box-shadow: 0 0 0 2px rgb(var(--background)), 0 0 0 3px rgb(var(--primary) / 60%);
}

.nodeCardV3 .nodeRect:hover{
    box-shadow: 0 0 0 2px rgb(var(--background)), 0 0 0 3px rgb(var(--primary) / 60%);
}

.nodeCardV3 .nodeRect.dropoff.target{
    box-shadow: 0 0 0 2px rgb(var(--background)), 0 0 0 3px rgb(255 255 255 / 60%);
}

.nodeCardV3 .nodeRect.dropoff:hover{
    box-shadow: 0 0 0 2px rgb(var(--background)), 0 0 0 3px rgb(255 255 255 / 60%);
}

.nodeCardV3.highlighted {
    opacity: 1;
}

.nodeCardV3:not(.highlighted).dimmed {
    opacity: 0.2;
}


.nodeCardIconWrap {
    color: rgb(255 255 255 / 40%);
    width: 14px;
    height: 14px;
    min-width: 14px;
    min-height: 14px;
    margin-top: 1px;
}

.nodeCardIconWrap svg {
    width: 100%;
    height: 100%;
}

.nodeCardOne {
    display: flex;
    align-items: center;
}

.nodeCardTwo {
    display: flex;
    align-items: center;
}

.nodeValueColor {
    color: rgb(var(--primary));
    font-weight: 500;
}

.nodeValueColor.dropoff {
    color: rgb(255 255 255 / 50%);
}

.nodeNumber {
    color: rgb(255 255 255 / 50%);
}

.nodeTextWrap {
    display: flex;
    flex-direction: column;
    gap: 2px;
    overflow: hidden;
}

.nodeLabel {
    color: rgb(255 255 255);
    font-weight: 500;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 16px;
}

.nodeSub {
    color: rgb(255 255 255 / 50%);
    font-weight: 400;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding-right: 16px;
}

.nodeTri {
    color: rgb(255 255 255 / 50%);
    font-weight: 400;
}

/* 
.bookmarkedPath {
    stroke: rgb(255 255 255 / 50%);
    z-index: 1;
}

.bookmarkedNode {
    fill: rgb(255 255 255 / 100%);
} */

.nodeValsWrap {
    display: flex;
    align-items: center;
    gap: 6px;
    line-height: 1em;
    /* font-weight: 300; */
}

.nodeValsWrap .nodePct {
    font-family: var(--mono-font);

}

.nodeValsWrap .nodeValue {
    color: rgb(255 255 255 / 50%);
    /* font-weight: 400; */
    font-family: var(--mono-font);
}

.bookmarkMenu {
    background: rgb(255 255 255 / 5%);
    border: 1px solid rgb(255 255 255 / 2.5%);
    padding: 8px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 50px;
}

.bookmarkMenu .bookmarkMenuOption {
    outline: none;
    display: flex;
    align-items: center;
    padding: 8px;
    border: none;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    cursor: pointer;
}

.bookmarkMenu .bookmarkMenuOption.selected{
    background-color: rgb(var(--primary) / 10%);
    color: rgb(var(--primary))
}

.bookmarkMenu .bookmarkMenuOption .bookmarkMenuIconWrap {
    width: 18px;
    height: 18px;
    color: rgb(255 255 255 / 50%);
    margin-right: 8px;
}

.bookmarkMenu .bookmarkMenuOption.selected .bookmarkMenuIconWrap {
    color: rgb(var(--primary));
}

.bookmarkMenu .bookmarkMenuOption .bookmarkMenuIconWrap svg {
    width: 100%;
    height: 100%;
}

.pathsWrap{
    display: flex;
}

.stepColumn{
    min-width: 175px;
    margin-right: 15px;
}

.stepColumn:last-child{
    /* margin-right: 0; */
}

.sankeyLinks{
    height: 100%;
    left: 0px;
    overflow: visible;
    position: absolute;
    top: 0px;
    width: 100%;
    z-index: 1;
}

.sidebarWrap{
    position: absolute;
    z-index: 10;
    background-color: rgb(var(--background));
    right: 0;
    top: var(--nav-height);
    width: 400px;
    height: calc(var(--doc-height) - var(--nav-height));
    box-shadow: rgba(0, 0, 0, 0.2) -16px 0px 10px;
}

.sidebarWrap .sidebarInner{
    background-color: rgb(255 255 255 / 2.5%);
    height: 100%;
    display: flex;
    flex-direction: column;
    /* position: sticky; */
}

.sidebarWrap .sidebarInner .sidebarTop{
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(255 255 255 / 5%);
}

.sidebarWrap .sidebarInner .sidebarTop .sidebarTitle{
    font-size: 16px;
}

.sidebarWrap .sidebarInner .sidebarTop .sidebarClose{
    border: none;
    cursor: pointer;
    width: 28px;
    height: 18px;
}

.sidebarWrap .sidebarInner .sidebarTop .sidebarClose svg{
    width: 100%;
    height: 100%;
}

.sidebarContent{
    height: 100%;
    flex: 1;
    /* overflow: auto; */
    overflow: hidden;
}

.sidebarLoading{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.generalSidebar{
    overflow-y: auto;
}

.generalSidebar::-webkit-scrollbar{
    width: 8px;
}

.generalSidebar::-webkit-scrollbar-track{
    background-color: rgb(var(--alpha) / 5%);
}

.generalSidebar::-webkit-scrollbar-thumb {
    background-color: rgb(var(--alpha) / 15%);
    border-radius: 4px;
}

.generalSidebar::-webkit-scrollbar-corner {
    background-color: rgb(var(--alpha) / 5%)
} 

.sidebarRow{
    border-bottom: 1px solid rgb(255 255 255 / 5%);
    position: relative;
    transition: background-color 100ms;
    padding: 16px;
}

.sidebarRowInner{
    display: flex;
    justify-content: space-between;
}

.userTag{
    font-size: 10px;
    background-color: rgb(255 255 255 / 10%);
    border-radius: 999px;
    padding: 4px 6px;
    color: white;
}

.sidebarRow:hover{
    background-color: rgb(255 255 255 / 2.5%);
}

.sidebarRow .sidebarRowSub{
    color: rgb(255 255 255 / 50%);
    font-weight: 400;
}

.sidebarRowLink{
    display: flex;
    align-items: center;
    gap: 8px;
}

.explorerAbsWrap{
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
}

.explorerAbs{
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 100ms;
}

.explorerAbs:hover{
    color: rgb(var(--primary));
}

.explorerAbs svg{
    width: 100%;
    height: 100%;
}

.identicon {
    width: 24px !important;
    height: 24px !important;
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    border: 1px solid rgb(var(--alpha) / 5%);
    overflow: hidden;
    background-color: rgb(var(--alpha) / 2.5%);
}

.identiconSmall {
    width: 16px !important;
    height: 16px !important;
    min-width: 16px;
    min-height: 16px;
    border-radius: 50%;
    border: 1px solid rgb(var(--alpha) / 5%);
    overflow: hidden;
    background-color: rgb(var(--alpha) / 2.5%);
}

.inlineUserRowFlex{
    display: flex;
    gap: 4px;
    align-items: center;
}

.userSidebar{
    height: 100%;
    display: flex;
    flex-direction: column;
}

.userTop{
    padding: 16px;
    border-bottom: 1px solid rgb(255 255 255 / 5%);
}

.userTitle{
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    font-size: 16px;
}

.stepsIcon{
    background-color: rgb(var(--primary));
    width: 14px;
    height: 14px;
    border-radius: 2px;
}

.userValsWrap{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.userValsWrap .userSub{
    color: rgb(255 255 255 / 50%);
    font-weight: 400;
    margin-top: 4px;
}

.userValsWrap .userSubLink:hover{
    text-decoration: underline;
}

.userValsWrap .userVal{
    color: white;
    font-weight: 400;
    margin-top: 4px;
}

.userButtons{
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    margin-top: 16px;
}

.userButton .userIcon{
    width: 22px;
    height: 22px;
    color: rgb(255 255 255 / 50%);
    transition: color 200ms;

}

.userButton .userIcon svg{
    width: 100%;
    height: 100%;
}

.userButtons .userButton{
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 11px;
    border-radius: 4px;
    gap: 4px;
    transition-property: background-color;
    color: rgb(255 255 255 / 50%);
    cursor: pointer;
    padding: 4px;
}

.userButtons .userButton:hover{
    background-color: rgb(var(--primary) / 5%);

}

.userButtons .userButton:hover .userIcon{
    color: rgb(var(--primary));
}