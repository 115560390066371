.filtersFlex{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.filter {
    display: flex;
    align-items: center;
    gap: 8px;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}


.actions button {
    border: none;
    cursor: pointer;
    color: rgb(255 255 255);
    padding: 4px;
    transition: background-color 200ms;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.actions button:hover {
    background-color: rgb(var(--primary) / 10%);
    color: rgb(var(--primary));
}

.actions button svg {
    width: 100%;
    height: 100%;
}

.linePretext{
    color: rgb(255 255 255 / 50%);
    min-width: 40px;
    text-align: right;
    margin-right: 4px;
}

.add {
    display: flex;
    align-items: center;
    border: none;
    gap: 4px;
    border-radius: 4px;
    color: rgb(var(--primary));
    padding: 4px 8px;
    cursor: pointer;
    width: fit-content;
    transition-property: background-color, color;
    transition-duration: 200ms;
}

.add.only{
    background-color: rgb(var(--primary) / 5%);
}

.add.only:hover{
    background-color: rgb(var(--primary) / 10%);
}

.add:hover {
    background-color: rgb(var(--primary) / 5%);
}

.add .plus {
    font-size: 18px;
}
