.customTooltip:global(.MuiTooltip-tooltip) {
    background-color: rgb(var(--background));
    border-radius: 4px;
    font-family: Inter, -apple-system;
    /* max-width: unset; */
    margin: 0;
    padding: 0;
    word-wrap: break-word;
    font-weight: 400;
    position: relative;
    border: 1px solid var(--border-01);
    font-size: 13px;
    color: var(--alpha-text);
    max-width: 200px;
}

.tooltipWrap{
    padding: 8px 8px;
    background-color: var(--ndp02);
}

.customTooltipArrow:global(.MuiTooltip-arrow) {
    color: var(--ndp02);
}


.customTooltipPopper.light{
    box-shadow: rgb(0 0 0 / 10%) 0px 4px 12px;
}

.customTooltipPopper.top{
    margin-bottom: 14px;
}

.customTooltipPopper.bottom{
    margin-bottom: 14px;
}

.tooltipInner{
    font-size: 12px;
    text-align: center;
}