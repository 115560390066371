.title {
    margin-bottom: 8px;
}

.topBar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}

.topBar .search{
    height: 32px;
    background-color: rgb(255 255 255 / 0.02);
    border: 1px solid rgb(255 255 255 / 0);
}

.barAboveTable{
    display: flex;
    justify-content: space-between;
}

.barAboveTable .barAboveTableRight{
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
}

.outline{
    font-size: 13px;
    /* border: 1px solid rgb(255 255 255 / 0.1); */
    border: none;
    border-radius: 4px;
    /* padding: 8px; */
    height: 32px;
    padding: 0 12px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    white-space: nowrap;
    cursor: pointer;
    transition-property: background-color, border-color;
    transition-duration: 200ms;
    outline: none;
}

.outline.disabled{
    background-color: rgb(255 255 255 / 0.04);
    /* border: 1px solid rgb(255 255 255 / 0.04); */
    color: rgb(255 255 255 / 0.5);
    cursor: not-allowed;
}

.outline:not(.disabled):hover{
    background-color: rgb(var(--primary) / 5%);
    color: rgb(var(--primary));
    /* border: 1px solid rgb(var(--primary) / 5%); */
}

.filled{
    font-size: 13px;
    background-color: rgb(var(--primary));
    border: none;
    padding: 0 12px;
    height: 32px;
    border-radius: 4px;
    white-space: nowrap;
    cursor: pointer;
    transition-property: background-color, border-color;
    transition-duration: 200ms;
    outline: none;
}

.filled:hover{
    background-color: rgb(var(--primary) / 80%);
}

.filled.disabled{
    background-color: rgb(var(--primary) / 50%);
    color: rgb(255 255 255 / 0.5);
    cursor: not-allowed;
}

.chains{
    display: flex;
    align-items: center;
    gap: 4px;
}

.platformIcon{
    width: 18px;
    height: 18px;
}

.eventBottom{
    display: flex;
    justify-content: center;
    padding: 32px 0;
}

.eventBottomInner{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loadBtn{
    border: 1px solid rgb(255 255 255 / 0.1);
    width: fit-content;
    color: rgb(255 255 255);
    padding: 12px 16px;
    border-radius: 4px;
    white-space: nowrap;
    cursor: pointer;
    transition-property: background-color, border-color;
    transition-duration: 200ms;
    outline: none;
    margin-top: 16px;
}

.loadBtn:hover{
    background-color: rgb(255 255 255 / 0.05);
    border: 1px solid rgb(255 255 255 / 0.05);
}

.stockLoadingButton{
    display: flex;
    align-items: center;
    gap: 8px;
}

/* table */


.tableWrap{
    border: 1px solid rgb(255 255 255 / 0.05);
    border-radius: 4px;
}

.tableHeaders{
    background-color: rgb(255 255 255 / 0.025);
    border-bottom: 1px solid rgb(255 255 255 / 0.025);
    width: fit-content;
}

.tableHeaderCell{
    font-size: 13px;
    color: rgb(255 255 255 / 0.5);
}

.tableRow{
    border-bottom: 1px solid rgb(255 255 255 / 0.05);
    width: fit-content;
}

.tableRow:last-child{
    border-bottom: none;
}

.tableCell{
    padding: 0 16px;
}

.accountWrap{
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 8px;
    border-radius: 4px;
}

.accountWrap:hover{
    background-color: rgb(255 255 255 / 0.05);
}

.accountIconWrap{
    min-width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
}

.accountIconWrap .accountIcon{
    width: 100%!important;
    height: 100%!important;
}

.accountText{
    font-weight: 400;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.tableTop{
    background-color: rgb(255 255 255 / 0.025);
    padding: 12px 16px 12px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tableTop .tableTopLeft{
    display: flex;
    align-items: center;
    gap: 8px;
}

.tableTop .searchWrap{
    position: relative;
    width: 300px;
}

.tableTop .searchWrap .searchIconWrap{
    position: absolute;
    top: 50%;
    left: 12px;
    width: 12px;
    height: 12px;
    transform: translateY(-50%);
    color: rgb(255 255 255 / 0.3);
}

.tableTop .searchWrap .searchIconWrap svg{
    width: 100%;
    height: 100%;
}

.tableTop .tableTopButton{
    border: none;
    background-color: rgb(255 255 255 / 5%);
    color: white;
    border-radius: 5px;
    height: 36px;
    font-size: 13px;
    font-weight: 400;
    outline: none;
    transition-property: background-color, color, border;
    transition-duration: 100ms;
    overflow: hidden;
    padding: 0 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    border:  1px solid rgb(255 255 255 / 0%);
}

.tableTop .tableTopButton:hover{
    /* background-color: rgb(255 255 255 / 0.075); */
    border: 1px solid rgb(var(--primary) / 70%);
    /* box-shadow: 0 0 5px 3px rgb(var(--primary) / 10%); */
}

.tableTop .tableTopButton .tableTopButtonIconWrap{
    width: 15px;
    height: 15px;
}

.tableTop .tableTopButton .tableTopButtonIconWrap svg{
    width: 100%;
    height: 100%;
}

.tableTop .searchInput{
    border: 1px solid rgb(255 255 255 / 0%);
    background-color: rgb(255 255 255 / 4%);
    color: white;
    border-radius: 5px;
    height: 36px;
    font-size: 13px;
    font-weight: 400;
    outline: none;
    transition-property: background-color, color, border;
    transition-duration: 100ms;
    overflow: hidden;
    padding: 0 8px;
    padding-left: 30px;
    width: 100%;
}

.tableTop .searchInput::placeholder{
    color: rgb(255 255 255 / 0.3);
}

.tableTop .searchInput:hover{
    border: 1px solid rgb(var(--primary) / 70%);
}

.tableTop .searchInput:focus{
    background-color: transparent;
    border: 1px solid rgb(var(--primary) / 70%);
    box-shadow: 0 0 5px 3px rgb(var(--primary) / 10%);
}

.rowButton{
    border: 1px solid rgb(255 255 255 / 0.025);
    background-color: rgb(255 255 255 / 0.025);
    padding: 6px 8px;
    display: flex;
    align-items: center;
    gap: 8px;
    border-radius: 0px;
    transition-property: background-color, border-color;
    transition-duration: 100ms;
    font-size: 13px;
}

.rowButton:hover{
    background-color: rgb(255 255 255 / 0.05);
    border: 1px solid rgb(255 255 255 / 0.05);
    cursor: pointer;
}

.rowButton .rowButtonIcon{
    width: 14px;
    height: 14px;
}

.rowButton .rowButtonIcon svg{
    width: 100%;
    height: 100%;
}

.rowButton .rowButtonText{
    font-size: 13px;
}

.contactCell{
    display: flex;
    align-items: center;
    gap: 8px;
}


.contactSourceMore{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 500;
    color: rgb(255 255 255 / 0.5);
    margin-left: -2px;
}

.syncingTag{
    padding: 4px 8px;
    background-color: rgb(var(--green) / 0.15);
    color: rgb(var(--green));
    border-radius: 999px;
    display: flex;
    width: fit-content;
}

.syncingTag .syncingTagIcon {
    width: 13px;
    height: 13px;
}

.syncingTag .syncingTagIcon svg{
    width: 100%;
    height: 100%;
}

.syncingTag .syncingTagText{
    font-size: 12px;
    font-weight: 500;
    margin-left: 4px;
}