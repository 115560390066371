/* Navbar */
.navbar {
    position: sticky;
    z-index: 2;
    top: 0;
    background-color: transparent;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid var(--border-00);
}

.navbarInner{
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 32px;
  }


.left {
  display: flex;
  align-items: center;
  width: 100%;
}


.right {
  display: flex;
  align-items: center;
  gap: 16px;
}

.logoWrap {
    min-height: var(--nav-height);
    display: flex;
    align-items: center;
    padding: 0 16px;
}

.logo {
    width: auto;
    height: 18px;
    object-fit: contain;
    object-position: 0 0;
}

.ghostLinkLogo{
    display: flex;
    align-items: center;
  }


