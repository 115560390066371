.header{
    min-height: var(--header-size);
    width: 100%;
    background-color: rgb(var(--background));
    background-color: rgb(196 63 92);
    border-bottom: 1px solid rgb(255 255 255 / 0.1);
    box-shadow: rgba(0, 0, 0, 0.16) 4px 0px 4px;
    display: flex;
    align-items: center;
    padding: 0 32px;
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    /* min-width: 1300px; */
}

.header .icon{
    width: 24px;
    height: 24px;
    object-fit: contain;
    margin-right: 32px;
}

.header .section{
    width: calc(100%/3);
}

.header .navWrap{
    display: flex;
    align-items: center;
    gap: 12px;
}

.header .headerLink{
    padding: 8px;
    cursor: pointer;
    border-radius: 4px;
    border: none;
    transition-property: background-color;
    transition-duration: 100ms;
    display: flex;
    align-items: center;
    gap: 8px;
}

.header .headerLinkFlex{
    display: flex;
    align-items: center;
    gap: 8px;
}

.header .headerLink .headerLinkSingleIcon{
    width: 18px;
    height: 18px;
    display: flex;
    align-items: center;
}

.header .headerLink .headerLinkSingleIcon svg{
    width: 100%;
    height: 100%;
}

.header .headerLink .headerLinkIconWrap{
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
}

.header .headerLink .headerLinkIconWrap svg{
    width: 100%;
    height: 100%;
}
/* 
.header .navWrap button.headerLink{
} */

.header .headerLink:hover{
    background-color: rgb(0 0 0 / 10%);

}

.header .navWrap .headerLink.active{
    background-color: rgb(0 0 0 / 10%);

}

.header .searchWrap{
    position: relative;
    width: calc(100% - 64px);
}

.header .searchWrap .searchIconWrap{
    width: 12px;
    height: 12px;
    position: absolute;
    left: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: rgb(255 255 255 / 40%);
}

.header .searchWrap .searchIconWrap svg{
    width: 100%;
    height: 100%;
}

.header .searchWrap .searchInputButton{
    border: 1px solid rgb(255 255 255 / 2.5%);
    /* background-color: rgb(255 255 255 / 2.5%); */
    background-color: rgb(0 0 0 / 10%);
    color: rgb(255 255 255 / 40%); 
    border: none;
    border-radius: 5px;
    height: 36px;
    font-size: 13px;
    font-weight: 400;
    outline: none;
    transition-property: background-color, color, border;
    transition-duration: 100ms;
    overflow: hidden;
    width: 100%;
    padding: 0 12px;
    padding-left: 32px;
    text-align: left;
    cursor: pointer;
}

.header .searchWrap .commandWrap{
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    color: rgb(255 255 255 / 40%);

}

.header .workspaceWrap{
    display: flex;
    align-items: center;
    border: none;
    gap: 8px;
    padding: 8px 12px;
    border-radius: 4px;
    cursor: pointer;
}

.header .workspaceWrap:hover{
    background-color: rgb(0 0 0 / 10%);
}

.header .workspaceWrap .workspaceIconWrap{

}

.header .workspaceWrap .workspaceIconWrap .workspaceIconImage{
    display: flex;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    overflow: hidden;
    align-items: center;
    justify-content: center;
}

.header .workspaceWrap .workspaceIconWrap .workspaceIconImage img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.header .workspaceWrap .workspaceIconText{
    color: white;
    display: flex;
    height: 24px;
    width: 24px;
    background-color: #0093E9;
    background-image: linear-gradient(160deg, #0093E9 0%, #80D0C7 100%);
    border-radius: 50%;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    font-size: 11px;
    font-weight: 600;
    line-height: 1em;
}

.header .workspaceWrap .workspaceTextWrap{
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin-right: 12px;
    text-align: left;
}

.header .workspaceWrap .workspaceTextWrap .workspaceTitle{
    font-weight: 500;
}

.header .workspaceWrap .workspaceTextWrap .workspaceSubtitle{
    font-weight: 400;
    color: rgb(255 255 255 / 50%);
    white-space: nowrap;

}

.header .workspaceWrap .workspaceDropdownIconWrap{
    color: white;
    width: 10px;
    height: 10px;
}

.header .workspaceWrap .workspaceDropdownIconWrap svg{
    width: 100%;
    height: 100%;
}

.settingsDropdown{
    display: flex;
    flex-direction: column;
    width: 200px;
}

.settingsDropdown .settingsDropdownSection{
    padding: 12px 16px;
    border-bottom: 1px solid rgb(255 255 255 / 5%);

}

.settingsDropdown .settingsDropdownSection .settingsDropdownSectionTitle{
    font-size: 12px;
    color: rgb(255 255 255 / 40%);
    margin-bottom: 4px;
}

.settingsDropdown .settingsDropdownSection .settingsDropdownSectionTitle.padded{
    margin: 8px 0px 4px 16px;
}

.settingsDropdown .settingsDropdownLink{
    display: flex;
    gap: 8px;
    align-items: center;
    padding: 12px 8px;
    transition-property: background-color;
    transition-duration: 100ms;
    margin: 0 8px;
    border-radius: 4px;
}

.settingsDropdown .settingsDropdownLink:hover{
    background-color: rgb(255 255 255 / 5%);
}

.settingsDropdown .settingsDropdownLink .settingsDropdownLinkIcon{
    width: 12px;
    height: 12px;
    display: flex;
    align-items: center;
}

.settingsDropdown .settingsDropdownLink .settingsDropdownLinkIcon svg{
    width: 100%;
    height: 100%;
}

.settingsDropdown .logoutBtn{
    color: rgb(var(--alpha-red));
    border: none;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 8px;
    cursor: pointer;
    border-radius: 4px;
}

.settingsDropdown .logoutBtn:hover{
    color: rgb(var(--alpha-red));
    background-color: rgb(var(--alpha-red) / 10%);
}

.settingsDropdown .logoutBtn.disabled{
    color: white;
    cursor: not-allowed;
    background-color: rgb(255 255 255 / 5%);
}

.settingsDropdown .logoutBtn.disabled:hover{
    background-color: rgb(255 255 255 / 5%);
}

.settingsDropdown .logoutBtn .logoutBtnIcon{
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
}

.settingsDropdown .logoutBtn .logoutBtnIcon svg{
    width: 100%;
    height: 100%;
}

.settingsDropdown .settingsDropdownPlanWrap{
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.settingsDropdown .settingsDropdownPlanWrap .settingsDropdownPlanText{
    color: rgb(255 255 255 / 50%);
}

.reportDropdown{
    display: flex;
    flex-direction: column;
    width: 250px;
    padding: 12px;
}

.reportDropdown .reportDropdownItem{
    display: flex;
    align-items: center;
    transition-property: background-color;
    transition-duration: 100ms;
    padding: 16px;
    gap: 16px;
    border-radius: 8px;
}

.reportDropdown .reportDropdownItem.active{
    background-color: rgb(255 255 255 / 5%);
}

.reportDropdown .reportDropdownItem:hover{
    background-color: rgb(255 255 255 / 5%);
}


.reportDropdown .reportDropdownItem .reportDropdownItemText{
    display: flex;
    flex-direction: column;
}

.reportDropdown .reportDropdownItem .reportDropdownItemText .reportDropdownItemTitle{
    font-size: 13px;
    margin-bottom: 4px;
    color: white;
}

.reportDropdown .reportDropdownItem .reportDropdownItemText .reportDropdownItemDescription{
    font-size: 12px;
    color: rgb(255 255 255 / 40%);
}


.progressTitleWrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.progressTitle {
    color: rgb(255 255 255);
    font-weight: 400;
}

.progressText {
    color: rgb(252 77 115);
    font-size: 12px;
}

.progressOuter {
    min-height: 4px;
    position: relative;
    background-color: rgb(var(--primary) / 20%);
    width: 100%;
    margin: 4px 0;
    border-radius: 2px;
}

.progress {
    height: 4px;
    border-radius: 2px;
    background-color: rgb(var(--primary));
}


.trialButtonIconWrap{
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    position: relative;
}

.trialButtonIconWrap .trialButtonIconBack{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    color: rgb(0 0 0 / 25%);
}

.trialButtonIconWrap .trialButtonIconText{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 11px;
    color: white;
    font-weight: 500;
}

.trialButtonIconWrap .trialButtonSvg{
    width: 100%;
    height: 100%;
}

.trialButtonDot{
    background-color: var(--graph-color-library-divergent-two);
    border: 1.5px solid white;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    position: absolute;
    top: -3px;
    right: -3.5px;
}

.trialDropdown{
    display: flex;
    width: 200px;
    align-items: center;
    gap: 16px;
    padding: 12px;

}

.trialDropdown .trialDropdownText{
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.trialDropdown .trialLoadingWrap{
    position: relative;
    width: 50px;
    height: 50px;

}

.trialDropdown .trialLoadingWrap .trialLoadingBack{
    color: rgb(var(--primary) / 20%);
    width: 100%;
    height: 4px;
    border-radius: 2px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.trialDropdown .trialLoadingWrap .trialLoadingText{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 24px;
}

.trialDropdown .trialLoadingWrap .trialLoadingFront{
    color: rgb(var(--primary));
}

.trialDropdown .trialDropdownTitle{
    font-size: 16px;
}

.trialDropdown .trialDropdownDescription{
    font-size: 13px;
    color: rgb(255 255 255 / 50%);
    font-weight: 400;
}