.loadingButtonInline {
    border: none;
    width: fit-content;
    padding: 0;
    outline: none;
    cursor: pointer;
}

.loadingButtonInlineLoader {
    width: 15px;
    height: 15px;
    color: inherit;
}

.loadingButtonInlineLoader :global(.MuiCircularProgress-root) {
    color: currentColor;
}
