.banner{
    border: 1px solid rgb(255 255 255 / 0.05);
    /* background-color: rgb(255 255 255 / 0.025); */
    /* box-shadow: 0 0 2px rgba(0,0,0,.8),0 4px 12px rgba(0,0,0,.36),inset 0 0 0 1px hsla(240,5%,96%,.16); */
    /* border-radius: 16px; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 72px;
    padding: 0 12px;
    margin-bottom: 16px;
}

.banner.abs{
    position: absolute;
    top: 32px;
    z-index: 1;
    left: 16px;
    right: 16px;
}

.banner .bannerLeft{
    display: flex;
    align-items: center;
}

.banner .bannerRight{
    display: flex;
    align-items: center;
    gap: 8px;
}

.banner .bannerRight .bannerButton{
    padding: 8px 16px;
    border-radius: 8px;
    border: none;
    /* background-color: rgb(255 255 255 / 0.05); */
    color: #fff;
    border: 1px solid rgb(255 255 255 / 0.1);
    font-size: 13px;
    cursor: pointer;
    transition-property: background-color;
    transition-duration: 100ms;
    display: flex;
    align-items: center;
    gap: 8px;
}

.banner .bannerRight .bannerButton:hover{
    background-color: rgb(255 255 255 / 0.05);
}

.banner .bannerRight .bannerButton .bannerButtonIcon{
    width: 14px;
    height: 14px;
    display: flex;
    align-items: center;
}

.banner .bannerRight .bannerButton .bannerButtonIcon svg{
    width: 100%;
    height: 100%;
}

.banner .bannerIcon{
    width: 24px;
    height: 24px;
    margin-right: 16px;
}

.banner .bannerIcon svg{
    width: 100%;
    height: 100%;
}

.banner .bannerText{
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.banner .bannerText .bannerTextTitle{
    font-size: 15px;
}

.banner .bannerText .bannerTextDesc{
    font-size: 13px;
    color: rgb(255 255 255 / 0.5);
}