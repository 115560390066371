.checkBoxWrap{
    border: none;
    cursor: pointer;
    padding: 0;
}

.checkBoxWrap:hover .checkbox:not(.selected){
    background-color: var(--ndp02);
}

.checkbox{
    width: 16px;
    height: 16px;
    border: 1px solid var(--border-01);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkbox.selected{
    background-color: rgb(var(--primary));
    border: none;
}