.inputWrap{
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 1px solid var(--border-00);
    color: white;
    border-radius: 5px;
    height: 36px;
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    position: relative;
    outline: none;
    transition-property: background-color, color, border;
    transition-duration: 100ms;
    padding: 0;
    overflow: hidden;
    cursor: text;
}

.inputWrap input.input{
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    padding: 0 8px 0 8px;
    height: 100%;
    width: 100%;
}

/* .inputWrap:hover input.input::placeholder{
    color: rgb(var(--primary));
} */

.inputWrap:hover, .inputWrap.isOpen{
    background-color: rgb(var(--primary) / 5%);
    color: rgb(var(--primary));
    border: 1px solid rgb(var(--primary) / 5%);
}

.inputWrap:hover:not(.isOpen){
    background-color: rgb(var(--primary) / 5%);
    border: 1px solid rgb(var(--primary) / 5%);
}

.inputWrap:hover:not(.isOpen) .input{
    color: rgb(var(--primary));
}

.inputWrap.isOpen{
    background-color: transparent;
    border: 1px solid rgb(var(--primary));
    box-shadow: 0 0 5px 3px rgb(var(--primary) / 10%);
}
