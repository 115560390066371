.defaultToast {
  display: flex;
  align-items: center;
  background: #fff;
  color: #363636;
  line-height: 1.3;
  will-change: transform;
  box-shadow: 0 3px 10px rgb(0 0 0 / 10%), 0 3px 3px rgb(0 0 0 / 5%);
  max-width: 480px;
  pointer-events: auto;
  padding: 8px 10px;
  border-radius: 8px;
}

.defaultToastIcon {
  font-size: 20px;
  margin-right: 12px;
  display: flex;
  align-items: center;
  line-height: 1em;
}

.cookieNoticeWrap {
  display: flex;
  align-items: center;
}

button.cookieAccept {
  border: none;
  padding: 0;
  text-decoration: underline;
  margin-left: 8px;
  cursor: pointer;
  font-size: 13px;

}

.cookieLink {
  color: rgb(var(--primary));
}

.cookieNoticeText {
  color: var(--alpha-text);
}

.cookieAccept {
  color: var(--alpha-text);
  font-size: 13px;
}

.trialBanner {
  background: var(rgb(var(--alpha-red))-light);
  color: rgb(var(--alpha-red));
  font-size: 14px;
  padding: 16px;
  display: flex;
  justify-content: center;
}

.bold {
  font-weight: 600;
}

.contact {
  color: inherit;
  text-decoration: underline;
}

.contact:hover {
  color: inherit;
  opacity: 0.9;
}

.content{
    min-height: calc(var(--doc-height));
}

/* Forbidden */

.pageErrorWrap {
    min-height: calc(var(--doc-height) - var(--nav-height));
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: rgb(var(--background));
}
/* 
.pageErrorWrap .errorWrap {
    background-color: var(--card);
    width: 450px;
    display: flex;
    flex-direction: column;
    padding: 16px;
    margin: auto 0;
    align-items: center;

} */

/* .errorWrap {
    background-color: var(--card);
    width: 450px;
    padding: 32px;
    display: flex;
    flex-direction: column;
    margin: auto 0;
    align-items: center;
}

.errorWrap .errorTitleWrap {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 0;
}

.errorWrap .title {
    margin: 0;
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    color: white;
}

.errorWrap .description {
    font-weight: 400;
    margin-bottom: 12px;
    text-align: center;
    color: var(--gamma-text);
    font-size: 14px;
    width: unset;
    margin: 12px 0 0 0;
}

.errorWrap .filled {
    padding: 0.63em 1em;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: rgb(var(--primary));
    color: white;
    font-weight: 400;
    font-size: 14px;
} */
