.pageWrap {
    display: flex;
    flex-direction: column;
    height: calc(var(--doc-height) - var(--nav-height));
    min-width: 1100px;
}

.wrapper {
    height: 100%;
}

.formWrapper {
    display: flex;
    padding: 0 32px;
    margin-top: 75px;
    justify-content: center;
    width: 100%;
    flex: 1;
    align-items: center;
    background-color: rgb(var(--background));
}

.formWrap {
    width: 336px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.formWrap form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.logo {
    width: 175px;
}

.formWrap p.subtitle {
    width: 100%;
    text-align: center;
}

.inputTitle{
    margin-bottom: 4px;
    margin-top: 16px;
    font-weight: 350;
    
}

.formWrap input.normal {
    font-size: 13px;
    color: var(--alpha-text);
    border-radius: 4px;
    background-color: rgb(var(--background));
    border: 1px solid var(--border-00);
    padding: 10px 12px;
    margin-top: 8px;
    outline: none;
}

.formWrap input.normal:disabled{
    background-color: rgb(255 255 255 / 5%);
    color: rgb(255 255 255 / 50%);
    cursor: not-allowed;
}

.formWrap input.normal.error {
    border: 1px solid rgb(var(--alpha-red));
    color: rgb(var(--alpha-red));
    background-color: var(--beta-red);
}

.formWrap .errorTxt {
    transition: max-height 0.2s ease;
    color: rgb(var(--alpha-red));
    margin-left: 8px;
    margin-top: 7px;
    font-size: 13px;
}

.formWrap .loginBtn {
    border-radius: 4px;
    font-weight: 400;
    padding: 8px 24px;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 12px;
    margin-top: 16px;
    box-shadow: none;
}

.formWrap .loginBtn:hover {
    box-shadow: none;
}

.formWrap .loginBtn :global(.MuiLoadingButton-loadingIndicator) {
    color: white;
}

.formWrap .loginBtn.inactive {
    background-color: rgb(var(--primary) / 50%);
}

.formWrap .loginBtn.active {
    background-color: rgb(var(--primary));
}

.bottomWrap {
    display: flex;
    justify-content: left;
    width: 400px;
    margin: 24px 0 16px 0;
    font-weight: 400;
}

.other {
    font-size: 13px;
    color: var(--alpha-text);
    width: 100%;
}

.other:hover {
    opacity: 0.8;
}

.title {
    font-weight: 500;
    font-size: 24px;
    font-family: "Poppins";
}

.subtitle{
    font-weight: 400;
    font-size: 14px;
    margin-top: 2px;
    margin-bottom: 16px;
    font-family: "Poppins";
    color: rgb(255 255 255 / 50%);
}

.subtitle a{
    color: rgb(var(--primary));
    text-decoration: underline;
}

.nonStyledTitle {
    font-family: var(--main-font);
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 16px;
}

.formWrap input.normal:-webkit-autofill {
    /* -webkit-text-fill-color: var(--alpha-text) !important; */
    /* -webkit-box-shadow: 0 0 0px 1000px var(--ndp02) inset; */
    /* box-shadow: 0 0 0px 1000px rgb(var(--background)) inset;
    caret-color: var(--alpha-text);
    border-color: var(--border-00); */
}

.top{
    display: flex;
    justify-content: flex-end;
    padding: 0 24px;
    font-weight: 400;
    margin-top: 24px;
}

.top .link{
    color: rgb(var(--primary));
    cursor: pointer;
    margin-left: 8px;
    border: 1px solid rgb(var(--primary));
    padding: 5px 12px;
    border-radius: 4px;
}

.formIconWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 40px;
    background-color: rgb(255 255 255 / 10%);
    border-radius: 50%;
    margin-bottom: 16px;
}

.formIconWrap svg{
    width: 24px;
    height: 24px;
    fill: rgb(255 255 255 / 50%);
}

.filledLink{
    background-color: rgb(var(--primary));
    color: white;
    border-radius: 4px;
    padding: 8px 16px;
}

.filledLinkWhite{
    background-color: rgb(255 255 255 / 10%);
    color: white;
    border-radius: 4px;
    padding: 8px 16px;
}

.inviteBox{
    background: rgb(var(--alpha) / 5%);
    width: 450px;
    padding: 24px;
    margin: auto;
}

.inviteTop{
    margin-bottom: 12px;
    color: rgb(var(--alpha) / 40%);
    text-align: center;
    margin-top: 100px;
    font-size: 16px;
    font-weight: 300;
}

.inviteBox .inviteIconWrap{
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
}

.inviteBox .inviteIcon{
    border-radius: 50%;
    overflow: hidden;
    width: 100px;
    height: 100px;
}


.inviteBox .inviteBoxTitle{
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 8px;
    text-align: center;
    line-height: 1.4em;
}

.inviteBox .inviteBoxSubtitle{
    font-size: 15px;
    font-weight: 350;
    margin-bottom: 16px;
    text-align: center;
    color: rgb(var(--alpha) / 40%);
    line-height: 1.5em;
}

.inviteBox .buttonWrap{
    display: flex;
    justify-content: center;
    gap: 8px;
}

.inviteBox .buttonWrap button{
    padding: 8px 0;
    font-size: 14px;
    color: white;
}

.widthHalf{
    width: 50%;
}

.loadingWrap{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
}

.loadingWrapLinear{
    width: 50%;
    margin: 0 auto;
    max-width: 600px;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.loadingIconWrap{
    border-radius: 50%;
    width: 50px;
    height: 50px;
    overflow: hidden;
    margin: 0 auto;
    margin-bottom: 16px;
}

.loadingIconWrap img{
    width: 100%;
    height: 100%;
}

.linearLabel{
    margin-bottom: 8px;
    text-align: center;
    font-size: 18px;
    color: white;
}

.linearSublabel{
    margin-bottom: 16px;
    color: rgb(255 255 255 / 50%);
}

.workspaceSelectWrap{
    /* grid with 3 columns */
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 16px;
    /* max-width: 1000px; */
    max-width: 775px;
    margin: 0 auto;
    justify-content: center;
}

.selectTitle{
    font-size: 24px;
    text-align: center;
    margin-top: 100px;
    margin-bottom: 24px;
}

.workspaceSelectWrap .workspaceSelectBtn{
    background-color: rgb(var(--alpha) / 5%);
    border-radius: 4px;
    color: white;
    font-size: 14px;
    font-weight: 400;
    min-width: 225px;
    min-height: 225px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    cursor: pointer;
    border: none;
    padding: 32px;
    transition: background-color 0.2s;
}

.workspaceSelectWrap .workspaceSelectBtn:hover{
    background-color: rgb(var(--alpha) / 7.5%);
}

.workspaceSelectIcon{
    width: 100px;
    height: 100px;
    margin-bottom: 16px;
    border-radius: 50%;
    overflow: hidden;
}

.workspaceSelectIcon img{
    width: 100%;
    height: 100%;
}

.workspaceSelectName{
    font-size: 16px;
}

.inviteTopWrap{
    margin-bottom: 16px;
}

.inviteIconTopBtn{
    border: none;
    padding: 0;
    color: rgb(255 255 255 / 50%);
    display: flex;
    align-items: center;
    gap: 2px;
    cursor: pointer;
}

.inviteIconTop{
    width: 17px;
    height: 17px;
}

.inviteIconTopBtn svg{
    width: 100%;
    height: 100%;
    fill: rgb(255 255 255 / 50%);
}