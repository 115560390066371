.tableWrap{
    height: 100%;
    z-index: 0;
    width: 100%;
    border-radius: 4px;
}

.pinnedOuter{
    width: 100%;
    height: 100%;
}

.pinnedWrap{
    width: 100%;
    height: 100%;
    z-index: 0;
    border-radius: 4px;
    position: relative;
    display: flex;
}

.pinnedWrap .tableWrap{
    height: 100%;
    z-index: 0;
    border-radius: 4px;
    position: absolute;
    top: 0;
}

.bottom{
    padding: 8px 16px 16px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: sticky;
    bottom: -36px;
    z-index: 1;
    background-color: rgb(var(--background));
    border-top: 1px solid var(--border-00);
}

.bottomInner {
    justify-content: space-between;
    display: flex;
    gap: 24px;
    width: 100%;
    overflow-x: auto;
}

.bottomInner.small {
    padding: 8px 8px 0 8px;
}

.bottomInner::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    z-index: -2;
}

.bottomInner::-webkit-scrollbar-thumb {
    background-color: var(--thumb-color);
    outline: none;
    border-radius: 4px;
}

.bottomInner::-webkit-scrollbar-corner {
    background-color: var(--card);
}


.pagination {
    display: flex;
    align-items: center;
}

.pagination .txt {
    margin: 0;
    padding-left: 8px;
    font-weight: 400;
    font-size: 12px;
    color: var(--gamma-text);
    white-space: nowrap;
}


.pageSelectWrap {
    position: relative;
    border: 1px solid var(--border-00);
    width: 50px;
    height: 32px;
}

select.pageSelect {
    cursor: pointer;
    border-radius: 0;
    font-weight: 500;
    color: var(--gamma-text);
    outline: none;
    font-size: 12px;
    appearance: none;
    line-height: 1em;
    width: 100%;
    position: absolute;
    left: 12px;
    height: 100%;
    background: none;
    border: none;
    z-index: 1;
}

.pageSelectIcon {
    width: 20px;
    color: var(--gamma-text);
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    z-index: 0;
}

.pageSelectIcon svg {
    width: 100%;
    height: 100%;
}

.btnWrap {
    display: flex;
    align-items: center;
    height: 24px;
    margin-left: 8px;
}

.btnWrap .arrowBtn {
    cursor: pointer;
    outline: none;
    border: 1px solid var(--border-00);
    height: 32px;
    color: var(--gamma-text);
    display: flex;
    align-items: center;
}

.btnWrap .arrowBtn:disabled {
    color: var(--disabled);
}

.btnWrap .arrowBtn:first-child {
    margin-right: -1px;
}

.btnWrap .arrowBtn svg {
    width: 15px;
    height: 15px;
}

.grid {
    border: none !important;
    border-radius: 0 !important;
    background-color: rgb(var(--background));
}

.grid .tableRow{
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.grid .tableRow:hover{
    background: inherit;
}

.grid .tableRow:global(.MuiDataGrid-row){
    border-bottom: 1px solid var(--grid-line-color);
}

.grid .tableRow.isLast:global(.MuiDataGrid-row){
    border-bottom: none;
}

/* .grid .tableRow:global(.MuiDataGrid-row):last-child{
    border-bottom: none;
} */

/* .grid .tableRow.even:global(.MuiDataGrid-row){
    background-color: var(--ndp01);
}

.grid .tableRow.odd:global(.MuiDataGrid-row) {
    background-color: var(--ndp02);
} */

.grid .tableRow.odd:global(.MuiDataGrid-row) {
    /* background-color: rgb(255 255 255 / 2.5%); */
    background-color: transparent;
    transition: background-color 200ms;
}

.grid .tableRow:global(.MuiDataGrid-row):hover{
    background-color: rgb(199 143 147 / 3%);
    cursor: pointer;
}
.grid .tableRow.even:global(.MuiDataGrid-row.Mui-selected){
    background-color: var(--ndp01);
}
 
.grid .resultRow{
    border-bottom: 1px solid var(--grid-line-color);
}

.grid .resultRow:global(.MuiDataGrid-row):hover{
    background-color: inherit;
}

.grid .resultRow :global(.MuiDataGrid-cell){
    border-right: 1px solid var(--grid-line-color);
}

.grid .resultRow :global(.MuiDataGrid-cell):hover{
    background-color: var(--ndp02);
}

.grid :global(.MuiDataGrid-columnHeader){
    border-right: 1px solid var(--grid-line-color) !important;
    /* border-top: 1.5px solid var(--grid-line-color) !important; */
}

.grid :global(.MuiDataGrid-columnHeader):last-child{
    border-right: none !important;
}

.grid .wrapping :global(.MuiDataGrid-cell){
    white-space: normal !important;
    word-wrap: break-word !important;
    padding: 12px 10px;
    align-items: flex-start;

}

.grid :global(.MuiDataGrid-cell--editing){
    padding: 0;
}

.grid .resultsHeader{
    color: var(--gamma-text);
    font-size: 12px;
}

.timeseriesWrap{
    display: flex;
    width: 100%;
    position: relative;
}

.distributionWrap{
    display: flex;
    width: 100%;
    position: relative;
    overflow: hidden;
}

.headerWrap{
    display: flex;
    flex-direction: column;
    min-width: 235px;
    position: sticky;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1;
}

.distributionWrap .headerWrap{
    min-width: 300px;
    width: 37%;
}

.pinnedWrap .pinnedColumn{
    width: 300px;
}

.pinnedWrap .pinnedColumn .headerCell{
    border-bottom: 1px solid var(--grid-line-color);
    border-right: 1px solid var(--grid-line-color);
    /* background-color: var(--ndp01); */
    /* color: rgb(255 255 255 /50%); */
    padding: 0 10px;
    display: flex;
    align-items: center;
    height: 56px;
}

.pinnedWrap .pinnedColumn .dataCell{
    border-bottom: 1px solid var(--grid-line-color);
    border-right: 1px solid var(--grid-line-color);
    /* background-color: var(--ndp01); */
    color: white;
    padding: 0 10px;
    display: flex;
    align-items: center;
    min-height: 44px;
    line-height: 1.5em;
}

.pinnedWrap .pinnedColumn .dataCell.odd{
    /* background-color: rgb(255 255 255 / 2.5%); */
}

.headerWrap .headerCellLeft{
    min-height: 44px;
    display: flex;
    align-items: center;
    border: none;
    border-right: 1px solid var(--grid-line-color);
    border-bottom: 1px solid var(--grid-line-color);
    background-color: var(--ndp01);
    padding: 0 8px;
    color: white;
    overflow: hidden;
    
}

.headerWrap .headerCellTop{
    min-height: 56px;
    display: flex;
    align-items: center;
    border: none;
    border-right: 1px solid var(--grid-line-color);
    border-bottom: 1px solid var(--grid-line-color);
    background-color: var(--ndp01);
    padding: 0 10px;
    color: rgb(255 255 255 /50%);
}
/* 
.pinnedWrap .dataCellTitle{
    min-height: 56px;
    display: flex;
    align-items: center;
    border: none;
    border-right: 1px solid var(--grid-line-color);
    border-bottom: 1px solid var(--grid-line-color);
    padding: 0 10px;
    color: rgb(255 255 255 / 50%);
}

.pinnedWrap .dataCell{
    min-height: 44px;
    display: flex;
    align-items: center;
    border: none;
    border-right: 1px solid var(--grid-line-color);
    border-bottom: 1px solid var(--grid-line-color);
    padding: 0 16px;
} */

.headerCellLeft.odd{
    background-color: var(--ndp02);
}

/* .headerWrap .headerCellLeft:last-child{
    border-bottom: none;
} */

/* .grid :global(.MuiDataGrid-virtualScroller)::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    z-index: -2;
}

.grid :global(.MuiDataGrid-virtualScroller)::-webkit-scrollbar-thumb {
    background-color: var(--thumb-color);
    outline: none;
    border-radius: 4px;
}

.grid :global(.MuiDataGrid-virtualScroller)::-webkit-scrollbar-corner {
    background: var(--card);
} */

.grid .resultCell{
    font-size: 12px;
}

.resultsWrapper{
    height: auto;
    width: 100%;
    overflow: hidden;
}

/* .resultsWrapper{
    overflow-x: scroll;
}

.resultsWrapper::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: var(--ndp02);
    z-index: -2;
}

.resultsWrapper::-webkit-scrollbar-thumb {
    background-color: var(--thumb-color);
    outline: none;
    border-radius: 0px;
}

.resultsWrapper::-webkit-scrollbar-corner {
    background: rgb(var(--background));
} */


.dot{
    width: 12px;
    height: 12px;
    border-radius: 50%;
    margin-right: 8px;
}

.headerCheckboxOuter{
    border-left: 1px solid var(--grid-line-color);
    border-right: 1px solid var(--grid-line-color);
    height: 100%;
    display: flex;
    margin-right: 8px;
    align-items: center;
}

.headerCellLeft .text{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.headerCheckboxOuter .checkbox{
    padding: 0 8px;
}

.headerCheckboxEmpty{
    border-right: 1px solid var(--grid-line-color);
    border-left: 1px solid var(--grid-line-color);
    height: 100%;
}

.invisibleCheckbox{
    width: 16px;
    height: 16px;
    margin: 0 8px;
}

.headerTopContent{
    padding: 0 8px;
    
}

.funnelGridTitle{
    font-size: 20px;
    font-weight: 500;
    padding: 0;
    margin: 0;
}

.funnelGrid{
    display: grid;
    width: 100%;
    grid-template-columns: minmax(180px, 220px) 1fr 1fr 1fr;
    grid-column-gap: 24px;
    padding: 16px 32px 16px 16px;
}

.funnelGrid .funnelCellLine{
    grid-column-start: 2;
    grid-column-end: -1;
    height: 1px;
    background-color: rgb(255 255 255 / 10%);
}

.funnelGrid .funnelCell{
    min-height: 48px;
    display: flex;
    align-items: center;
    padding: 8px;
    font-size: 14px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.funnelGrid .funnelCell .funnelCellHeaderWrap{
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-bottom: 4px;
}

.funnelGrid .funnelCellHeaderTitle{
    font-size: 12px;
    /* color: rgb(255 255 255 / 50%); */
    font-weight: 400;
    margin-bottom: 8px;
}

.funnelGrid .funnelCellHeaderSubtitle{
    font-size: 12px;
    color: rgb(255 255 255 / 50%);
    font-weight: 400;
    white-space: wrap;

}

.funnelGrid .funnelCell.titleCell{
    height: 54px;
    display: flex;
    align-items: center;
    padding: 8px;
    padding-right: 16px;
}

.funnelGrid .funnelCell.value{

}

.funnelGrid .funnelCell .funnelCellTitleWrap{
    display: flex;
    align-items: center;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.funnelGrid .funnelCell .funnelCellTitleWrap .funnelCellIndex{
    border: 1px solid rgb(255 255 255 / 10%);
    min-width: 20px;
    min-height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    border-radius: 2px;
    margin-right: 8px;
}

.funnelGrid .funnelCell .funnelCellTitleWrap .funnelCellName{
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

}