.pageTitle {
    font-size: 24px;
}

.topBar {
    display: flex;
    align-items: center;
    margin: 12px 0 16px 0;
    justify-content: space-between;
}

.topBarLeft {
    display: flex;
    align-items: center;
    gap: 4px;
}


.breadcrumbBold {
    font-weight: 400;
    color: rgb(255 255 255 / 50%);
    font-size: 16px;
}



.topBarDivider {
    font-weight: 500;
}

.topBarTitle {
    font-size: 16px;
    font-weight: 500;
    color: white;
}

.topBarRight {
    display: flex;
    align-items: center;
    gap: 8px;
}

.topBar .filterButton {
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition-property: background-color, color;
    transition-duration: 200ms;
    padding: 8px 12px;
    background-color: transparent;
}

.topBar .filterButton.active {
    background-color: rgb(var(--primary) / 5%);
    color: rgb(var(--primary));
    padding: 8px 12px;
}

.topBar .filterButton:not(.active):hover {
    color: rgb(var(--primary));
}

.topBar .searchBar {
    display: flex;
    gap: 4px;
    border: 1px solid rgb(255 255 255 / 2.5%);
    background-color: rgb(255 255 255 / 2.5%);
    color: white;
    font-size: 13px;
    font-weight: 400;
    outline: none;
    transition-property: background-color, color, border;
    transition-duration: 100ms;
    overflow: hidden;
    width: 100%;
    padding: 8px 0;
    position: relative;
    min-width: 300px;
    border-radius: 4px;
    align-items: center;
}

.topBar .searchBar:hover {
    border: 1px solid rgb(var(--primary) / 70%);
}

.topBar .searchBar.active {
    background-color: transparent;
    border: 1px solid rgb(var(--primary) / 70%);
    box-shadow: 0 0 5px 3px rgb(var(--primary) / 10%);
}

.topBarOverview {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 16px 24px 0 24px;
    border-bottom: 1px solid rgb(255 255 255 / 10%);
}

.topBarOverview .topBarTopSection {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 8px;
    gap: 8px;
}

.topBar .searchBar .searchInput {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border: none;
    outline: none;
    padding-left: 30px;
    color: white;
    font-size: 13px;
}

.topBar .searchBar .searchInput::placeholder {
    transition-property: color;
    transition-duration: 200ms;
    color: rgb(255 255 255 / 30%);
}

.topBar .searchBar .searchIcon {
    color: rgb(255 255 255 / 50%);
    padding-left: 8px;
    height: 100%;
    display: flex;
    align-items: center;
}

.topBar .searchBar .searchIcon svg {
    width: 16px;
    height: 16px;
}

.topBarBottomSection {
    display: flex;
    align-items: center;
    margin-top: 16px;
    gap: 16px;
}

.topBarBottomSection .topBarOption {
    position: relative;
    padding-bottom: 8px;
}

.topBarBottomSection .topBarOption.active {
    color: rgb(var(--primary));
}

.topBarBottomSection .highlightBar {
    height: 1px;
    background-color: rgb(var(--primary));
    width: 100%;
    /* margin: 0 -16px; */
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
}

.addSourceButton {
    background-color: rgb(var(--primary));
    color: white;
    border-radius: 2px;
    padding: 8px;
    border: none;
    display: flex;
    align-items: center;
    gap: 4px;
    cursor: pointer;
    transition-property: background-color;
    transition-duration: 200ms;
    white-space: nowrap;
}

.addSourceButton:hover {
    background-color: rgb(var(--primary) / 90%);

}

.addSourceButton .addSourceIcon {
    color: white;
    display: flex;
    align-items: center;
}

.addSourceButton .addSourceIcon svg {
    width: 16px;
    height: 16px;
}

.newPopoverWrap {
    margin-left: 8px;
}

.newPopoverWrapInner {
    background-color: rgb(var(--alpha) / 5%);
    display: flex;
    flex-direction: column;
    width: 350px;
}

.newPopoverWrap .title {
    font-size: 20px;
}

.newPopoverWrap .createOptionText {
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
    text-align: left;
}

.newPopoverWrap .circle {
    background-color: rgb(var(--alpha) / 5%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--alpha-text);
}

.newPopoverWrap .circle svg {
    width: 18px;
    height: 18px;
}

.newPopoverWrap .createOption {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 12px 12px;
    cursor: pointer;
    transition-duration: 100ms;
    transition-property: background-color;
    border: 1px solid rgb(var(--alpha) / 2.5%);
    min-height: 75px;
    border-left: none;
    border-top: none;
}

.newPopoverWrap .createOption:hover {
    background-color: rgb(var(--alpha) / 2.5%);
}

.newPopoverWrap .createOption .title {
    font-size: 14px;
    font-weight: 500;
    color: var(--alpha-text);
}

.newPopoverWrap .createOption .description {
    color: var(--gamma-text);
    font-size: 13px;
    line-height: 1.4em;
    margin-top: 5px;
}


.popoverTitle {
    color: rgb(255 255 255 / 50%);
}

.popperItemWrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 8px 0;
}

.createItemWrap {
    width: calc(100%/3);
    display: flex;
    border: none;
    transition: all 100ms;
    align-items: center;
    gap: 16px;
    padding: 16px;
    border-radius: 4px;
}

.createItemWrap:hover {
    cursor: pointer;
    background-color: rgb(var(--alpha) / 5%);
}

.createItemWrap .createItemIcon {
    min-width: 30px;
    width: 30px;
    color: var(--alpha-text);
}

.createItemWrap .createItemIcon svg {
    width: 100%;
    height: 100%;
}

.createItemTextWrap {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.createItemTextWrap .createItemTitle {
    font-size: 14px;
    font-weight: 500;
    color: var(--alpha-text);
    position: relative;
    display: flex;
    align-items: center;
}

.createItemTextWrap .createItemDescription {
    font-size: 12px;
    font-weight: 400;
    color: rgb(255 255 255 / 50%);
    margin-top: 8px;
}

.topBarInPage {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px;
}

.topBarInPage .topBarTitle {
    font-size: 16px;
    font-weight: 400;
}

.topBarInPage .outline {
    padding: 8px 16px;
    border: 1px solid rgb(255 255 255 / 10%);
    background-color: transparent;
    transition-property: background-color;
    transition-duration: 200ms;
    cursor: pointer;
    border-radius: 2px;
}

.topBarInPage .deleteButton {
    padding: 8px 16px;
    border: 1px solid rgb(255 255 255 / 10%);
    background-color: transparent;
    transition-property: background-color, color, border;
    transition-duration: 200ms;
    cursor: pointer;
    border-radius: 2px;
}

.topBarInPage .outline:hover {
    background-color: rgb(255 255 255 / 5%);
}

.topBarInPage .deleteButton:hover {
    color: rgb(var(--alpha-red));
    border: 1px solid rgb(var(--alpha-red) / 50%);
    background-color: rgb(var(--alpha-red) / 5%);
}

.inPageWrap {
    display: flex;
    flex-direction: column;
}

.overviewPageWrap {
    display: flex;
    flex-direction: column;
    padding: 32px;
}

.contentLeft {
    display: flex;
    flex-direction: column;
    width: 60%;
}

.contentRight {
    width: 40%;
}

.createPageTitle {
    font-size: 20px;
    color: white;
    font-weight: 500;
}

.createPageDescription {
    font-size: 14px;
    color: rgb(255 255 255 / 50%);
    margin-top: 8px;
    font-weight: 400;
}

.inputTitleWrap {
    /* optional for an input */
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 16px;
}

.inputTitleWrap .inputTitle {
    margin: 0;
}

.inputTitle {
    font-size: 13px;
    margin: 16px 0 0px 0;
}

.resetButton {
    font-size: 13px;
    text-decoration: underline;
    cursor: pointer;
    color: rgb(var(--primary));
    border: none;
    padding: 0;
}

.formWrap .createInput {
    border: 1px solid rgb(255 255 255 / 2.5%);
    background-color: rgb(255 255 255 / 2.5%);
    color: white;
    border-radius: 5px;
    height: 36px;
    font-size: 13px;
    font-weight: 400;
    outline: none;
    transition-property: background-color, color, border;
    transition-duration: 100ms;
    overflow: hidden;
    width: 100%;
    margin-top: 8px;
    padding: 0 8px;
}

.formWrap select.createInput {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    cursor: pointer;

}

.formWrap .createInput.error {
    background-color: rgb(var(--alpha-red) / 10%);
    border: 1px solid rgb(var(--alpha-red) / 50%);
}

.formWrap .createInput.error:focus {
    border: 1px solid rgb(var(--alpha-red) / 50%);
    box-shadow: none;
}

.formWrap .createInput:hover {
    border: 1px solid rgb(var(--primary) / 70%);
}

.formWrap .createInput:focus {
    background-color: transparent;
    border: 1px solid rgb(var(--primary) / 70%);
    box-shadow: 0 0 5px 3px rgb(var(--primary) / 10%);
}

.formWrap .createInputButton {
    border: 1px solid rgb(255 255 255 / 2.5%);
    background-color: rgb(255 255 255 / 2.5%);
    border-radius: 5px;
    height: 36px;
    font-size: 13px;
    font-weight: 400;
    outline: none;
    transition-property: background-color, color, border;
    transition-duration: 100ms;
    overflow: hidden;
    width: 100%;
    margin-top: 8px;
    padding: 0 8px;
    text-align: left;
    cursor: pointer;
}

.formWrap .createInputButton .buttonTextEmpty {
    color: rgb(255 255 255 / 20%);
}

.formWrap .createInputButton .buttonLabelWrap {
    display: flex;
    align-items: center;
    gap: 8px;
}

.formWrap .createInputButton.error {
    background-color: rgb(var(--alpha-red) / 10%);
    border: 1px solid rgb(var(--alpha-red) / 50%);
}

.formWrap .createInputButton.error:focus {
    border: 1px solid rgb(var(--alpha-red) / 50%);
    box-shadow: none;
}

.formWrap .createInputButton:hover {
    border: 1px solid rgb(var(--primary) / 70%);
}

.formWrap .createInputButton.active {
    background-color: transparent;
    border: 1px solid rgb(var(--primary) / 70%);
    box-shadow: 0 0 5px 3px rgb(var(--primary) / 10%);
}

.formWrap {
    margin-top: 24px;
}

.formWrap input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
}

.formWrap .saveButton{
    background-color: rgb(var(--primary));
    color: white;
    border-radius: 4px;
    padding: 8px 16px;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition-property: background-color;
    transition-duration: 100ms;
    font-size: 13px;
}

.formWrap .createButton {
    background-color: rgb(var(--primary));
    color: white;
    border-radius: 4px;
    padding: 8px 16px;
    border: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition-property: background-color;
    transition-duration: 100ms;
    font-size: 13px;
    margin-top: 16px;
}

.formWrap .createButton.loading {
    width: fit-content;
    padding: 8px 12px;
    opacity: 0.5;
    cursor: not-allowed;
    gap: 12px;
    /* padding: 8px 32px; */
}

.formWrap .createButton:hover{
    background-color: rgb(var(--primary) / 80%);
}

.formWrap .deleteButton{
    color: rgb(var(--alpha-red));
    border: 1px solid rgb(var(--alpha-red));
    border-radius: 4px;
    padding: 8px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition-property: background-color;
    transition-duration: 100ms;
    font-size: 13px;
    gap: 4px;
}

.formWrap .deleteButton:hover{
    background-color: rgb(var(--alpha-red) / 20%);
    color: rgb(var(--alpha-red));
}

.formWrap .deleteButton .deleteIcon{
    color: rgb(var(--alpha-red));
    width: 16px;
    height: 16px;
}

.formWrap .deleteButton .deleteIcon svg{
    width: 100%;
    height: 100%;
}

.errorTxt {
    color: rgb(var(--alpha-red));
    margin-top: 7px;
    font-size: 13px;
}

/* table list */

.statusTag{
    display: flex;
    align-items: center;
    gap: 8px;
}

.statusDot{
    min-width: 8px;
    width: 8px;
    min-height: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: rgb(255 255 255 / 0.5);
}

.statusDot.green{
    background-color: rgb(var(--green) / 90%);
}

.tableWrap {
    width: 100%;
    border: 1px solid rgb(255 255 255 / 10%);
    border-bottom: none;
}

.tableWrap .tableHeaders {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px;
    border: none;
    border-bottom: 1px solid rgb(255 255 255 / 10%);
    background-color: rgb(255 255 255 / 2.5%);
}

.tableWrap .tableRow {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px;
    border: none;
    border-bottom: 1px solid rgb(255 255 255 / 10%);
    cursor: pointer;
    transition-property: background-color;
    transition-duration: 100ms;
}

.tableWrap .tableRow:hover {
    background-color: rgb(255 255 255 / 2.5%);
}

.tableWrap .tableCell {
    overflow: hidden;
    text-align: left;
}

.noSourcesWrap {
    border-bottom: 1px solid rgb(255 255 255 / 10%);
    /* padding: 16px; */
    height: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.noSourcesWrap .noSourcesTitle {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
}

.noSourcesWrap .noSourcesDescription {
    font-size: 14px;
    color: rgb(255 255 255 / 50%);
    text-align: center;
}

.pageLoadingWrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
}

.errorCard.errorCard{
    width: 400px;
}

.errorCard .errorTitle{
    font-size: 20px;
}

.errorCard .errorSubtitle{
    font-size: 13px;
    color: rgb(255 255 255 / 0.5);
    font-weight: 400;
    line-height: 1.25em;
    margin: 12px 0 16px 0;
}

.errorCard .errorButton{
    color: rgb(var(--primary));
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.errorCard .errorButton .errorButtonIcon{
    width: 16px;
    height: 16px;
    margin-right: 4px;
}

.errorCard .errorButton .errorButtonIcon svg{
    width: 100%;
    height: 100%;
}

.errorCard .errorLink{
    text-decoration: underline;
}

.tableLoadingWrap {
    border-bottom: 1px solid rgb(255 255 255 / 10%);
    height: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.stepWrap {
    display: flex;
    align-items: flex-start;
    gap: 16px;
    width: 100%;
}

.stepIndex {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background-color: rgb(255 255 255 / 10%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.stepContent {
    width: 100%;
}

.stepContent .stepTitle {
    font-size: 16px;
    font-weight: 500;
}

.stepContent .stepDescription {
    font-size: 14px;
    color: rgb(255 255 255 / 50%);
    margin-top: 4px;
}

/* overview */

.overviewTop {
    display: flex;
    gap: 16px;
    align-items: center;
}

.overviewTop .overviewIcon {
    color: rgb(255 255 255 / 50%);
    width: 36px;
    height: 36px;
}

.overviewTop .overviewIcon svg {
    width: 100%;
    height: 100%;
}

.overviewTop .overviewTitle {
    font-size: 24px;
    font-weight: 500;
    color: white;
}

.overviewTop .overviewDescription {
    font-size: 14px;
    color: rgb(255 255 255 / 50%);
    margin-top: 2px;
}


.codeBlockWrap {
    position: relative;
    width: 100%;
}

.codeBlockWrap .copyButton {
    border: none;
    padding: 0;
    position: absolute;
    right: 16px;
    top: 16px;
    width: 16px;
    height: 16px;
    cursor: pointer;
}

.codeBlockWrap .copyButton:hover {
    color: rgb(var(--primary));
}

.codeBlockWrap .copyButton svg {
    width: 100%;
    height: 100%;
}

.copyButtonVerbose {
    background-color: rgb(var(--primary));
    color: white;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition-property: background-color;
    transition-duration: 100ms;
    padding: 6px 8px;
    font-size: 12px;
    display: flex;
    align-items: center;
    gap: 4px;
}

.copyButtonVerbose .copyIcon {
    color: white;
    width: 16px;
    height: 16px;
}

.copyButtonVerbose .copyIcon svg {
    width: 100%;
    height: 100%;
}

.copyButtonVerbose:hover {
    background-color: rgb(var(--primary) / 80%);
}

.copyButtonVerbose:focus {
    box-shadow: rgb(var(--primary) / 25%) 0px 0px 0px 2px;
}

.overviewStep {
    margin-top: 32px;
}

.settingsWrap {
    display: flex;
    gap: 16px;
}

.settingsForm {
    width: 100%;
    margin-top: 0;
}

.settingsForm .inputTitle{
    font-size: 15px;
    font-weight: 500;
}

.settingsForm .inputDescription{
    font-size: 13px;
    color: rgb(255 255 255 / 50%);
    margin-top: 4px;
}

.settingsForm .outline{
    height: 36px;
    background-color: rgb(var(--primary));
    color: white;
    border-radius: 4px;
    border: none;
    cursor: pointer;
    transition-property: background-color;
    transition-duration: 100ms;
    padding: 6px 16px;
    font-size: 13px;
    display: flex;
    align-items: center;
    gap: 4px;

}

.settingsForm .outline:hover{
    background-color: rgb(var(--primary) / 80%);

}

.settingsForm .outline:focus{
    box-shadow: rgb(var(--primary) / 25%) 0px 0px 0px 2px;
}

.settingsForm .outline .copyIcon{
    color: white;
    width: 16px;
    height: 16px;
}

.settingsForm .outline .copyIcon svg{
    width: 100%;
    height: 100%;
}

.settingsSidebar {
    display: flex;
    flex-direction: column;
    width: 225px;
    padding: 16px;
    gap: 4px;
}

.settingsSidebar .settingsSidebarItem{
    color: white;
    cursor: pointer;
    transition-property: color;
    transition-duration: 100ms;
    padding: 8px;
    border-radius: 4px;
}

.settingsSidebar .settingsSidebarItem:hover{
    color: rgb(var(--primary));
}

.settingsSidebar .settingsSidebarItem.active {
    color: rgb(var(--primary));
    background-color: rgb(var(--primary) / 10%);
}
