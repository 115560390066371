/* base select */
.select{
    display: flex;
    align-items: center;
    border: 1px solid var(--border-00);
    border-radius: 5px;
    height: 36px;
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    position: relative;
    cursor: pointer;
    outline: none;
    transition-property: background-color, color;
    transition-duration: 100ms;
    padding: 0;
    overflow: hidden;
}

/* select active styles */

.select:hover, .select.isOpen{
    background-color: rgb(var(--primary) / 5%);
    color: rgb(var(--primary));
    border: 1px solid rgb(var(--primary) / 5%);
}

.select:hover .selectText, .select.isOpen .selectText, .select:hover .selectText.placeholder, .select.isOpen .selectText.placeholder{
    color: rgb(var(--primary));
}

.select:hover .arrowWrap, .select.isOpen .arrowWrap{
    color: rgb(var(--primary) / 80%);
}

.select.right{
    width: 250px;
}

.select .selectText{
    transition: color 100ms;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 32px 0 8px;

}

.select .selectText.placeholder{
    color: var(--input-placeholder);
}

.select .arrowWrap{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0px;
    top: 0;
    bottom: 0;
    border-radius: 4px;
    color: rgb(255 255 255 / 50%);
    transition: color 200ms;
    width: 24px;
}


.dropdown{
    background-color: rgb(var(--background));
    border: 1px solid rgb(255 255 255 / 5%);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    width: fit-content;
    max-height: 350px;
    position: relative;
    padding: 8px;
}

.dropdown .linearProgressWrap{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 4px;
    z-index: 2;
}

.dropdown .search{
    background-color: rgb(255 255 255 / 2.5%);
    border-radius: 8px;
    min-height: 36px;
    padding: 0 8px;
    outline: none;
    color: white;
    border: 1px solid transparent;
    transition-property: background-color, border;
    transition-duration: 200ms;
    margin: 8px 8px 4px 8px;
}

.option{
    display: flex;
    border: none;
    padding: 10px 8px;
    gap: 8px;
    align-items: center;
    cursor: pointer;
    outline: none;
    font-size: 13px;
    border-radius: 4px;
    text-align: left;
}

.option .icon{
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.option .icon svg{
    width: 100%;
    height: 100%;
}

.option.highlighted{
    background-color: rgb(var(--primary) / 5%);
    color: rgb(var(--primary));
}

.option.highlighted .icon{
    color: rgb(var(--primary));
}

.option.active{
    background-color: rgb(var(--primary) / 15%);
    color: rgb(var(--primary));
}

.option.active .icon{
    color: rgb(var(--primary));
}

