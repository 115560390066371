@charset "UTF-8";

* {
    font-style: normal;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

*,
:after,
:before {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}

:root {
    --main-font: "Inter", -apple-system, system-ui, sans-serif;
    --mono-font: "IBM Plex Mono", -apple-system, system-ui, sans-serif;
    --code-font: "Inconsolata", -apple-system, system-ui, sans-serif;
    --doc-height: 100%;
    --header-size: 64px;
    --nav-height: 60px;
    --alpha-red: 255 45 85;
    --alpha-red-v2: 218 84 84;
    --beta-red: rgb(var(--alpha-red) / 12%);

    --alpha: 222 199 204;
    /* --alpha: 222 213 214; */
    /* --background: 22 20 21; */
    --background: 28 28 28;
    --primary: 237 77 112;
    --primary-hex: #ed4d70;
    --primary-border: rgb(var(--primary) / 100%);
    --border-00: rgb(var(--alpha) / 10%);
    --border-01: rgb(var(--alpha) / 12%);
    --border-03: rgb(var(--alpha) / 14%);
    --ndp01: rgb(var(--alpha) / 2.5%);
    --ndp02: rgb(var(--alpha) / 5.0%);
    --sdp01: #1b1a1a;
    --sdp02: #211d1e;
    --sdp04: #242223;
    --light: rgb(var(--alpha) / 35%);
    --disabled: rgb(var(--alpha) / 25%);
    --input-placeholder: rgb(var(--alpha) / 28%);
    /*or 66565b*/
    --grid-line-color: rgb(var(--alpha) / 5%);
    --warning: 246 194 80;

    --alpha-text: #f0eeef;
    --gamma-text: #6f686a;
    --graph-color-library-divergent-one: #ed4d70;
    --graph-color-library-divergent-two: #3881ff;
    --graph-color-library-divergent-three: #438b72;
    --graph-color-library-divergent-four: #db8044;
    --graph-color-library-divergent-five: #716CBC;
    --graph-color-library-divergent-six: #67adcf;
    --graph-color-library-divergent-seven: #66b399;
    --graph-color-library-divergent-eight: #e3a176;
    --graph-color-library-divergent-nine: #A49DFF;
    --icon-color-one: #5372c1;
    --icon-color-two: #509071;
    /* --icon-color-three: #DE6262;
    --icon-color-four: #976ad9;
    --icon-color-five: #D9730D; */
    --card: rgb(28 28 28);
    --alpha-green: #34bf8c;
    --green: 52 191 140;

    --color-library-divergent-one-rgb: 56 129 255;

    --gray-600: #363030;

    /* solids */

    --alpha-solid-05: #282828;

    --primary-solid-05: #312125;
}

::selection {
    background: rgb(var(--primary));
}

::-moz-selection {
    background: rgb(var(--primary));
    /* Gecko Browsers */
}

button {
    background-color: transparent;
    font-size: 13px;
    box-sizing: border-box !important;
    color: inherit;

}

body {
    font-family: var(--main-font);
    margin: 0;
    color: var(--alpha-text);
    background-color: rgb(var(--background));
    width: 100%;
    height: auto;
    font-size: 13px;
    overflow: visible;
}

a {
    text-decoration: none;
    color: inherit;
}

button {
    box-sizing: border-box !important;
    color: inherit;
}

table {
    border-spacing: 0;
    border-collapse: collapse;

}

input::placeholder {
    color: var(--input-placeholder);
}

input::-moz-placeholder {
    color: var(--input-placeholder);
}

textarea::placeholder {
    color: var(--input-placeholder);
}

textarea::-moz-placeholder {
    color: var(--input-placeholder);
}

h4 {
    font-weight: 500;
}

.modalTitle {
    font-size: 20px;
    color: var(--alpha-text);
    font-weight: 600;
    text-align: left;
}

.modalText {
    font-size: 14px;
    color: var(--gamma-text);
    font-weight: normal;
    margin-top: 16px;
    text-align: left;
}

.modalBtns {
    margin-top: 32px;
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    width: 100%;
}

.modalBtns button {
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    padding: 8px 24px;
    border: none;
    cursor: pointer;
    width: fit-content;
}

.modalBtns button.blue {
    background-color: rgb(var(--primary));
    color: rgb(var(--background));
}

.modalBtns button.white {
    background-color: white;
    color: var(--alpha-text);
    font-weight: 500;
    border: 1px solid var(--border-00);
}

.modalBtns button.destructive {
    background-color: rgb(var(--alpha-red));
    color: rgb(var(--background));
}

.Selectable {
    font-family: var(--main-font), -apple-system, system-ui, sans-serif;
}

.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
    background-color: #f0f8ff !important;
    color: rgb(var(--primary)) !important;
}

.Selectable .DayPicker-Day {
    border-radius: 0 !important;
}

.Selectable .DayPicker-Day--start {
    border-radius: 4px !important;
}

.Selectable .DayPicker-Day--end {
    border-radius: 4px !important;
}

.Selectable .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
    background-color: rgb(var(--primary)) !important;
}

.Selectable .DayPicker-Day {
    padding: 4px 8px;
}

.Selectable .DayPicker-Day--today {
    color: unset;
    font-weight: unset;
}

.DayPicker {
    display: inline-block;
    font-size: 0.85rem !important;
}

.stacked-tooltip-wrap {}

.stacked-tooltip-wrap-inner {
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    align-items: center;
    width: 200px;
}

.stacked-tooltip-wrap div.stacked-tooltip-item {
    width: 100%;
    padding: 0px 4px 4px 4px;
    color: var(--alpha-text);
    white-space: normal;
    line-height: 1.5em;
}

.tooltip-line {
    padding: 1px 0;
    color: var(--alpha-text);
}

.custom-tooltip-data-title {
    font-weight: 500;
}

.dot {
    height: 8px;
    width: 8px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 2px;
}

a.normalCellLink {
    color: var(--alpha-text);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

a.normalCellLink:hover {
    text-decoration: underline;
    transition: color 0.2s ease;
}

.assetFlex {
    display: flex;
    align-items: center;
    gap: 4px;
}

/* Grid */
.layout-resizable.react-grid-layout {
    position: relative;
    transition: height 100ms ease;
    z-index: 5;
}

.layout-resizable .react-grid-item {
    transition: all 200ms ease;
    transition-property: left, top;
    z-index: 5;
}

.layout-resizable .react-grid-item.cssTransforms {
    transition-property: transform;
}

.layout-resizable .react-grid-item.resizing {
    z-index: 5;
    will-change: width, height;
}

.layout-resizable .react-grid-item.react-draggable-dragging {
    transition: none;
    z-index: 3;
    will-change: transform;
}

.layout-resizable .react-grid-item.react-grid-placeholder {
    background: var(--ndp02);
    transition-duration: 100ms;
    z-index: 2;
    border-radius: 4px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
    display: flex;
}

.loadingWrap {
    display: flex;
    width: 100%;
    height: 100vh;
    align-items: center;
    justify-content: center;
}

.tooltip-wrap {
    background-color: var(--os02);
    display: flex;
    flex-direction: column;
    gap: 0;
    justify-content: center;
    align-items: center;
}

.tooltip-wrap .bold {
    font-weight: 550;
}

.cellDisplay {
    align-items: center;
    cursor: pointer;
    display: flex;
    gap: 8px;
    overflow: hidden;
    width: 100%;
}

.elevated {
    background-color: white;
    padding: 9px;
    display: flex;
    box-shadow: rgb(149 157 165 / 26%) 0px 0px 10px;
    flex-wrap: wrap;
    gap: 4px;
    /* width: calc(100% + 18px); */
    border-radius: 1px;
}

.classicFlex {
    display: flex;
    align-items: center;
    gap: 8px;
}

.lineWrap {
    display: flex;
    gap: 8px;
    align-items: center;
}

.highcharts-legend-item-hidden .highcharts-point {
    fill: var(--gamma-text);
}

.MuiButton-startIcon {
    width: 20px;
    height: 20px;
}

.MuiLoadingButton-loading .MuiButton-startIcon {
    display: none;
}

.MuiModal-root {
    outline: none;
}

.MuiBackdrop-root {
    outline: none;
}

.green {
    color: var(--alpha-green);
}

.red {
    color: rgb(var(--alpha-red));
}

.null {
    color: var(--light);
}

.normalCell {
    /* color: var(--alpha-text); */
    font-size: 13px;
    /* width: 100%; */
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

thead {
    text-align: left;
}

th { 
    font-weight: unset;
}