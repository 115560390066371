.pageWrap {
    display: flex;
    flex-direction: column;
    height: calc(var(--doc-height) - var(--nav-height));
    min-width: 1100px;
}

.wrapper {
    height: 100%;
    
}

.formWrapper {
    display: flex;
    padding: 0 32px;
    margin-top: 75px;
    justify-content: center;
    width: 100%;
    flex: 1;
    align-items: center;
    background-color: rgb(var(--background));
}

.formWrap {
    width: 336px;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.formWrap form {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.logo {
    width: 175px;
}

.formWrap p.subtitle {
    width: 100%;
    text-align: center;
}

.inputTitle{
    margin-bottom: 8px;
    margin-top: 16px;
    font-weight: 350;
}

.formWrap input.normal {
    /* font-size: 13px;
    color: var(--alpha-text);
    border-radius: 4px;
    background-color: rgb(var(--background));
    border: 1px solid var(--border-00);
    padding: 8px 12px;
    margin-top: 8px;
    outline: none; */
    border: 1px solid rgb(255 255 255 / 2.5%);
    background-color: rgb(255 255 255 / 2.5%);
    color: white;
    border-radius: 5px;
    height: 36px;
    font-size: 13px;
    font-weight: 400;
    outline: none;
    transition-property: background-color, color, border;
    transition-duration: 100ms;
    overflow: hidden;
    width: 100%;
    padding: 0 8px;
}

input.normal:hover{
    border: 1px solid rgb(var(--primary) / 70%);
}

input.normal:focus{
    background-color: transparent;
    border: 1px solid rgb(var(--primary) / 70%);
    box-shadow: 0 0 5px 3px rgb(var(--primary) / 10%);
}

.formWrap input.error {
    border: 1px solid rgb(var(--alpha-red));
    color: rgb(var(--alpha-red));
    background-color: var(--beta-red);
}

.formWrap .errorTxt {
    transition: max-height 0.2s ease;
    color: rgb(var(--alpha-red));
    margin-top: 12px;
    font-size: 13px;
}

.formWrap .loginBtn {
    border-radius: 4px;
    font-weight: 400;
    padding: 8px 24px;
    border: none;
    color: white;
    cursor: pointer;
    font-size: 12px;
    margin-top: 24px;
    box-shadow: none;
}

.formWrap .loginBtn:hover {
    box-shadow: none;
}

.formWrap .loginBtn :global(.MuiLoadingButton-loadingIndicator) {
    color: white;
}

.formWrap .loginBtn.inactive {
    background-color: rgb(var(--primary) / 50%);
}

.formWrap .loginBtn.active {
    background-color: rgb(var(--primary));
}

.bottomWrap {
    display: flex;
    justify-content: left;
    width: 336px;
    margin: 24px 0 16px 0;
    font-weight: 400;
}

.other {
    font-size: 13px;
    color: var(--alpha-text);
    width: 100%;
}

.other:hover {
    opacity: 0.8;
}

.title {
    font-weight: 500;
    font-size: 24px;
    font-family: "Poppins";
}

.subtitle{
    font-weight: 400;
    font-size: 15px;
    margin-top: 2px;
    margin-bottom: 8px;
    font-family: "Poppins";
    color: rgb(255 255 255 / 50%);
}

.nonStyledTitle {
    font-family: var(--main-font);
    font-size: 20px;
    text-align: center;
    font-weight: 500;
    margin-bottom: 16px;
}

.top{
    display: flex;
    justify-content: flex-end;
    padding: 0 24px;
    font-weight: 400;
    margin-top: 24px;
}

.top .link{
    color: rgb(var(--primary));
    cursor: pointer;
    margin-left: 8px;
    border: 1px solid rgb(var(--primary));
    padding: 5px 12px;
    border-radius: 4px;
}



.devBtn{
    color: rgb(var(--alpha) / 50%);
    font-size: 12px;
    margin-top: 16px;
    border: none;
    outline: none;
    padding: 0;
    cursor: pointer;
}

.devLogin{
    width: 336px;
}

.devLogin input.normal{
    width: 100%;
}

.devLogin button.loginBtn{
    width: 100%;
}

.otherWrap{
    margin-top: 16px;
    font-size: 13px;
}

.fauxLink{
    color: rgb(var(--primary));
    cursor: pointer;
    border: 1px solid rgb(var(--alpha-text));
    border-radius: 4px;
    text-decoration: underline;
}