.queryContainer {
    display: flex;
    gap: 16px;
}

.queryContainer .section {
    width: 50%;
}

.queryContainer .section .title {
    margin-bottom: 8px;
}

.queryContainer .section .sectionInner {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.filter {
    display: flex;
    align-items: center;
    gap: 8px;
}

.event {
    display: flex;
    align-items: center;
    gap: 8px;
}

.event .letter {
    height: 28px;
    min-width: 28px;
    border-radius: 50%;
    background-color: rgb(255 255 255 / 5%);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--border-00);
    font-size: 13px;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 50px;
}

.actions button {
    border: none;
    cursor: pointer;
    color: rgb(255 255 255);
    padding: 4px;
    transition: background-color 200ms;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.actions button:hover {
    background-color: rgb(255 255 255 / 10%);
}

.actions button svg {
    width: 100%;
    height: 100%;
}

.add {
    display: flex;
    align-items: center;
    border: none;
    gap: 8px;
    border-radius: 4px;
    color: rgb(var(--primary));
    padding: 4px 16px;
    cursor: pointer;
    width: fit-content;
}

.add:hover {
    background-color: rgb(var(--primary) / 5%);
}

.add .plus {
    font-size: 16px;
}


/* top bar */

.right {
    display: flex;
    align-items: center;
}

.outlineBtn.outlineBtn {
    background-color: transparent;
    border: 1px solid var(--border-00);
    transition: opacity 200ms;
    border-radius: 0;
    height: 32px;
    margin-right: 8px;
}

.outlineBtn.outlineBtn:hover {
    background-color: inherit;
    opacity: 0.75;
}

.addBtn{
    height: 32px;
    background-color: rgb(var(--primary));
}

.addBtn:hover{
    background-color: rgb(var(--primary) / 50%);
}

.queryTypeSelectWrap {
    position: relative;
    border: 1px solid var(--border-00);
    width: 175px;
    height: 32px;
    border-radius: 4px;
}

.queryTypeSelect {
    border-radius: 0;
    font-weight: 500;
    color: var(--alpha-text);
    outline: none;
    font-size: 13px;
    appearance: none;
    padding: 0px 12px;
    line-height: 1em;
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    z-index: 2;
}

.pageSelectIcon {
    width: 20px;
    color: var(--gamma-text);
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    z-index: 1;
}

.pageSelectIcon svg {
    width: 100%;
    height: 100%;
}

/* NO RESULTS */

.noResultsWrap{
    display: flex;
    width: 100%;
    height: 400px;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
}

.noResultsWrapInner{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--ndp02);
    backdrop-filter: blur(6px);
    padding: 16px;
    border-radius: 4px; */
}

.noResultsWrap .emptyIcon{
    height: 115px;
    margin-bottom: 16px;
}

.noResultsWrap .emptyIcon svg{
    width: 100%;
    height: 100%;
}


.noResultsWrap .title {
    font-size: 16px;
    font-weight: 500;
}

.noResultsWrap .description{
    font-size: 14px;
    color: rgb(255 255 255 / 35%);
    text-align: center;
    max-width: 350px;
    line-height: 1.4em;
    margin-top: 4px;
    font-weight: 300;
}

.noResultsWrap .description a.supportEmail{
    color: rgb(var(--primary));
    transition: opacity 200ms;
}

.noResultsWrap .description a.supportEmail:hover{
    opacity: 0.8;
}

.vert {
    background-color: var(--border-00);
    min-width: 1px;
    /* margin: 0 16px; */
}

.noResultsWrap .errorBtn{
    padding: 4px 20px;
    margin-top: 8px;
}

/* top */

.top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px 0px 24px;
    overflow: auto;
    white-space: nowrap;
}

.top .right{
    display: flex;
    align-items: center;
    gap: 8px;
}


/* chart  */

.chartTop{
    display: flex;
    align-items: center;
    margin-bottom: 16px;
    width: 100%;
    /* padding-right: 100px; */
}

.arrowWrap{
    background: var(--alpha-solid-05);
    font-weight: 400;
    height: 32px;
    margin-right: 15px;
    padding: 6px 24px;
    position: relative;
    display: flex;
    align-items: center;
    flex: 1;
    font-size: 12px;
    border: none;
    cursor: pointer;
    min-width: 175px;
}

.arrowWrap:last-child{
    margin-right: 0;
}

.arrowWrap .remove{
    display: none;
    align-items: center;
    gap: 4px;
    margin-left: -2px;
}

.arrowWrap:hover .remove{
    display: flex;
}

.arrowWrap:not(.add):hover .arrowLabel{
    display: none;
}

.arrowWrap.removeDisabled{
    cursor: unset;
}

.arrowWrap.removeDisabled:hover .arrowLabel{
    display: flex;
}

.arrowWrap.add{
    flex: unset;
    width: 150px;
    cursor: pointer;
}

.arrowWrap .arrowLeft{
    background: var(--alpha-solid-05);
    height: 32px;
    overflow: hidden;
    padding-right: 22.5px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transform-origin: left center;
    left: 0%;
}

.arrowWrap .arrowLeft::before{
    background: var(--card);
    border: 1px solid var(--card);
    border-radius: 0px 0px 1px;
    box-sizing: border-box;
    content: "";
    height: 100%;
    left: 0px;
    position: absolute;
    top: 0px;
    transform: rotate(-45deg);
    transform-origin: left bottom;
    width: 100%;
}

.arrowWrap .arrowRight{
    background: var(--card);
    height: 32px;
    left: 100%;
    overflow: hidden;
    padding-right: 22.5px;
    position: absolute;
    top: 50%;
    transform-origin: left center;
    transform: translate(-5%, -50%);
}

.arrowWrap .arrowRight::before{
    background: var(--alpha-solid-05);
    border: 1px solid var(--alpha-solid-05);
    border-radius: 0px 0px 1px;
    box-sizing: border-box;
    content: "";
    height: 100%;
    left: 0px;
    position: absolute;
    top: 0px;
    transform: rotate(-45deg);
    transform-origin: left bottom;
    width: 100%;
}

.arrowIcon{
    width: 16px;
    height: 16px;
}

.arrowIcon svg{
    width: 100%;
    height: 100%;
}

.arrowLabel{
    display: flex;
    align-items: center;
    gap: 4px;
    transition: opacity 200ms;
}

.pathfinderSankeyWrap{
    width: 100%;
    height: 100%;
    overflow-y: hidden;
    /* padding: 32px;
    padding-bottom: 64px;
    padding-top: 0; */
    padding: 16px 32px 16px 32px;
}
