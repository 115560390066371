/* query */

.filters .sectionInner {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.topContent{
    display: flex;
    gap: 16px;
}

.topContent .queryCard{
    min-width: 400px;
    width: 400px;
}

.queryCard .title{
    margin-bottom: 8px;
}

/* TOP BAR */

.right {
    display: flex;
    align-items: center;
}

.outlineBtn.outlineBtn {
    background-color: transparent;
    border: 1px solid var(--border-00);
    transition: opacity 200ms;
    border-radius: 0;
    height: 32px;
    margin-right: 8px;
}

.outlineBtn.outlineBtn:hover {
    background-color: inherit;
    opacity: 0.75;
}

.addBtn{
    height: 32px;
    background-color: rgb(var(--primary));
}

.addBtn:hover{
    background-color: rgb(var(--primary) / 50%);
}

.queryTypeSelectWrap {
    position: relative;
    border: 1px solid var(--border-00);
    width: 175px;
    height: 32px;
    border-radius: 4px;
}

.queryTypeSelect {
    border-radius: 0;
    font-weight: 500;
    color: var(--alpha-text);
    outline: none;
    font-size: 13px;
    appearance: none;
    padding: 0px 12px;
    line-height: 1em;
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    z-index: 2;
}

.pageSelectIcon {
    width: 20px;
    color: var(--gamma-text);
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    z-index: 1;
}

.pageSelectIcon svg {
    width: 100%;
    height: 100%;
}


/* RETENTION TABLE */

.retentionRow{
    display: flex;
    align-items: center;
}

.retentionRowWrap{
    display: flex;
    width: fit-content;
}

.retentionRowWrap .header{
}

.periodCell{
    display: flex;
    width: 90px;
    height: 36px;
    font-size: 12px;
    align-items: center;
    justify-content: center;
    /* background-color: rgb(var(--alpha) / 2.5%); */
    border-right: 1px solid var(--grid-line-color);
    border-bottom: 1px solid var(--grid-line-color);
}

.periodCell.header{
    width: 120px;
}

.periodCell:hover{
    opacity: 0.9;
}

.periodCellBlank{
    display: flex;
    width: 90px;
    height: 36px;
    align-items: center;
    justify-content: center;
    /* border: 1px solid rgb(var(--alpha) / 6%); */
    border-right: 1px solid var(--grid-line-color);
    border-bottom: 1px solid var(--grid-line-color);
}

.periodCellBlank:hover{
    background-color: var(--ndp02);
}



.retentionRowWrap .columnHeader{
    background-color: rgb(var(--alpha) / 10%)
}

.resultsWrapOuter{
    position: relative;
}

.resultsWrapOuter .headerColumn{
    display: flex;
    flex-direction: column;
    width: calc(85px * 2);
    position: sticky;
    left: 0;
    top: 0;
    bottom: 0;
}

.headerColumn .headerColumnRow{
    display: flex;
    align-items: center;
    height: 36px;
    min-width: 240px;
    border-bottom: 1px solid var(--grid-line-color);
    border-right: 1px solid var(--grid-line-color);
    background-color: rgb(var(--background));
    z-index: 1;
}

.headerColumn .headerColumnRow .periodCell{
    background-color: rgb(var(--alpha) / 5%);
}

.resultsWrapOuter .resultsWrap{
    position: absolute;
    left: calc(120px * 2);
    top: 0;
    width: calc(100% - (120px * 2));
    overflow: auto;
}

/* no results */

.noResultsWrap{
    display: flex;
    width: 100%;
    height: 400px;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
}

.noResultsWrapInner{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--ndp02);
    backdrop-filter: blur(6px);
    padding: 16px;
    border-radius: 4px; */
}
.noResultsWrap .emptyIcon{
    height: 115px;
    margin-bottom: 16px;
}

.noResultsWrap .emptyIcon svg{
    width: 100%;
    height: 100%;
}


.noResultsWrap .title {
    font-size: 16px;
    font-weight: 500;
}

.noResultsWrap .description{
    font-size: 14px;
    color: rgb(255 255 255 / 35%);
    text-align: center;
    max-width: 350px;
    line-height: 1.4em;
    margin-top: 4px;
    font-weight: 300;
}

.noResultsWrap .description a.supportEmail{
    color: rgb(var(--primary));
    transition: opacity 200ms;
}

.noResultsWrap .description a.supportEmail:hover{
    opacity: 0.8;
}

/* top options bar on results */

.top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    overflow: auto;
    white-space: nowrap;
}

.top .right{
    display: flex;
    align-items: center;
    gap: 8px;
}

.top .period{
    border: 1px solid var(--border-01);
    padding: 0px 8px;
    cursor: pointer;
    margin-right: -1px;
    z-index: 0;
    position: relative;
    height: 36px;
}

.top .period:first-child{
    border-radius: 4px 0 0 4px;
}

.top .period:last-child{
    border-radius: 0 4px 4px 0;
}

.top .period.selected{
    border: 1px solid var(--primary-border);
    background: rgb(var(--primary) / 10%);
    color: rgb(var(--primary));
    z-index: 1;
    border-radius: 4px;
}

.top .right .periodSelectWrap{
    margin-right: 8px;
}


.filter {
    display: flex;
    align-items: center;
    gap: 8px;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* min-width: 50px; */
}

.actions button {
    border: none;
    cursor: pointer;
    color: rgb(255 255 255);
    padding: 4px;
    transition: background-color 200ms;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.actions button:hover {
    background-color: rgb(255 255 255 / 10%);
}

.actions button svg {
    width: 100%;
    height: 100%;
}

.graphWrap{
    min-width: 400px;
}

.notFoundIcon{
    height: 32px;
    width: 32px;
    margin-bottom: 8px;
}

.notFoundIcon svg{
    width: 100%;
    height: 100%;
}

/* media query for all  */
@media screen and (max-width: 900px) {

    .topContent{
        flex-wrap: wrap;
    }

    .topContent .queryCard{
        width: 100%
    }

}