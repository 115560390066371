.queryContainer {
    display: flex;
    gap: 16px;
}

.queryContainer .section {
    width: 50%;
}

.queryContainer .section .title {
    margin-bottom: 8px;
}

.queryContainer .section .sectionInner {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.filter {
    display: flex;
    align-items: center;
    gap: 8px;
}

.event {
    display: flex;
    align-items: center;
    gap: 8px;
}

.event .letter {
    height: 28px;
    min-width: 28px;
    border-radius: 50%;
    background-color: rgb(255 255 255 / 5%);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--border-00);
    font-size: 13px;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.actions button {
    border: none;
    cursor: pointer;
    color: rgb(255 255 255);
    padding: 4px;
    transition: background-color 200ms;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.actions button:hover {
    background-color: rgb(255 255 255 / 10%);
}

.actions button svg {
    width: 100%;
    height: 100%;
}

.add {
    display: flex;
    align-items: center;
    border: none;
    gap: 8px;
    border-radius: 4px;
    color: rgb(var(--primary));
    padding: 4px 16px;
    cursor: pointer;
    width: fit-content;
}

.add:hover {
    background-color: rgb(var(--primary) / 5%);
}

.add .plus {
    font-size: 16px;
}


/* top bar */

.right {
    display: flex;
    align-items: center;
}

.outlineBtn.outlineBtn {
    background-color: transparent;
    border: 1px solid var(--border-00);
    transition: opacity 200ms;
    border-radius: 0;
    height: 32px;
    margin-right: 8px;
}

.outlineBtn.outlineBtn:hover {
    background-color: inherit;
    opacity: 0.75;
}

.addBtn{
    height: 32px;
    background-color: rgb(var(--primary));
}

.addBtn:hover{
    background-color: rgb(var(--primary) / 50%);
}

.queryTypeSelectWrap {
    position: relative;
    border: 1px solid var(--border-00);
    width: 175px;
    height: 32px;
    border-radius: 4px;
}

.queryTypeSelect {
    border-radius: 0;
    font-weight: 500;
    color: var(--alpha-text);
    outline: none;
    font-size: 13px;
    appearance: none;
    padding: 0px 12px;
    line-height: 1em;
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    z-index: 2;
}

.pageSelectIcon {
    width: 20px;
    color: var(--gamma-text);
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    z-index: 1;
}

.pageSelectIcon svg {
    width: 100%;
    height: 100%;
}

/* NO RESULTS */

.noResultsWrap{
    display: flex;
    width: 100%;
    height: 400px;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
}

.noResultsWrapInner{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--ndp02);
    backdrop-filter: blur(6px);
    padding: 16px;
    border-radius: 4px; */
}

.noResultsWrap .emptyIcon{
    height: 115px;
    margin-bottom: 16px;
}

.noResultsWrap .emptyIcon svg{
    width: 100%;
    height: 100%;
}


.noResultsWrap .title {
    font-size: 16px;
    font-weight: 500;
}

.noResultsWrap .description{
    font-size: 14px;
    color: rgb(255 255 255 / 35%);
    text-align: center;
    max-width: 350px;
    line-height: 1.4em;
    margin-top: 4px;
    font-weight: 300;
}

.noResultsWrap .description a.supportEmail{
    color: rgb(var(--primary));
    transition: opacity 200ms;
}

.noResultsWrap .description a.supportEmail:hover{
    opacity: 0.8;
}

.vert {
    background-color: var(--border-00);
    min-width: 1px;
    /* margin: 0 16px; */
}

.noResultsWrap .errorBtn{
    padding: 4px 20px;
    margin-top: 8px;
}

/* top */

.top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px 0px 24px;
    overflow: auto;
    white-space: nowrap;
}

.top .right{
    display: flex;
    align-items: center;
    gap: 8px;
}


.conversionWindowSelect{
    width: 300px;
    background-color: rgb(255 255 255 / 2.5%);
}

.conversionWindowInner{
    padding: 16px;
}

.conversionWindowSelect .title{
    font-size: 16px;
}

.conversionWindowSelect .description{
    font-size: 13px;
    color: rgb(255 255 255 / 35%);
    max-width: 350px;
    line-height: 1.4em;
    margin-top: 4px;
    font-weight: 300;
}

.conversionWindowSelect .inputWrap{
    display: flex;
    gap: 8px;
    margin-top: 8px;
}

.conversionWindowSelect .inputWrap .conversionNumberInput{
    width: 50%
}

.conversionWindowSelect .inputWrap .conversionUnitSelect{
    width: 50%
}

.conversionAdd{
    width: 100%;
    text-align: center;
    padding: 12px 0;
    background-color: rgb(var(--primary) / 50%);
    cursor: default;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    border: none;
    transition: background-color 200ms;
}

.conversionAdd.dirty{
    background-color: rgb(var(--primary));
    cursor: pointer;
}

.notFoundIcon{
    height: 48px;
    width: 48px;
    margin-bottom: 8px;
}

.notFoundIcon svg{
    width: 100%;
    height: 100%;
}

.userMenu{
    padding: 12px;
    margin-right: 36px;
    background-color: rgb(255 255 255 / 5%);
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 220px;
}

/* .userMenu .userMenuTop{
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    z-index: 1;
    left: 0;
    top: 8px;
    right: 8px;
} */

.userMenu .userMenuClose{
    padding: 0;
    width: 20px;
    height: 20px;
    border: none;
    display: flex;
    align-items: center;
    position: absolute;
    right: 8px;
    top: 8px;
    cursor: pointer;
    color: white;
    transition: color 100ms;
}

.userMenu .userMenuClose svg{
    width: 100%;
    height: 100%;
}

.userMenu .userMenuClose:hover{
    color: rgb(255 255 255 / 80%);
}

.userMenu .userMenuTitleWrap{
    display: flex;
    gap: 4px;
}

.userMenu .userMenuTitleWrap .userMenuIcon{
}

.userMenu .userMenuTitleWrap .userMenuTitle{
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
    padding-right: 20px;
}

.userMenu .userMenuValueWrap{
    margin: 8px 0;
    display: flex;
    gap: 4px;
}

.userMenu .userMenuValueWrap .userMenuValue{
    font-size: 13px;
}

.userMenu .userMenuValueWrap .userMenuValue.gray{
    color: rgb(255 255 255 / 50%);
}

.userMenu .userMenuActionWrap{
    width: 100%;
}

.userMenu .userMenuActionWrap .userMenuAction{
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid rgb(255 255 255 / 10%);
    width: 100%;
    padding: 4px;
    border-radius: 4px;
    transition: background-color 100ms;
    cursor: pointer;
}

.userMenu .userMenuActionWrap .userMenuAction:hover{
    background-color: rgb(255 255 255 / 5%);
}


.userSidebar{
    height: 100%;
    display: flex;
    flex-direction: column;
}

.userTop{
    padding: 16px;
    border-bottom: 1px solid rgb(255 255 255 / 5%);
}

.userTitle{
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    font-size: 16px;
}

.stepsIcon{
    background-color: rgb(var(--primary));
    width: 14px;
    height: 14px;
    border-radius: 2px;
}

.userValsWrap{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.userValsWrap .userSub{
    color: rgb(255 255 255 / 50%);
    font-weight: 400;
    margin-top: 4px;
}

.userValsWrap .userSubLink:hover{
    text-decoration: underline;
}

.userValsWrap .userVal{
    color: white;
    font-weight: 400;
    margin-top: 4px;
}

.userButtons{
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    margin-top: 16px;
}

.userButton .userIcon{
    width: 22px;
    height: 22px;
    color: rgb(255 255 255 / 50%);
    transition: color 200ms;

}

.userButton .userIcon svg{
    width: 100%;
    height: 100%;
}

.userButtons .userButton{
    border: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 11px;
    border-radius: 4px;
    gap: 4px;
    transition-property: background-color;
    color: rgb(255 255 255 / 50%);
    cursor: pointer;
    padding: 4px;
}

.userButtons .userButton:hover{
    background-color: rgb(var(--primary) / 5%);

}

.userButtons .userButton:hover .userIcon{
    color: rgb(var(--primary));
}

.sidebarRow{
    border-bottom: 1px solid rgb(255 255 255 / 5%);
    position: relative;
    transition: background-color 100ms;
    padding: 16px;
}

.sidebarRowInner{
    display: flex;
    justify-content: space-between;
}

.userTag{
    font-size: 10px;
    background-color: rgb(255 255 255 / 10%);
    border-radius: 999px;
    padding: 4px 6px;
    color: white;
}

.userText{
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.sidebarRow:hover{
    background-color: rgb(255 255 255 / 2.5%);
}

.sidebarRow .sidebarRowSub{
    color: rgb(255 255 255 / 50%);
    font-weight: 400;
}

.sidebarRowLink{
    display: flex;
    align-items: center;
    gap: 8px;
}

.explorerAbsWrap{
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
}

.explorerAbs{
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: color 100ms;
}

.explorerAbs:hover{
    color: rgb(var(--primary));
}

.explorerAbs svg{
    width: 100%;
    height: 100%;
}

.identicon {
    width: 24px !important;
    height: 24px !important;
    min-width: 24px;
    min-height: 24px;
    border-radius: 50%;
    border: 1px solid rgb(var(--alpha) / 5%);
    overflow: hidden;
    background-color: rgb(var(--alpha) / 2.5%);
}

.identiconSmall {
    width: 16px !important;
    height: 16px !important;
    min-width: 16px;
    min-height: 16px;
    border-radius: 50%;
    border: 1px solid rgb(var(--alpha) / 5%);
    overflow: hidden;
    background-color: rgb(var(--alpha) / 2.5%);
}

.inlineUserRowFlex{
    display: flex;
    gap: 4px;
    align-items: center;
}



.generalSidebar{
    overflow-y: auto;
    height: 100%;
}

.sidebarLoading{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}