.container{
    border: 1px solid var(--border-00);
    background-color: rgb(var(--background));
    width: 268px;
    padding-bottom: 8px;
}

.headerInputWrap{
    display: flex;
    background-color: var(--ndp02);
    padding: 8px;
    gap: 8px;
}

.headerInputWrap input.datepickerInput{
    background-color: var(--ndp02);
    border: none;
    height: 32px;
    padding: 0 8px;
    outline: none;
    color: var(--alpha-text);
    width: 50%;
}

.headerInputWrap .datepickerInput::-webkit-inner-spin-button {
  display: none;
}

.headerInputWrap .datepickerInput::-webkit-calendar-picker-indicator {
  display: none;
}

.headerContainer{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 10px;
    height: 40px;
    background-color: var(--ndp02);
    border-bottom: 1px solid var(--border-00);
    width: 100%;
}

.headerContainer .headerNavButton{
    border: none;
    padding: 0;
    cursor: pointer;
    width: 20px;
}

.headerNavButton svg{
    width: 20px;
    height: 20px;

}

.headerNavButtonEmpty{
    width: 20px;
}

.headerContainer .dayNames{
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 12px 16px;
    background: var(--ndp02);
}

.headerContainer .weekDay{
    background-color: red;
}

.container :global(.react-datepicker__day-names){
    display: flex;
    align-items: center;
    padding: 4px 8px;
    background-color: var(--ndp02);
    justify-content: space-between;
}

.container :global(.react-datepicker__day-names .react-datepicker__day-name){
    width: calc(100% / 7);
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.container :global(.react-datepicker__week){
    display: flex;
    align-items: center;
    padding: 4px 8px;
    justify-content: space-between;
}


.container :global(.react-datepicker__week .react-datepicker__day){
    width: calc(100% / 7);
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

/* .container :global(.react-datepicker__week .react-datepicker__day--today){
    color: rgb(var(--primary));
} */

.container .disabledDay{
    color: var(--gamma-text);
}

/* SELECTED DAY */

.container :global(.react-datepicker__week .react-datepicker__day.react-datepicker__day--selected){
    background-color: rgb(var(--primary) / 10%);
    color: rgb(var(--primary));
    border-radius: 5px;
}

.container :global(.react-datepicker__week .react-datepicker__day.react-datepicker__day--selected):hover{
    background-color: rgb(var(--primary) / 10%);
    color: rgb(var(--primary));
    border-radius: 5px 0 0 5px;
}

/* Date range start */

.container :global(.react-datepicker__week .react-datepicker__day.react-datepicker__day--selecting-range-start){
    border-radius: 5px 0 0 5px;
    background-color: rgb(var(--primary) / 10%);
    color: rgb(var(--primary));
}

.container :global(.react-datepicker__week .react-datepicker__day.react-datepicker__day--range-start){
    border-radius: 5px 0 0 5px;
    background-color: rgb(var(--primary) / 10%);
    color: rgb(var(--primary));
}

.container :global(.react-datepicker__week .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--range-start):hover{
    background-color: rgb(var(--primary) / 10%);
    color: rgb(var(--primary));
    border-radius: 5px 0 0 5px;
}

/* Date range end */

.container :global(.react-datepicker__week .react-datepicker__day.react-datepicker__day--selecting-range-end){
    border-radius: 0 5px 5px 0;
}

.container :global(.react-datepicker__week .react-datepicker__day.react-datepicker__day--range-end){
    border-radius: 0 5px 5px 0;
    background-color: rgb(var(--primary) / 10%);
    color: rgb(var(--primary));
}

.container :global(.react-datepicker__week .react-datepicker__day.react-datepicker__day--in-range.react-datepicker__day--range-end):hover{
    background-color: rgb(var(--primary) / 10%);
    color: rgb(var(--primary));
    border-radius: 0 5px 5px 0;
}

/* In selecting range */

.container :global(.react-datepicker__week .react-datepicker__day.react-datepicker__day--in-selecting-range){
    background-color: rgb(var(--primary) / 8%);
    color: rgb(var(--primary));
}

.container :global(.react-datepicker__week .react-datepicker__day.react-datepicker__day--in-selecting-range):hover{
    background-color: rgb(var(--primary) / 8%);
    color: rgb(var(--primary));
    border-radius: 0;
}

.container :global(.react-datepicker__week .react-datepicker__day.react-datepicker__day--in-range){
    background-color: rgb(var(--primary) / 10%);
    color: rgb(var(--primary));
}

.container :global(.react-datepicker__week .react-datepicker__day.react-datepicker__day--in-range):hover{
    background-color: rgb(var(--primary) / 10%);
    color: rgb(var(--primary));
    border-radius: 0;

}

/* General */

.container :global(.react-datepicker__week .react-datepicker__day):hover{
    background-color: var(--ndp02);
    border-radius: 5px;
}

.container :global(.react-datepicker__week .react-datepicker__day.react-datepicker__day--outside-month){
    color: var(--gamma-text);
}

.container :global(.react-datepicker__week .react-datepicker__day.react-datepicker__day--outside-month.react-datepicker__day--in-range){
    color: rgb(var(--primary));
}

.container :global(.react-datepicker__week .react-datepicker__day.react-datepicker__day--outside-month.react-datepicker__day--in-selecting-range){
    color: rgb(var(--primary));
}

.container :global(.react-datepicker__week .react-datepicker__day.react-datepicker__day--disabled){
    color: var(--gamma-text);
}

:global(.react-datepicker__aria-live){
    display: none;
}

/* 




.container :global(.react-datepicker__week .react-datepicker__day :not(.react-datepicker__day--selected, .react-datepicker__day--range-start)):hover{
    background-color: var(--ndp02);
}


 */

 .bottomWrap{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 8px 8px;
    background: var(--ndp02);
    margin-top: 8px;
    gap: 8px;
 }


 .applyButton{
    background-color: rgb(var(--primary));
    color: white;
    padding: 7px 12px;
    font-size: 13px;
    cursor: pointer;
    border: none;
 }

 .outlineButton{
    background-color: transparent;
    color: var(--alpha-text);
    border: 1px solid var(--border-01);
    padding: 7px 12px;
    font-size: 13px;
    cursor: pointer;
 }

 :global(.react-datepicker-popper){
    z-index: 2;
 }