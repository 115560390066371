.title {
    margin-bottom: 8px;
}

.filter {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 8px;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 50px;
}

.actions button {
    border: none;
    cursor: pointer;
    color: rgb(255 255 255);
    padding: 4px;
    transition: background-color 200ms;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.actions button:hover {
    background-color: rgb(255 255 255 / 10%);
}

.actions button svg {
    width: 100%;
    height: 100%;
}

.topBar{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 12px;
}

.topBar .search{
    height: 32px;
    background-color: rgb(255 255 255 / 0.02);
    border: 1px solid rgb(255 255 255 / 0);
}

.barAboveTable{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.barAboveTable .barAboveTableRight{
    display: flex;
    align-items: center;
    gap: 8px;
}

.outline{
    border: 1px solid rgb(255 255 255 / 0.1);
    border-radius: 4px;
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    white-space: nowrap;
    cursor: pointer;
    transition-property: background-color, border-color;
    transition-duration: 200ms;
    outline: none;
}

.outline.disabled{
    background-color: rgb(255 255 255 / 0.04);
    border: 1px solid rgb(255 255 255 / 0.04);
    color: rgb(255 255 255 / 0.5);
    cursor: not-allowed;
}

.outline:not(.disabled):hover{
    background-color: rgb(var(--primary) / 5%);
    color: rgb(var(--primary));
    border: 1px solid rgb(var(--primary) / 5%);
}

.filled{
    background-color: rgb(var(--primary));
    border: none;
    padding: 8px;
    border-radius: 4px;
    white-space: nowrap;
    cursor: pointer;
    transition-property: background-color, border-color;
    transition-duration: 200ms;
    outline: none;
}

.filled:hover{
    background-color: rgb(var(--primary) / 80%);
}

.chains{
    display: flex;
    align-items: center;
    gap: 4px;
}

.platformIcon{
    width: 18px;
    height: 18px;
}


.eventTable{
    overflow: auto;
    position: relative;
}

.eventRow{
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border-bottom: 1px solid rgb(255 255 255 / 0.05);
    cursor: pointer;
    gap: 32px;
    width: 100%;
}

.eventHeaderRow{
    display: flex;
    align-items: center;
    background-color: rgb(var(--alpha) / 0.025);
    padding: 12px 16px;
    gap: 32px;
    width: 100%;
    border-bottom: 1px solid rgb(255 255 255 / 0.05);
}

.eventCell{
    /* min-width: 250px;
    max-width: 250px; */
    /* flex: 1; */
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.collapseButton{
    border: none;
    background-color: transparent;
    cursor: pointer;
    outline: none;
    padding: 0;
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    margin-right: 8px;
}

.collapseButtonEmpty{
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    margin-right: 8px;
}

.collapseButton:hover{
    background-color: rgb(var(--primary) / 0.05);
    color: rgb(var(--primary));
    border-radius: 4px;
}

.collapseButton .collapseIcon{
    display: flex;
    justify-content: center;
    width: 100%;
}

.collapseButton .collapseIcon svg{
    width: 14px;
    height: 14px;
}

.collapsed{
    border-bottom: 1px solid rgb(255 255 255 / 0.05);
    padding: 12px 24px;
    background-color: rgb(255 255 255 / 2.5%);
    padding-left: 64px;
}

.collapsedInner{
    display: flex;
    flex-wrap: wrap;
    gap: 0 16px;
}

.collapsedCell{
    display: flex;
    align-items: center;
    width: calc((100% / 3) - 16px);
    padding: 8px 0;
    gap: 8px;
    font-size: 12px;
    border-bottom: 1px solid rgb(255 255 255 / 0.05);
}

.collapsedCell.lastLine{
    border-bottom: none;
}

.collapsedCell .collapsedTitle{
    color: rgb(255 255 255 / 0.5);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    flex-shrink: 0;
}

.collapsedCell .collapsedText{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.userLink{
    color: rgb(var(--primary));
}

.userLink:hover{
    text-decoration: underline;
}

.eventBottom{
    display: flex;
    justify-content: center;
    padding: 32px 0;
}

.eventBottomInner{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loadBtn{
    border: 1px solid rgb(255 255 255 / 0.1);
    width: fit-content;
    color: rgb(255 255 255);
    padding: 12px 16px;
    border-radius: 4px;
    white-space: nowrap;
    cursor: pointer;
    transition-property: background-color, border-color;
    transition-duration: 200ms;
    outline: none;
    margin-top: 16px;
}

.loadBtn:hover{
    background-color: rgb(255 255 255 / 0.05);
    border: 1px solid rgb(255 255 255 / 0.05);
}

.loading{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
}

.stockLoadingButton{
    display: flex;
    align-items: center;
    gap: 8px;
}

.topTitleContainer{
    display: flex;
    align-items: center;
    gap: 8px;
}

.topTitle{
    font-size: 24px;
}

.topSubtitle{
    color: rgb(255 255 255 / 0.5);
}

.refreshButton{
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;
    cursor: pointer;
    padding: 4px;
}

.refreshButton:hover{
    background-color: rgb(var(--primary) / 0.1);
    border-radius: 4px;
    color: rgb(var(--primary));
}

.refreshButton svg{
    width: 18px;
    height: 18px;
}

.linearProgressWrap{
    width: 100%;
    height: 4px;
}

.barAboveTableLeft{
    display: flex;
    align-items: center;
}


.barAboveTableLeft .eventTypeSelect:first-child{
    border-radius: 4px 0 0 4px;
}

.barAboveTableLeft .eventTypeSelect:last-child{
    border-radius: 0 4px 4px 0;
}

.eventTypeSelect{
    border: 1px solid var(--border-01);
    padding: 0px 8px;
    cursor: pointer;
    margin-right: -1px;
    z-index: 0;
    position: relative;
    height: 36px;
}

.eventTypeSelect.selected{
    border: 1px solid var(--primary-border);
    background: rgb(var(--primary) / 10%);
    color: rgb(var(--primary));
    z-index: 1;
    border-radius: 4px;
}

.collapsedTop{
    display: flex;
    align-items: center;
    margin-bottom: 16px;
}

.collapsedTop .collapseLink:first-child{
    border-radius: 4px 0 0 4px;
}

.collapsedTop .collapseLink:last-child{
    border-radius: 0 4px 4px 0;
}

.collapseLink{
    padding: 8px;
    border: 1px solid rgb(255 255 255 / 0.1);
    margin-right: -1px;
    transition-property: background-color, border-color, color;
    transition-duration: 100ms;
}

.collapseLink:hover{
    color: rgb(var(--primary));
    background-color: rgb(var(--primary) / 0.05);
    border: 1px solid rgb(var(--primary) / 1);
    border-radius: 4px;
}

.collapseLinkInline{
    color: rgb(var(--primary));
    text-decoration: underline;
}

.simpleFlex{
    display: flex;
    align-items: center;
    gap: 8px;
}


.noWrap {
    border-bottom: 1px solid rgb(255 255 255 / 10%);
    /* padding: 16px; */
    height: 300px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
}

.noWrap .noTitle {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 4px;
}

.noWrap .noDescription {
    font-size: 14px;
    color: rgb(255 255 255 / 50%);
    text-align: center;
}
