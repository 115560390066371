.topBar{
    background-color: rgb(var(--background));
    border-bottom: 1px solid var(--border-00);
    height: var(--nav-height);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    width: 100%;
}

.topBar.white{
    background-color: rgb(255 255 255 / 2.5%);
}