.queryContainer {
    display: flex;
    gap: 16px;
}

.queryContainer .section {
    width: 50%;
}

.queryContainer .section .sectionInner {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.queryContainer .section .title {
    margin-bottom: 8px;
}

.metric {
    display: flex;
    align-items: center;
    gap: 8px;
}

.metric .letter {
    height: 28px;
    min-width: 28px;
    border-radius: 50%;
    background-color: rgb(255 255 255 / 5%);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--border-00);
    font-size: 13px;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    min-width: 50px;
}

.actions button {
    border: none;
    cursor: pointer;
    color: rgb(255 255 255);
    padding: 4px;
    transition: background-color 200ms;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.actions button:hover {
    background-color: rgb(255 255 255 / 10%);
}

.actions button svg {
    width: 100%;
    height: 100%;
}

.add {
    display: flex;
    align-items: center;
    border: none;
    gap: 8px;
    border-radius: 4px;
    color: rgb(var(--primary));
    padding: 4px 16px;
    cursor: pointer;
    width: fit-content;
}

.add:hover {
    background-color: rgb(var(--primary) / 5%);
}

.add .plus {
    font-size: 16px;
}

.metric .right {
    max-width: 200px;
}

.filter {
    display: flex;
    align-items: center;
    gap: 8px;
}

/* top bar */


/* TOP BAR */

.right {
    display: flex;
    align-items: center;
}

.outlineBtn.outlineBtn {
    background-color: transparent;
    border: 1px solid var(--border-00);
    transition: opacity 200ms;
    border-radius: 4px;
    height: 32px;
    margin-right: 8px;
}

.outlineBtn.outlineBtn:hover {
    background-color: inherit;
    opacity: 0.75;
}

.addBtn {
    height: 32px;
    background-color: rgb(var(--primary));
}

.addBtn:hover {
    background-color: rgb(var(--primary) / 50%);
}

.queryTypeSelectWrap {
    position: relative;
    border: 1px solid var(--border-00);
    width: 175px;
    height: 32px;
    border-radius: 4px;
}

.queryTypeSelect {
    border-radius: 0;
    font-weight: 500;
    color: var(--alpha-text);
    outline: none;
    font-size: 13px;
    appearance: none;
    padding: 0px 12px;
    line-height: 1em;
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    z-index: 2;
}

.pageSelectIcon {
    width: 20px;
    color: var(--gamma-text);
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    z-index: 1;
}

.pageSelectIcon svg {
    width: 100%;
    height: 100%;
}


.noResultsWrap .errorBtn{
    padding: 4px 20px;
    margin-top: 8px;
}


.queryModal .inputTitle{
    margin: 16px 0 8px 0;
    color: var(--light);

}

.queryModal .inputTitle .asterisk{
    color: rgb(var(--alpha-red));
    margin-left: 2px;
}

.queryModal .input{
    font-size: 13px;
    background: var(--ndp02);
    border: none;
    height: 36px;
    border-radius: 5px;
    padding: 0 16px;
    outline: none;
    color: var(--alpha-text);
    width: 100%;
}

.queryModal .input.error {
    border: 1px solid rgb(var(--alpha-red));
    color: rgb(var(--alpha-red));
    background-color: var(--beta-red);

}

.queryModal .loadingButton{
    margin-top: 24px;
    height: 36px;
    width: 100%;
}

.deleteModal .buttonContainer{
    display: flex;
    gap: 8px;
    margin-top: 16px;
}

.deleteModal .title{
    font-size: 18px;
    font-weight: 550;
    margin-bottom: 8px;
}

.deleteModal .description{
    font-size: 15px;
    color: var(--gamma-text);
    max-width: 450px;
    line-height: 1.4em;
    margin-bottom: 8px;
}

.deleteModal .deleteBtn{
}


.deleteModal .outlineBtn {
    background-color: transparent;
    border: 1px solid var(--border-00);
    transition: opacity 200ms;
    border-radius: 4px;
    height: 32px;
}

.deleteModal .outlineBtn:hover {
    background-color: inherit;
    opacity: 0.75;
}

.noResultsWrap{
    display: flex;
    width: 100%;
    height: 400px;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
}

.noResultsWrapInner{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--ndp02);
    backdrop-filter: blur(6px);
    padding: 16px;
    border-radius: 4px; */
}

.noResultsWrap .emptyIcon{
    height: 125px;
    margin-bottom: 16px;
}

.noResultsWrap .emptyIcon svg{
    width: 100%;
    height: 100%;
}


.noResultsWrap .title {
    font-size: 16px;
    font-weight: 500;
}

.noResultsWrap .description{
    font-size: 14px;
    color: rgb(255 255 255 / 35%);
    text-align: center;
    max-width: 350px;
    line-height: 1.4em;
    margin-top: 4px;
    font-weight: 300;
}

.noResultsWrap .description a.supportEmail{
    color: rgb(var(--primary));
    transition: opacity 200ms;
}

.noResultsWrap .description a.supportEmail:hover{
    opacity: 0.8;
}

.noResultsWrap .errorBtn{
    padding: 4px 20px;
    margin-top: 8px;
}



.top{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    border-bottom: 1px solid var(--border-00);
    overflow: auto;
    white-space: nowrap;
}

.top .right{
    display: flex;
    align-items: center;
    gap: 8px;
}

.top .period{
    border: 1px solid var(--border-01);
    padding: 0px 8px;
    cursor: pointer;
    margin-right: -1px;
    z-index: 0;
    position: relative;
    height: 36px;
}

.top .period:first-child{
    border-radius: 4px 0 0 4px;
}

.top .period:last-child{
    border-radius: 0 4px 4px 0;
}

.top .period.selected{
    border: 1px solid var(--primary-border);
    background: rgb(var(--primary) / 10%);
    color: rgb(var(--primary));
    z-index: 1;
    border-radius: 4px;
}

.top .right .periodSelectWrap{
    margin-right: 8px;
}
