.sidebarWrap{
    position: absolute;
    z-index: 10;
    background-color: rgb(var(--background));
    right: 0;
    top: var(--nav-height);
    width: 400px;
    height: calc(var(--doc-height) - var(--nav-height));
    box-shadow: rgba(0, 0, 0, 0.2) -16px 0px 10px;
}

.sidebarWrap .sidebarInner{
    background-color: rgb(255 255 255 / 2.5%);
    height: 100%;
    display: flex;
    flex-direction: column;
    /* position: sticky; */
}

.sidebarWrap .sidebarInner .sidebarTop{
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgb(255 255 255 / 5%);
}

.sidebarWrap .sidebarInner .sidebarTop .sidebarTitle{
    font-size: 16px;
}

.sidebarWrap .sidebarInner .sidebarTop .sidebarClose{
    border: none;
    cursor: pointer;
    height: 22px;
    width: 22px;
    padding: 0;
    color: white;
    transition: color 100ms;
}

.sidebarWrap .sidebarInner .sidebarTop .sidebarClose:hover{
    color: rgb(255 255 255 / 80%);
}

.sidebarWrap .sidebarInner .sidebarTop .sidebarClose svg{
    width: 100%;
    height: 100%;
}

.sidebarContent{
    height: 100%;
    flex: 1;
    /* overflow: auto; */
    overflow: hidden;
}

.sidebarLoading{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}


