

/* v2 */

.infoWidgetRow{
    display: flex;
    margin-bottom: 16px;
    gap: 16px;
}

.widgetRow{
    display: flex;
    gap: 16px;
    margin-bottom: 16px;
}

.infoWidget{
    border: 1px solid rgb(255 255 255 / 5%);
    padding: 12px;
    width: calc((100%) / 2);
    display: flex;
    flex-direction: column;
    text-align: left;
    /* glow */
    transition-property: background-color, box-shadow;
    transition-duration: 100ms;
}

.infoWidget:hover{
    background-color: rgb(255 255 255 / 2.5%);
    cursor: pointer;
    /* glow */
    box-shadow: rgb(255 255 255 / 5%) 0px 0px 10px;
}

.infoWidget .infoWidgetTitle{
    font-size: 15px;
    margin-bottom: 8px;

}

.infoWidget .infoWidgetSubtitle{
    font-size: 13px;
    color: rgb(255 255 255 / 50%);
    margin-bottom: 16px;
}

.infoWidget .infoWidgetCTA{
    padding: 8px;
    border: 1px solid rgb(255 255 255 / 10%);
    border-radius: 4px;
    background-color: rgb(255 255 255 / 5%);
    display: inline-block;
    margin-top: auto;
    width: fit-content;
}

.overviewWrap{
    display: flex;
    flex-direction: column;
}

.widgetRow{

}

.widget{
    overflow: hidden;
    border: 1px solid rgb(255 255 255 / 5%);
    display: flex;
    flex-direction: column;
    height: 500px;
    width: calc(100%/3);
}


.w-1-2{
    width: calc(100%/2);
}

.w-2-3{
    width: calc(100%/3 * 2);
}

.widget .widgetBody{
    overflow: auto;
}


.widget .widgetBody::-webkit-scrollbar-gutter{
    height: 0;
}

.widget .widgetBodyStatic{
    /* flex: 1; */
    height: 100%;
    width: 100%;
}

.widget .scrollLoading{
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
}

.widgetLoadingOuter{
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 100%;
    width: 100%;
}

.widget .widgetHeader{
    display: flex;
    border-bottom: 1px solid rgb(255 255 255 / 10%);
    padding: 12px;
    position: relative;
}


.widget .widgetHeader .widgetHeaderLeft{
    display: flex;
    align-items: flex-start;
}

.widget .widgetHeader .widgetLine{
    min-width: 4px;
    border-radius: 2px;
    margin-right: 8px;
    align-self: stretch;
}

.widget .widgetHeader .widgetHeaderTextWrap{
    display: flex;
    flex-direction: column;
}

.widget .widgetHeader .widgetHeaderTextWrap .widgetHeaderDate{
    font-size: 11px;
    color: rgb(255 255 255 / 40%);
    margin-bottom: 3px;
    text-transform: uppercase;
    font-weight: 500;
}

.widget .widgetHeader .widgetHeaderTextWrap .widgetHeaderTitle{
    font-size: 16px;
    color: white;
    font-weight: 500;
    margin-bottom: 4px;
}

.widget .widgetHeader .widgetHeaderTextWrap .widgetHeaderSubtitle{
    font-size: 13px;
    font-weight: 400;
    flex-wrap: wrap;
}

.widget .widgetHeader .widgetHeaderRight{
    width: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: flex-start;
}

.widget .widgetHeader .widgetOptionsBtn{
    border: none;
    background-color: transparent;
    color: white;
    cursor: pointer;
    transition-property: color;
    transition-duration: 100ms;
    position: absolute;
    right: 16px;
    top: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.widget .widgetHeader .widgetViewMoreBtn{
    font-weight: 400;
    border: none;
    background-color: transparent;
    color: rgb(var(--primary));
    cursor: pointer;
    position: absolute;
    right: 16px;
    top: 16px;
    padding: 8px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    transition-property: background-color, color;
    transition-duration: 100ms;
}

.widget .widgetHeader .widgetViewMoreBtn .widgetViewMoreBtnIcon{
    margin-right: 8px;
}

.widget .widgetHeader .widgetViewMoreBtn .widgetViewMoreBtnIcon svg{
    width: 14px;
    height: 14px;
}

.widget .widgetHeader .widgetViewMoreBtn:hover{
    /* color: rgb(var(--primary) / 80%); */
    background-color: rgb(var(--primary) / 10%);

}

.widget .widgetHeader .widgetOptionsBtn:hover{
    background-color: rgb(255 255 255 / 5%);
}

.widget .widgetHeader .widgetOptionsBtn svg{
    width: 20px;
    height: 20px;
}

.widgetOptions{
    display: flex;
    flex-direction: column;
    width: auto;
    padding: 8px;
}

.widgetOptions .widgetOption{
    display: flex;
    align-items: center;
    transition-property: background-color;
    transition-duration: 100ms;
    padding: 8px;
    gap: 16px;
    border-radius: 4px;
    border: none;
    cursor: pointer;
}

.widgetOptions .widgetOption:hover{
    /* background-color: rgb(255 255 255 / 5%); */
    background-color: rgb(255 255 255 / 10%);
}

.chains{
    display: flex;
    align-items: center;
    gap: 4px;
}

.platformIcon{
    width: 18px;
    height: 18px;
}

/* tablev2 */

.widgetTable{
    width: 100%;
}

.widgetTable .widgetTableHeaderWrap{
    border-bottom: 1px solid rgb(255 255 255 / 10%);
    background-color: rgb(255 255 255 / 2.5%);

}

.widgetTable .widgetTableHeaderWrap .tableHeader{
    font-weight: 400;
    text-align: left;
    padding: 8px 10px;

}

.widgetTable  .tableRow {
    border-bottom: 1px solid rgb(255 255 255 / 10%);
}

.widgetBodyScrollable a.tableRow{
    cursor: pointer;
}

.widgetTable .tableRow .tableCell{
    padding:  8px 12px;
    white-space: nowrap;
}

.insightBody{
    display: flex;
    flex-direction: column;
    overflow: auto;
    padding: 8px;
    gap: 4px;
}

.insight{
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 8px;
}

.insight:hover{
    background-color: rgb(255 255 255 / 5%);
    cursor: pointer;
}

.insight .insightIcon{
    min-width: 32px;
    min-height: 32px;
    border-radius: 4px;
    background-color: rgb(255 255 255 / 5%);
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
}

.insight .insightIcon svg{
    width: 20px;
    height: 20px;
}

.insight .insightText{
    display: flex;
    flex-direction: column;
    gap: 4px;
    overflow: hidden;
}

.insight .insightText .insightTitle{
    color: white;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

.insight .insightText .insightSubtitle{
    color: rgb(255 255 255 / 50%);
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
}

/* create wrap */

.newPopoverWrap {
    margin-left: 8px;
}

.newPopoverWrapInner {
    background-color: rgb(var(--alpha) / 5%);
    display: flex;
    flex-direction: column;
    width: 350px;
}

.newPopoverWrap .title {
    font-size: 20px;
}

.newPopoverWrap .createOptionText {
    display: flex;
    flex-direction: column;
    /* justify-content: flex-start; */
    text-align: left;
}

.newPopoverWrap .circle {
    background-color: rgb(var(--alpha) / 5%);
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--alpha-text);
}

.newPopoverWrap .circle svg {
    width: 18px;
    height: 18px;
}

.newPopoverWrap .createOption {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 12px 12px;
    cursor: pointer;
    transition-duration: 100ms;
    transition-property: background-color;
    border: 1px solid rgb(var(--alpha) / 2.5%);
    min-height: 75px;
    border-left: none;
    border-top: none;
}

.newPopoverWrap .createOption:hover {
    background-color: rgb(var(--alpha) / 2.5%);
}

.newPopoverWrap .createOption .title {
    font-size: 14px;
    font-weight: 500;
    color: var(--alpha-text);
}

.newPopoverWrap .createOption .description {
    color: var(--gamma-text);
    font-size: 13px;
    line-height: 1.4em;
    margin-top: 5px;
}


.popoverTitle{
    color: rgb(255 255 255 / 50%);
}

.popperItemWrap{
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: 8px 0;
}

.createItemWrap {
    width: calc(100%/3);
    display: flex;
    border: none;
    transition: all 100ms;
    align-items: center;
    gap: 16px;
    padding: 16px;
    border-radius: 4px;
}

.createItemWrap:hover {
    cursor: pointer;
    background-color: rgb(var(--alpha) / 5%);
}

.createItemWrap .createItemIcon {
    min-width: 30px;
    width: 30px;
    color: var(--alpha-text);
}

.createItemWrap .createItemIcon svg {
    width: 100%;
    height: 100%;
}

.createItemTextWrap {
    display: flex;
    flex-direction: column;
    text-align: left;
}

.createItemTextWrap .createItemTitle {
    font-size: 14px;
    font-weight: 500;
    color: var(--alpha-text);
    position: relative;
    display: flex;
    align-items: center;
}

.createItemTextWrap .createItemDescription {
    font-size: 12px;
    font-weight: 400;
    color: rgb(255 255 255 / 50%);
    margin-top: 8px;
}

.emptyWrap{
    height: calc(var(--doc-height) - var(--header-size));
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.emptyWrap .emptyContent{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 350px;

}

.emptyWrap .emptyHome{
    width: 350px;
}

.emptyWrap .emptyContent .emptyTitle{
    font-size: 18px;
    margin-bottom: 12px;
}

.emptyWrap .emptyContent .emptySubtitle{
    font-size: 14px;
    color: rgb(255 255 255 / 50%);
    text-align: center;
    line-height: 1.5em;
    margin-bottom: 12px;
}

.emptyWrap .emptyContent .emptyBtn{
    padding: 8px 16px;
    border-radius: 4px;
    background-color: rgb(var(--primary));
    color: white;
    font-size: 13px;
    cursor: pointer;
    transition-property: background-color;
    transition-duration: 100ms;
}

.insightBodyEmpty{
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    padding: 12px;
}

.insightBodyEmpty .insightEmptyTitle{
    font-size: 18px;
    margin-bottom: 4px;
}

.insightBodyEmpty .insightEmptySubtitle{
    font-size: 14px;
    color: rgb(255 255 255 / 50%);
    text-align: center;
    line-height: 1.5em;
    margin-bottom: 8px;
}

.insightBodyEmpty .insightEmptySubtitle .insightEmptyBtnInline{
    color: rgb(var(--primary));
    font-size: 14px;
    padding: 0;
    cursor: pointer;
    transition-property: color;
    transition-duration: 100ms;
    border: none;
}

.insightBodyEmpty .insightEmptySubtitle .insightEmptyBtnInline:hover{
    color: rgb(var(--primary) / 80%);
}

.insightBodyEmpty .insightEmptyIcon{
    width: 24px;
    height: 24px;
    margin-bottom: 8px;
    color: rgb(255 255 255 / 50%);
}

.insightBodyEmpty .insightEmptyIcon svg{
    width: 100%;
    height: 100%;
}

/* .insightBodyEmpty .insightEmptyBtn{
    padding: 8px 16px;
    border-radius: 4px;
    background-color: rgb(var(--primary));
    color: white;
    font-size: 13px;
    cursor: pointer;
    transition-property: background-color;
    transition-duration: 100ms;
    border: none;
}

.insightBodyEmpty .insightEmptyBtn:hover{
    background-color: rgb(var(--primary) / 80%);
} */

.lightUserHover:hover{
    text-decoration: underline;
    cursor: pointer;
    color: rgb(var(--primary));
}