.templatesWrap{
    display: flex;
    align-items: center;
    gap: 16px;
}

.templatesWrap .template{
    width: 25%;
    padding: 16px;
    border: 1px solid rgb(255 255 255 / 10%);
    cursor: pointer;
    transition-property: background-color;
    transition-duration: 200ms;
}

.templatesWrap .template:hover{
    background-color: rgb(255 255 255 / 5%);
}

.queryBox{
    padding: 16px;
    border: 1px solid rgb(255 255 255 / 5%);
    margin-top: 16px;
}

.mainInput{
    min-width: 200px;
}

.filterWrap{
    margin-top: 16px;
}

.filterWrap .filterSubtitle{
    margin-bottom: 8px;
}