.pageWrap {
    display: flex;
    flex-direction: column;
    height: calc(var(--doc-height));
}

.wrapper {
    display: flex;
    padding: 32px;
    justify-content: center;
    width: 100%;
    flex: 1;
    align-items: center;
    background-color: rgb(var(--background));
}


.innerWrap{
    display: flex;
    flex-direction: column;
    width: 400px;
    align-items: center;

}

.pageTitle {
    font-size: 24px;
    font-weight: 500;
}

.pageSubtitle{
    text-align: center;
    font-size: 15px;
    font-weight: 300;
    margin-bottom: 16px;
}

.innerWrap input{
    font-size: 14px;
    color: var(--alpha-text);
    border-radius: 4px;
    background-color: var(--ndp02);
    border: none;
    padding: 10px 18px;
    margin-top: 24px;
    outline: none;
    width: 100%;
}


.innerWrap input:-webkit-autofill {
    -webkit-text-fill-color: var(--alpha-text) !important;
    /* -webkit-box-shadow: 0 0 0px 1000px var(--ndp02) inset; */
    box-shadow: 0 0 0px 1000px var(--ndp02) inset;
    transition: background-color 5000s ease-in-out 0s;
    caret-color: var(--alpha-text);
}

.innerWrap input.error{
    border: 1px solid rgb(var(--alpha-red));
    color: rgb(var(--alpha-red));
    background-color: var(--beta-red);
}

.innerWrap .btn {
    color: white;
    font-size: 14px;
    width: 100%;
    /* border: 1px solid var(--border-00); */
    padding: 10px 24px;
    /* background: rgb(var(--primary) / 50%); */
    font-weight: 400;
    cursor: pointer;
    pointer-events: auto;
    box-shadow: none;
    text-transform: none;
    margin-top: 16px;
}

.innerWrap .btn:hover{
    background-color: var(--ndp01);
}

.innerWrap .btn :global(.MuiLoadingButton-loadingIndicator) {
    color: white;
  }

.innerWrap .btn.inactive {
    background-color: rgb(var(--primary) / 50%);
}

.innerWrap .btn.active {
    background-color: rgb(var(--primary));
}


.columnForm{
    display: flex;
    flex-direction: column;
    width: 100%;
}

.errorTxt {
    color: rgb(var(--alpha-red));
    margin-left: 8px;
    margin-top: 7px;
    font-size: 13px;
}

.bottomWrap {
    display: flex;
    justify-content: center;
    width: 500px;
    margin-top: 24px;
}

.otherWrap {
    font-size: 14px;
    color: var(--alpha-text);
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: 24px;
}

@media screen and (max-width: 900px) {

    .wrapper{
        flex-wrap: wrap;
        padding-top: 0;
    }

}