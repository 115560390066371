
.watermark{
    background-position: calc(50% + 32px) calc(50% - 48px);
    background-repeat: no-repeat;
    background-size: 175px;
  }

  .invisible{
    fill: var(--gamma-text);
  }


.legendItemWrap{
    font-weight: 400;
    font-size: 12px;
    fill: var(--alpha-text);
}

.dot{
    min-width: 10px;
    min-height: 10px;
    border-radius: 5px;
    margin-top: 4px;
}

.piechartTooltipWrap{
    display: flex;
    align-items: flex-start;
    gap: 7px;
    padding: 6px 8px;
    border-radius: 2px;
    background-color: var(--sdp02);
}

.timechartTooltipWrap{
    display: flex;
    flex-direction: column;
    background-color: var(--sdp02);
    padding: 8px;
    border-radius: 2px;
}

.timechartInfoWrap{
    display: flex;
    align-items: flex-start;
    gap: 7px;
}

.normalTooltipWrap{
    display: flex;
    flex-direction: column;
    background-color: var(--sdp02);
    padding: 8px;
    border-radius: 2px;

}

.normalInfoWrap{
    display: flex;
    align-items: flex-start;
    gap: 7px;
}


.tooltipText{
    /* font-size: 14px;
    color: var(--alpha-text);
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis; */
    font-size: 12px;
    color: white;
    line-height: 1.4em;
    display: flex;
    align-items: center;
}

.tooltipTitle{
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.tooltipTextSub{
    font-size: 12px;
    color: rgb(var(--alpha) / 60%)
}

.tooltipWrap {
    display: flex;
    flex-direction: column;
}

.tooltipTime {
    display: flex;
    align-items: center;
    font-size: 11px;
}

.tooltipValueWrap{
    color: var(--alpha-text);
    padding-bottom: 8px;
    font-size: 12px;
}

.tooltipValue{
    font-weight: 650;
    margin-left: 2px;
}

.numberCircle{
    /* border-radius: 50%;
    width: 15px;
    height: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(var(--alpha) / 15%);
    font-size: 10px;
    font-weight: 600; */
    height: 15px;
    min-width: 15px;
    border-radius: 50%;
    background-color: var(--ndp02);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--border-00);
    font-size: 10px;
}

.sankeyDataLabelWrap{
    width: fit-content;
    max-width: 200px;
    white-space: pre-wrap;
    background-color: var(--sdp02);
    border: 4px solid white;
    color: white;
    padding: 8px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    flex-direction: column;
}

.sankeyDataLabelName{
    font-size: 12px;
    font-weight: 600;
    color: var(--alpha-text);
}

.sankeyDataLabelCount{
    font-size: 14px;
    font-weight: 400;
    color: var(--alpha-text);
}

.datalabel{
    max-width: 100px;
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
}

.tooltipWrapV2{
    display: flex;
    flex-direction: column;
    font-size: 13px;
    color: rgb(255 255 255);
    overflow: hidden;
}

.valueTag{
    background-color: rgb(var(--alpha) / 7.5%);
    font-family: var(--mono-font);
    color: white;
    padding: 2px 4px;
    border-radius: 2px;

}

.tooltipText.title{
    font-size: 13px;
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
}

.tooltipLine{
    margin: 2px 0;
    display: inline-flex;
    align-items: center;
    gap: 4px;
    font-size: 12px;
}

/* .tooltipText{
    max-width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
} */

.tooltipLine.gray{
    color: rgb(255 255 255 / 60%);
}
