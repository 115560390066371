/* base select */
.select{
    display: flex;
    align-items: center;
    background-color: transparent;
    border: 1px solid var(--border-00);
    color: white;
    border-radius: 5px;
    height: 36px;
    font-size: 13px;
    font-weight: 400;
    text-align: left;
    position: relative;
    outline: none;
    transition-property: background-color, color, border;
    transition-duration: 100ms;
    padding: 0;
    overflow: hidden;
    cursor: text;
}

div.select:hover input.selectInput::placeholder{
    color: rgb(var(--primary));
}

.select input.selectInput{
    background-color: transparent;
    border: none;
    outline: none;
    color: white;
    padding: 0 8px 0 8px;
    height: 100%;
}

button.select{
    cursor: pointer;
}

/* select active styles */

.select:hover, .select.isOpen{
    background-color: rgb(var(--primary) / 5%);
    color: rgb(var(--primary));
    border: 1px solid rgb(var(--primary) / 5%);
}

.select:hover .selectText, .select.isOpen .selectText, .select:hover .selectText.placeholder, .select.isOpen .selectText.placeholder{
    color: rgb(var(--primary));
}

.select:hover .arrowWrap, .select.isOpen .arrowWrap{
    color: rgb(var(--primary) / 80%);
}

.select.numeric:hover:not(.isOpen){
    background-color: rgb(var(--primary) / 5%);
    border: 1px solid rgb(var(--primary) / 5%);
}

.select.numeric:hover:not(.isOpen) .selectInput{
    color: rgb(var(--primary));
}

.select.numeric.isOpen{
    background-color: transparent;
    border: 1px solid rgb(var(--primary));
    box-shadow: 0 0 5px 3px rgb(var(--primary) / 10%);
}

.select.right{
    width: 250px;
}

.select .selectText{
    transition: color 100ms;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    padding: 0 24px 0 8px;

}

.select .selectText.placeholder{
    color: var(--input-placeholder);
}

.select .arrowWrap{
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0px;
    top: 0;
    bottom: 0;
    border-radius: 4px;
    color: rgb(255 255 255 / 50%);
    transition: color 200ms;
    width: 24px;
}
