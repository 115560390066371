.topContent{
    display: flex;
    gap: 16px;
}

.topContent .queryCard{
    min-width: 400px;
    width: 400px;
}

.queryContainer {
    display: flex;
    flex-direction: column;
}

.queryContainer .section {
    margin-bottom: 16px;
}

.queryContainer .section:last-child{
    margin-bottom: 0;
}

.queryContainer .section .sectionInner {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.queryContainer .section .title {
    margin-bottom: 8px;
}

.filter {
    display: flex;
    align-items: center;
    gap: 8px;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* min-width: 50px; */
}

.actions button {
    border: none;
    cursor: pointer;
    color: rgb(255 255 255);
    padding: 4px;
    transition: background-color 200ms;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.actions button:hover {
    background-color: rgb(255 255 255 / 10%);
}

.actions button svg {
    width: 100%;
    height: 100%;
}

/* no results */

.noResultsWrap{
    display: flex;
    width: 100%;
    height: 300px;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
}

.noResultsWrapInner{
    display: flex;
    flex-direction: column;
    align-items: center;
    /* display: flex;
    flex-direction: column;
    align-items: center;
    background: var(--ndp02);
    backdrop-filter: blur(6px);
    padding: 16px;
    border-radius: 4px; */
}
.noResultsWrap .emptyIcon{
    /* width: 40px;
    height: 40px; */
    height: 120px;
    margin-bottom: 16px;
}

.noResultsWrap .emptyIcon svg{
    width: 100%;
    height: 100%;
}


.noResultsWrap .title {
    font-size: 16px;
    font-weight: 500;
}

.noResultsWrap .description{
    font-size: 14px;
    color: rgb(255 255 255 / 40%);
    text-align: center;
    max-width: 350px;
    line-height: 1.4em;
    margin-top: 4px;
}

.noResultsWrap .description a.supportEmail{
    color: rgb(var(--primary));
    transition: opacity 200ms;
}

.noResultsWrap .description a.supportEmail:hover{
    opacity: 0.8;
}

.vert {
    background-color: var(--border-00);
    min-width: 1px;
    /* margin: 0 16px; */
}

.noResultsWrap .errorBtn{
    padding: 4px 20px;
    margin-top: 8px;
}

/* TOP BAR */

.right {
    display: flex;
    align-items: center;
}

.outlineBtn.outlineBtn {
    background-color: transparent;
    border: 1px solid var(--border-00);
    transition: opacity 200ms;
    border-radius: 0;
    height: 32px;
    margin-right: 8px;
}

.outlineBtn.outlineBtn:hover {
    background-color: inherit;
    opacity: 0.75;
}

.addBtn{
    height: 32px;
    background-color: rgb(var(--primary));
}

.addBtn:hover{
    background-color: rgb(var(--primary) / 50%);
}

.queryTypeSelectWrap {
    position: relative;
    border: 1px solid var(--border-00);
    width: 175px;
    height: 32px;
    border-radius: 4px;
}

.queryTypeSelect {
    border-radius: 0;
    font-weight: 500;
    color: var(--alpha-text);
    outline: none;
    font-size: 13px;
    appearance: none;
    padding: 0px 12px;
    line-height: 1em;
    width: 100%;
    height: 100%;
    background: none;
    border: none;
    cursor: pointer;
    position: absolute;
    z-index: 2;
}

.pageSelectIcon {
    width: 20px;
    color: var(--gamma-text);
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    align-items: center;
    z-index: 1;
}

.pageSelectIcon svg {
    width: 100%;
    height: 100%;
}

.chartTop{
    display: flex;
    align-items: center;
    justify-content: space-between;
    white-space: nowrap;
}

.chartTop .right{
    display: flex;
    align-items: center;
    gap: 8px;
}

.tableTop{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 24px;
    border-bottom: 1px solid var(--border-00);
    overflow: auto;
    white-space: nowrap;
}

.tableTop .right{
    display: flex;
    align-items: center;
    gap: 8px;
}

@media screen and (max-width: 900px) {

    .topContent{
        flex-wrap: wrap;
    }

    .topContent .queryCard{
        width: 100%
    }

}