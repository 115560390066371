.container{
    display: flex;
    height: calc(100% - var(--header-size));
}

.container .sidebar{
    height: 100%;
    border-right: 1px solid rgb(255 255 255 / 10%);
    min-width: 350px;
    display: flex;
    flex-direction: column;
    padding: 16px;
}

.events{
    padding: 16px;
    border: 1px solid rgb(255 255 255 / 10%);
}