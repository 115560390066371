.outerDialog.outerDialog{
    width: 500px;
    margin: 100px auto 100px auto;
    background-color: rgb(var(--background));
    border-radius: 4px;
    overflow: hidden;
}

.innerDialog.innerDialog {
    padding: 20px;
    background-color: rgb(255 255 255 / 2.5%);
    border: 1px solid rgb(255 255 255 / 5%);
}

.titleWrap{
    margin: -20px -20px 20px -20px;
    background-color: rgb(255 255 255 / 5%);
    padding: 12px 20px;
    color: rgb(255 255 255 / 50%);
    font-weight: 400;
    
}

.title{
    font-size: 16px;
    font-weight: 500;
}

.description{
    font-size: 13px;
    font-weight: 400;
    color: rgb(255 255 255 / 40%);
    margin: 8px 0;
}

.labelWrap{
    display: flex;
    align-items: center;
    gap: 8px;
    margin-top: 16px;
}

.labelWrap .inputLabel{
    font-weight: 400;
    color: rgb(255 255 255 / 50%);
}

.labelWrap .optional{
    font-size: 12px;
    color: rgb(255 255 255 / 25%);
}

.input{
    border: 1px solid rgb(255 255 255 / 2.5%);
    background-color: rgb(255 255 255 / 2.5%);
    color: white; 
    border-radius: 5px;
    height: 36px;
    font-size: 13px;
    font-weight: 400;
    outline: none;
    transition-property: background-color, color, border;
    transition-duration: 100ms;
    overflow: hidden;
    width: 100%;
    margin-top: 8px;
    padding: 0 8px;
}

.input:hover{
    border: 1px solid rgb(var(--primary) / 70%);
}

.input.isOpen{
    background-color: transparent;
    border: 1px solid rgb(var(--primary) / 70%);
    box-shadow: 0 0 5px 3px rgb(var(--primary) / 10%);
}

.textarea{
    resize: none;
    background-color: rgb(255 255 255 / 2.5%);
    border: 1px solid rgb(255 255 255 / 2.5%);
    color: white;
    border-radius: 5px;
    height: 200px;
    font-size: 13px;
    font-weight: 400;
    outline: none;
    transition-property: background-color, color, border;
    transition-duration: 100ms;
    overflow: hidden;
    width: 100%;
    margin-top: 8px;
    padding: 8px;
}

.textarea:hover{
    border: 1px solid rgb(var(--primary) / 70%);
}

.textarea.isOpen{
    background-color: transparent;
    border: 1px solid rgb(var(--primary) / 70%);
    box-shadow: 0 0 5px 3px rgb(var(--primary) / 10%);
}