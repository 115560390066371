.actions{
    display: flex;
    justify-content: flex-end;
    gap: 8px;
}

.actions .outline{
    background-color: transparent;
    border: none;
    color: white;
    border-radius: 5px;
    height: 36px;
    font-size: 13px;
    font-weight: 400;
    outline: none;
    transition-property: background-color, color, border;
    transition-duration: 100ms;
    overflow: hidden;
    margin-top: 8px;
    padding: 0 16px;
    cursor: pointer;
}

.actions .outline:hover{
    background-color: rgb(var(--primary) / 7.5%);
    color: rgb(var(--primary));
}

.actions .fill{
    background-color: rgb(var(--primary));
    border: none;
    color: white;
    border-radius: 5px;
    height: 36px;
    font-size: 13px;
    font-weight: 400;
    outline: none;
    transition-property: background-color, color, border;
    transition-duration: 200ms;
    overflow: hidden;
    margin-top: 8px;
    padding: 0 16px;
    cursor: pointer;
}

.actions .fill:hover{
    background-color: rgb(var(--primary) / 70%);
    border: none;
}