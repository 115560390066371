.selectorWrap {
    display: flex;
    flex-direction: column;
    width: 150px;
    background-color: var(--ndp02);
}

.selectorWrap .daysInput {
    width: 100%;
    background: var(--ndp02);
    border: none;
    border-bottom: 1px solid var(--border-01);
    outline: none;
    height: 36px;
    padding: 0 12px;
    color: var(--alpha-text);
}


.selectorWrap .daysInput::placeholder {
    color: var(--input-placeholder);
}

.selectorBody {
    display: flex;
    flex-direction: column;
}

.selectorWrap .option {
    border: none;
    text-align: left;
    padding: 10px 12px;
    cursor: pointer;
    outline: none;
}

.selectorWrap .option:hover {
    background-color: var(--ndp02);
}

.selectorWrap .option.selected {
    background-color: var(--ndp02);
    color: var(--alpha-text)
}

.selectorWrap .option.optionHighlighted{
    background-color: var(--ndp02);
}

.placeholder{
    color: var(--input-placeholder);
}

.icon{
    position: absolute;
    right: 4px;
    top: 50%;
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateY(-50%);
}

.topButton{
    outline: none;
}

.warningInline{
    padding: 10px;
    font-size: 12px;
    color: rgb(var(--warning));
    background: rgb(var(--warning) / 10%);
}