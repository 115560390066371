.dropdown{
    background-color: rgb(var(--background));
    border: 1px solid rgb(255 255 255 / 5%);
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    max-height: 350px;
    position: relative;
}

.dropdown .linearProgressWrap{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 4px;
    z-index: 2;
}

.dropdown .search{
    background-color: rgb(255 255 255 / 2.5%);
    border-radius: 8px;
    min-height: 36px;
    padding: 0 8px;
    outline: none;
    color: white;
    border: 1px solid transparent;
    transition-property: background-color, border;
    transition-duration: 200ms;
    margin: 8px 8px 4px 8px;
}

.dropdown .dropdownHeader{
    display: flex;
    align-items: center;
    padding: 4px 8px 4px 8px;
    margin-bottom: 4px;
}

.dropdown .dropdownHeader .dropdownButton{
    border: none;
    padding: 4px 6px;
    font-size: 11px;
    cursor: pointer;
    transition-property: color, background-color;
    transition-duration: 200ms;
    border-radius: 4px;
}

.dropdown .dropdownHeader .dropdownButton:hover{
    background-color: rgb(var(--primary) / 10%);
    color: rgb(var(--primary));
}

.dropdown .search:hover{
    border: 1px solid rgb(var(--primary) / 50%);
    background-color: rgb(var(--primary) / 5%);
}

.dropdown .search:focus{
    border: 1px solid rgb(var(--primary) / 50%);
    background-color: rgb(var(--primary) / 5%);
}

.dropdown .dropdownTop{
    display: flex;
    padding: 0px 16px 8px 16px;
}

.dropdown .dropdownTop .dropdownTopFilter{
    font-size: 12px;
    font-weight: 300;
}

.dropdown .dropdownTop .dropdownTopFilter .dropdownTopButton{
    color: rgb(var(--primary));
    font-size: 12px;
    font-weight: 300;
    border: none;
    padding: 0;
    cursor: pointer;
    transition-property: color;
    transition-duration: 100ms;
    margin-left: 4px;
    outline: none;
}

.dropdownTopSelectOption.dropdownTopSelectOption{
    font-size: 12px;
    padding: 8px;
}

.dropdown .dropdownTop .dropdownTopFilter .dropdownTopButton:hover{
    color: rgb(var(--primary) / 75%);
}

.dropdown .dropdownContent{
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    gap: 4px;
    height: 100%;
    padding-bottom: 8px;
    margin-top: 8px;
}

/* this looks weird on selects */
/* .dropdown .dropdownContent::-webkit-scrollbar{
    width: 8px;
    height: 8px;
}

.dropdown .dropdownContent::-webkit-scrollbar-track{
    background-color: rgb(var(--alpha) / 5%);
}

.dropdown .dropdownContent::-webkit-scrollbar-thumb{
    background-color: rgb(var(--alpha) / 15%);
    border-radius: 4px;
}

.dropdown .dropdownContent::-webkit-scrollbar-corner { 
    background-color: rgb(var(--alpha) / 5%)
} */


.dropdown .dropdownContent .event{
    display: flex;
    border: none;
    padding: 10px 8px;
    /* gap: 8px; */
    align-items: center;
    cursor: pointer;
    outline: none;
    font-size: 13px;
    margin: 0 8px;
    border-radius: 4px;
}

.dropdown .dropdownContent .event .icon{
    min-width: 16px;
    min-height: 16px;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 8px;
}

.dropdown .dropdownContent .event .icon svg{
    width: 100%;
    height: 100%;
}

.dropdown .dropdownContent .event.highlighted{
    background-color: rgb(var(--primary) / 5%);
    color: rgb(var(--primary));
}

.dropdown .dropdownContent .event.highlighted .icon{
    color: rgb(var(--primary));
}

.dropdown .dropdownContent .event.active{
    background-color: rgb(var(--primary) / 15%);
    color: rgb(var(--primary));
}

.dropdown .dropdownContent .event.active .icon{
    color: rgb(var(--primary));
}

.sectionTitleWrap{
    display: flex;
    align-items: center;
    padding: 0 14px;
    border: none;
    cursor: pointer;
    font-size: 13px;
}

.sectionTitleWrap .sectionArrow{
    width: 20px;
    height: 20px;
    color: rgb(255 255 255 / 25%);
}

.sectionTitleWrap .sectionArrow.isOpen{
    transform: rotate(-90deg);
}

.sectionTitleWrap .sectionArrow svg{
    width: 100%;
    height: 100%;
}

.sectionTitleWrap .sectionTitle{
    font-size: 11px;
    color: rgb(255 255 255 / 25%);
    font-weight: 400;
}

.supertype{
    color: rgb(var(--alpha) / 60%);
    white-space: nowrap;
}

.eventName{
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.supertypeIcon{
    height: 10px;
    width: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(var(--alpha) / 60%);
    margin: 0 4px;
}

.supertypeIcon svg{
    width: 100%;
    height: 100%;
}

.supertypeIconOfficial{
    width: 15px;
    height: 15px;
    object-fit: contain;
}

.loading{
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
}

.loading .loadingText{
    /* color: rgb(var(--primary)); */
    font-size: 13px;
}
