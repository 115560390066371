.cohortsWrap{
    width: 100%;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    transition: border 100ms;
}

.cohortsWrap.lastGroupHovered{
    border-bottom: 1px solid rgb(255 255 255 / 20%);
}

.cohortsWrap .title{
    padding: 16px 0 0 16px;
}

.group{
    border: 1px solid rgb(255 255 255 / 10%);
    padding: 24px 16px;
    position: relative;
    border-bottom: 1px solid rgb(255 255 255 / 10%);
    border-bottom: none;
}

.group.firstGroup{
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.group.lastGroup{
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    border-bottom: 1px solid rgb(255 255 255 / 10%);
}

.group.beforeOr{
    margin-bottom: 24px;
    border-bottom: 1px solid rgb(255 255 255 / 10%);
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.group.afterOr{
    margin-top: 24px;
    border-top: 1px solid rgb(255 255 255 / 10%);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.groupAdd{
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    position: absolute;
    bottom: -18px;
    left: 0;
    right: 0;
    z-index: 1;
}

.groupAdd .groupAddIcon{
    width: 16px;
    color: rgb(255 255 255 / 25%);
    transition: opacity 100ms;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: calc(50% - 10px);
}

.groupAdd .groupAddIcon svg{
    width: 100%;
    height: 100%;
}

.groupAddHighlightLine{
    height: 1px;
    z-index: 0;
    position: absolute;
    width: 100%;
}

.groupAdd:hover .groupAddHighlightLine{
    background-color: rgb(255 255 255 / 10%);
    width: 100%;
    height: 1px;
    top: 50%;
    position: absolute;
}

.groupAdd:hover .groupAddIcon{
    opacity: 0;
}

.groupAddButton{
    opacity: 0;
    transition: opacity 100ms, box-shadow 100ms, color 100ms, border 100ms;
    padding: 6px 6px;
    border: 1px solid rgb(255 255 255 / 10%);
    background-color: rgb(var(--background));
    z-index: 1;
    cursor: pointer;
    border-radius: 4px;
    display: flex;
    align-items: center;
    box-shadow: inset 0 0 100px 100px rgba(255, 255, 255, 0.0);
}

.groupAddButton:hover {
    box-shadow: inset 0 0 100px 100px rgb(var(--primary) / 0.05);
    color: rgb(var(--primary));
    border: 1px solid rgb(var(--primary) / 10%);
}

.groupAdd:hover .groupAddButton{
    opacity: 1;
}

.groupAddButton .groupAddButtonIcon{
    width: 10px;
    color: white;
    margin-right: 6px;
    transition: color 100ms;
}

.groupAddButton:hover .groupAddButtonIcon{
    color: rgb(var(--primary));
}

.groupLine{
    height: 1px;
    background-color: rgb(255 255 255 / 10%);
}

.groupOperator{
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 36px;
    position: absolute;
    bottom: -18px;
    left: 0;
    right: 0;
    z-index: 1;
}

.groupOperator.or{
    height: 48px;
    top: calc(100% + 1px);
}

.groupOperatorButton{
    /* background-color: rgb(var(--primary) / 10%); */
    background-color: var(--primary-solid-05);
    border: none;
    padding: 4px 8px;
    border-radius: 4px;
    color: rgb(var(--primary));
    cursor: pointer;
    font-size: 14px;
}

.filtersFlex{
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.filter {
    display: flex;
    align-items: center;
    gap: 8px;
    z-index: 2;
}

.subfilters {
    padding-left: 16px;
    border-left: 4px solid rgb(255 255 255 / 10%);
    margin: 8px 0px 8px 32px;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.actions {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}


.actions button {
    border: none;
    cursor: pointer;
    color: rgb(255 255 255);
    padding: 4px;
    transition: background-color 200ms;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.actions button:hover {
    background-color: rgb(var(--primary) / 10%);
    color: rgb(var(--primary));
}

.actions button svg {
    width: 100%;
    height: 100%;
}

.linePretext{
    color: rgb(255 255 255 / 50%);
    min-width: 40px;
    text-align: right;
    margin-right: 4px;
}

.linePretextButton{
    border: none;
    min-width: 40px;
    background-color: transparent;
    color: rgb(255 255 255 / 50%);
    cursor: pointer;
    transition: background-color 100ms, color 100ms;
    height: 36px;
    border-radius: 5px;
    text-align: right;
    margin-right: 4px;
}

.linePretextButton:hover{
    background-color: rgb(var(--primary) / 10%);
    color: rgb(var(--primary));
}

.linePretextButton.active{
    background-color: rgb(var(--primary) / 10%);
    color: rgb(var(--primary));
}

.add {
    display: flex;
    align-items: center;
    border: none;
    gap: 4px;
    border-radius: 4px;
    color: rgb(var(--primary));
    margin-top: 8px;
    padding: 4px 8px;
    cursor: pointer;
    width: fit-content;
    transition-property: background-color, color;
    transition-duration: 200ms;
    z-index: 2;
}

.add.only{
    background-color: rgb(var(--primary) / 5%);
    margin-top: 0;
}

.add.only:hover{
    background-color: rgb(var(--primary) / 10%);
}

.add:hover {
    background-color: rgb(var(--primary) / 5%);
}

.add .plus {
    font-size: 18px;
}
