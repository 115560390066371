.card {
    border: 1px solid rgb(255 255 255 / 0.05);
}

.topUserHeader {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 0;
}

.iconContainer {
    border-radius: 50%;
    background-color: rgb(var(--alpha) / 5%);
    overflow: hidden;
    height: 64px;
    width: 64px;
}

.iconContainer .identicon {
    width: 64px !important;
    height: 64px !important;
    min-width: 64px;
    min-height: 64px;
    border-radius: 4px;
    border: 1px solid rgb(var(--alpha) / 5%);
    overflow: hidden;
    background-color: rgb(var(--alpha) / 2.5%);

}

.topUserHeaderText {
    font-size: 20px;
    margin-top: 12px;
    font-weight: 500;
}

.userBlock {
    display: flex;
    flex-direction: column;
    padding: 16px;
    border-top: 1px solid rgb(255 255 255 / 0.05);
    border-bottom: 1px solid rgb(255 255 255 / 0.05);
}

.userBlockTitle {
    font-size: 16px;
    font-weight: 500;
}

.userBlockValue {
    font-size: 14px;
    color: rgb(255 255 255 / 0.5);
    font-weight: 400;
    margin-top: 4px;

}

.userBlockValueWrap {
    display: flex;
    flex-wrap: wrap;
    margin-top: 4px;
    gap: 8px;
}

.userBlockValueWrap .userBlockItemTag {
    color: rgb(var(--primary));
    background-color: rgb(var(--primary) / 0.1);
    padding: 4px;
    border-radius: 4px;
    font-weight: 500;
}

.userBlockShowMore {
    color: rgb(255 255 255 / 50%);
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition-property: background-color, color;
    margin-top: 8px;
    text-align: left;
    padding: 0;
    display: flex;
    align-items: center;
    width: fit-content;
}

.userBlockShowMore:hover {
    color: rgb(255 255 255 / 100%);
}

.userBlockShowMore .userBlockItemShowMoreIcon {
    width: 12px;
    height: 10px;
    margin-left: 8px;
    margin-top: 1px;
}

.collapsedSection {
    border-bottom: 1px solid rgb(255 255 255 / 0.05);
}

.collapsedSection:last-child {
    border-bottom: none;
}

.collapsedCell{
    display: flex;
    width: 100%;
    padding: 8px 0;
    gap: 8px;
    border-bottom: 1px solid rgb(255 255 255 / 0.05);
    font-size: 12px;
}

.collapsedCell:last-child{
    border-bottom: none;
}

.collapsedCell .collapsedTitle{
    color: rgb(255 255 255 / 0.5);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 175px;
    padding-top: 3px;
    line-height: 1em;
}

.collapsedCell .collapsedText{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.collapsedTopTitle {
    font-size: 16px;
    margin-bottom: 8px;
}

.collapsedCellValueWrap{
    white-space: wrap;
    overflow: hidden;
    text-overflow: ellipsis;
    word-wrap: break-word;
    text-align: left;
    padding: 0;
    line-height: 1.5em;
    /* transition-property: color;
    transition-duration: 100ms; */
}

/* .collapsedCellValueWrap:hover{
    color: rgb(var(--primary));
    cursor: pointer;
} */
/* 
.collapsedCellValueWrap{
    position: relative;
    width: 100%;
}

.expandedText{
    position: absolute;
    left: 0;
    top: 0;
    background-color: rgb(255 255 255 / 0.05);
    border: 1px solid rgb(255 255 255 / 0.05);
    z-index: 1;
    padding: 8px;
    width: 100%;
} */

.chainLogo{
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background-color: rgb(var(--alpha) / 2.5%);
    border: 1px solid rgb(var(--alpha) / 5%);
    overflow: hidden;
}

.propertiesInner {
    display: flex;
    flex-wrap: wrap;
}

.propertiesCell {
    position: relative;
    display: flex;
    flex-direction: column;
    width: calc(100%/3);
    border: none;
    border-bottom: 1px solid rgb(255 255 255 / 0.05);
    padding: 14px;
    /* border-right: 1px solid rgb(255 255 255 / 0.05); */
    transition-property: background-color;
    transition-duration: 100ms;
    cursor: pointer;
}

.propertiesCell:hover {
    background-color: rgb(255 255 255 / 0.05);
    padding-right: 42px;
}

.copyIcon {
    width: 14px;
    height: 14px;
    position: absolute;
    right: 12px;
    bottom: 12px;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 100ms;
}

.propertiesCell:hover .copyIcon {
    opacity: 1;
}

.propertiesCell.lastRow {
    border-bottom: none;
}

.propertiesCell.lastCol {
    border-right: none;
}

.propertiesCell .propertiesTitle {
    color: rgb(255 255 255 / 0.5);
    font-size: 12px;
    margin-bottom: 4px;
}

.propertiesCell .propertiesText {
    font-size: 14px;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 100%;
    text-align: left;
}


.contextInner {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 0;
}

.contextInner .contextCell {
    display: flex;
    align-items: center;
    gap: 4px;

}

.contextInner .contextCell .contextTitle {
    color: rgb(255 255 255 / 0.5);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 175px;
}

.contextInner .contextCell .contextText {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.topLinks {
    display: flex;
    align-items: center;
    gap: 8px;
    margin-bottom: 16px;
    font-size: 14px;
}

.topLinks .filterButton {
    border: none;
    cursor: pointer;
    transition-property: background-color, color;
    transition-duration: 200ms;
    padding: 8px 6px;
    background-color: transparent;
    border-bottom: 2px solid transparent;
}

.topLinks .filterButton.active {
    color: rgb(var(--primary));
    border-bottom: 2px solid rgb(var(--primary));
}

.topLinks .filterButton:not(.active):hover {
    color: rgb(var(--primary));
}

.bodyLoadingWrap{
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    border: 1px solid rgb(255 255 255 / 0.05);
    width: 100%;
}

.errorWrap{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.errorCard{
    border: 1px solid rgb(255 255 255 / 0.05);
    height: 325px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.errorCard .errorTitle{
    font-size: 20px;
    font-weight: 500;
}

.errorCard .errorSubtitle{
    font-size: 13px;
    color: rgb(255 255 255 / 0.5);
    font-weight: 400;
    line-height: 1.25em;
    margin: 12px 0 16px 0;
    text-align: center;
}

.errorCard .errorButton{
    /* color: rgb(var(--primary)); */
    display: inline-block;
    color: white;
    background-color: rgb(var(--primary));
    border-radius: 4px;
    padding: 8px;
}

.errorCard .errorButton .errorButtonIcon{
    width: 16px;
    height: 16px;
    margin-right: 4px;
}

.errorCard .errorButton .errorButtonIcon svg{
    width: 100%;
    height: 100%;
}

.errorCard .errorLink{
    text-decoration: underline;
}

.timelineColumn{
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 0;
}

.timelineColumn .timelineHeader{
    padding: 0 16px;
}

.timelineColumn .timelineItemDate{
    font-size: 14px;
    color: white;
    font-weight: 400;
}

.subTimeline{
    display: flex;
    flex-direction: column;
    margin-top: 8px;
}

.subTimeline .eventRow{
    display: flex;
    font-size: 12px;
    align-items: center;
    border: none;
    cursor: pointer;
    overflow: hidden;
    width: 100%;
    padding: 8px 16px;
}

.subTimeline .eventRow:hover{
    background-color: rgb(255 255 255 / 0.05);
}

/* .subTimeline .eventRow.selected{
    background-color: rgb(var(--primary) / 0.1);
    color: rgb(var(--primary));
}

.subTimeline .eventRow:not(.selected):hover{
    background-color: rgb(255 255 255 / 0.05);
} */

.subTimeline .iconCol{
    min-width: 20px;
    height: 16px;
    color: rgb(255 255 255 / 0.5);
    text-align: left;
}
/* 
.subTimeline .eventRow.selected .iconCol{
    color: rgb(var(--primary));
} */

.subTimeline .iconCol svg{
    width: 14px;
    height: 16px;

}

.subTimeline .timeCol{
    min-width: 90px;
    color: rgb(255 255 255 / 0.5);
    text-align: left;
}

/* .subTimeline .eventRow.selected .timeCol{
    color: rgb(var(--primary));
} */

.subTimeline .textCol{
    flex: 1;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.subTimeline .sessionBreak{
    padding: 16px 16px 16px 20px;
    display: flex;
}

.subTimeline .sessionBreak .sessionLine{
    width: 4px;
    background-color: rgb(255 255 255 / 0.2);
    border-radius: 4px;
    height: 30px;
}

.eventHeader{
    display: flex;
    flex-direction: column;
    color: rgb(255 255 255 / 0.5);
    margin-bottom: 16px;
}

.eventData{
    padding: 8px 48px 16px 128px;
}

.eventBody{

}

.eventBodyButton{
    font-size: 12px;
    border: 1px solid var(--border-01);
    padding: 0px 8px;
    cursor: pointer;
    margin-right: -1px;
    z-index: 0;
    position: relative;
    height: 32px;
}

.eventBodyButton.large{
    font-size: 13px;
    padding: 0px 12px;
    height: 36px;
}

.eventBodyButton.active{
    border: 1px solid var(--primary-border);
    background: rgb(var(--primary) / 10%);
    color: rgb(var(--primary));
    z-index: 1;
    border-radius: 4px;
}

.eventBodyButton:first-child{
    border-radius: 4px 0 0 4px;
}

.eventBodyButton:last-child{
    border-radius: 0 4px 4px 0;
}

.eventBottom{
    display: flex;
    justify-content: center;
    padding: 32px 0 0 0;
}

.eventBottomInner{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loadBtn{
    border: 1px solid rgb(255 255 255 / 0.1);
    width: fit-content;
    color: rgb(255 255 255);
    padding: 12px 16px;
    border-radius: 4px;
    white-space: nowrap;
    cursor: pointer;
    transition-property: background-color, border-color;
    transition-duration: 200ms;
    outline: none;
    margin-top: 16px;
}

.loadBtn:hover{
    background-color: rgb(255 255 255 / 0.05);
    border: 1px solid rgb(255 255 255 / 0.05);
}

.topActivity{
    margin-bottom: 16px;
}

/* .top{
    margin-bottom: 16px;
}

.topHeader{
    display: flex;
    align-items: center;
    gap: 12px;
    margin-bottom: 16px;
}

.topHeaderText{
    display: flex;
    flex-direction: column;
}

.topHeaderText .title{
    font-size: 20px;
}

.topHeaderText .subtitle{
    font-size: 14px;
    color: rgb(255 255 255 / 0.5);
    font-weight: 400;
}

.iconContainer{
    display: flex;
    align-items: center;
}

.identicon {
    width: 36px !important;
    height: 36px !important;
    min-width: 36px;
    min-height: 36px;
    border-radius: 4px;
    border: 1px solid rgb(var(--alpha) / 5%);
    overflow: hidden;
    background-color: rgb(var(--alpha) / 2.5%);
}


.topLinks{
    display: flex;
    align-items: center;
    gap: 4px;
}

.topLinks .filterButton {
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition-property: background-color, color;
    transition-duration: 200ms;
    padding: 8px 12px;
    background-color: transparent;
}

.topLinks .filterButton.active {
    background-color: rgb(var(--primary) / 5%);
    color: rgb(var(--primary));
    padding: 8px 12px;
}

.topLinks .filterButton:not(.active):hover {
    color: rgb(var(--primary));
}

.topLinks .topLink{
    cursor: pointer;
}

.topLinks .topLink:hover{
    color: rgb(var(--primary));
}

.topLinks .topLink.active{
    color: rgb(var(--primary));
}

.topLinks .topLink .topLinkText{
    margin-bottom: 4px;
}

.highlightBar{
    width: calc(100% + 8px);
    height: 2px;
    border-radius: 4px;
    background-color: rgb(var(--primary));
    margin: 0 -4px;
    margin-bottom: -1px;
}

.activityContainer{
    display: flex;
    gap: 16px;
    height: 100%;
}

.scrollAuto{
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
}

.leftActivity{
    min-width: 350px;
    width: 350px;
    overflow-y: auto;
    border: 1px solid rgb(255 255 255 / 0.05);
}

.linearLoadingWrap{
    position: sticky;
    top: 0;
}

.rightActivity{
    min-width: 350px;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid rgb(255 255 255 / 0.05);
}

.userBody{
    height: 100%;
    overflow: hidden;
}

.timelineColumn{
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 0;
}

.timelineColumn .timelineHeader{
    padding: 0 16px;
}

.timelineColumn .timelineItemDate{
    font-size: 14px;
    color: white;
    font-weight: 400;
}

.subTimeline{
    display: flex;
    flex-direction: column;
    margin-top: 8px;
}

.subTimeline .eventRow{
    display: flex;
    font-size: 12px;
    align-items: center;
    border: none;
    cursor: pointer;
    overflow: hidden;
    width: 100%;
    padding: 8px 16px;
}

.subTimeline .eventRow.selected{
    background-color: rgb(var(--primary) / 0.1);
    color: rgb(var(--primary));
}

.subTimeline .eventRow:not(.selected):hover{
    background-color: rgb(255 255 255 / 0.05);
}

.subTimeline .iconCol{
    min-width: 20px;
    height: 16px;
    color: rgb(255 255 255 / 0.5);
    text-align: left;
}

.subTimeline .eventRow.selected .iconCol{
    color: rgb(var(--primary));
}

.subTimeline .iconCol svg{
    width: 14px;
    height: 16px;

}

.subTimeline .timeCol{
    min-width: 90px;
    color: rgb(255 255 255 / 0.5);
    text-align: left;
}

.subTimeline .eventRow.selected .timeCol{
    color: rgb(var(--primary));
}

.subTimeline .textCol{
    flex: 1;
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.subTimeline .sessionBreak{
    padding: 16px 16px 16px 20px;
    display: flex;
}

.subTimeline .sessionBreak .sessionLine{
    width: 4px;
    background-color: rgb(255 255 255 / 0.2);
    border-radius: 4px;
    height: 30px;
}

.eventHeader{
    display: flex;
    flex-direction: column;
    color: rgb(255 255 255 / 0.5);
    margin-bottom: 16px;
}

.eventTitle{
    font-size: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.eventHeader{
    padding: 16px;
    padding-bottom: 0;
}

.eventTitleWrap{
    display: flex;
    flex-direction: column;
    padding: 0 16px 16px 16px;
    border-bottom: 1px solid rgb(255 255 255 / 0.05);

}

.eventBody{
    padding: 16px 0;
    padding-top: 0;
}

.collapsedSection{
    padding: 12px 16px;
    border-bottom: 1px solid rgb(255 255 255 / 0.05);
}

.collapsedSection:last-child{
    border-bottom: none;
}

.collapsedTopTitle{
    font-size: 16px;
    margin-bottom: 8px;
}

.collapsedInner{
    display: flex;
    flex-direction: column;
    gap: 0 16px;
}

.collapsedCell{
    display: flex;
    align-items: center;
    width: 100%;
    padding: 8px 0;
    gap: 8px;
    border-bottom: 1px solid rgb(255 255 255 / 0.05);
}

.collapsedCell:last-child{
    border-bottom: none;
}

.collapsedCell .collapsedTitle{
    color: rgb(255 255 255 / 0.5);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 175px;
}

.collapsedCell .collapsedText{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.propertiesInner{
    display: flex;
    flex-wrap: wrap;
}

.propertiesCell{
    display: flex;
    flex-direction: column;
    width: 25%;
    border-bottom: 1px solid rgb(255 255 255 / 0.05);
    padding: 12px 12px 12px 0px;
}

.propertiesCell.lastRow{
    border-bottom: none;
}

.propertiesCell .propertiesTitle{
    color: rgb(255 255 255 / 0.5);
    font-size: 12px;
    margin-bottom: 4px;
}

.propertiesCell .propertiesText{
    font-size: 14px;
    color: white;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}


.contextInner{
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding: 16px 0;
}

.contextInner .contextCell{
    display: flex;
    align-items: center;
    gap: 4px;

}

.contextInner .contextCell .contextTitle{
    color: rgb(255 255 255 / 0.5);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    min-width: 175px;
}

.contextInner .contextCell .contextText{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

}

.chainLogo{
    width: 16px;
    height: 16px;
    border-radius: 4px;
    background-color: rgb(var(--alpha) / 2.5%);
    border: 1px solid rgb(var(--alpha) / 5%);
    overflow: hidden;
}

.collapseLink{
    padding: 8px;
    border: 1px solid rgb(255 255 255 / 0.1);
    margin-right: -1px;
    transition-property: background-color, border-color, color;
    transition-duration: 100ms;
}

.collapseLink:hover{
    color: rgb(var(--primary));
    background-color: rgb(var(--primary) / 0.05);
    border: 1px solid rgb(var(--primary) / 1);
    border-radius: 4px;
}

.collapseLinkInline{
    color: rgb(var(--primary));
    text-decoration: underline;
}

.errorWrap{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
}

.errorCard.errorCard{
    width: 400px;
}

.errorCard .errorTitle{
    font-size: 20px;
}

.errorCard .errorSubtitle{
    font-size: 13px;
    color: rgb(255 255 255 / 0.5);
    font-weight: 400;
    line-height: 1.25em;
    margin: 12px 0 16px 0;
}

.errorCard .errorButton{
    color: rgb(var(--primary));
    border-radius: 4px;
    display: flex;
    align-items: center;
}

.errorCard .errorButton .errorButtonIcon{
    width: 16px;
    height: 16px;
    margin-right: 4px;
}

.errorCard .errorButton .errorButtonIcon svg{
    width: 100%;
    height: 100%;
}

.errorCard .errorLink{
    text-decoration: underline;
}

.bodyLoadingWrap{
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
} */