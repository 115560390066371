
.tableWrap{
    width: 100%;
    overflow: auto;
}

.table{
    min-width: 100%;
}

.tableHeaders{
    display: flex;
    align-items: center;
    width: -webkit-fit-content;
    width: fit-content;
    min-width: 100%;
}

.tableRow{
    display: flex;
    align-items: center;
    height: 50px;
    width: -webkit-fit-content;
    width: fit-content;
    min-width: 100%;
}

.tableCell{
    white-space: nowrap;
    padding: 0 16px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.checkboxButton{
    border: none;
    padding: 0 4px 0 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
}

.checkboxButton .checkbox{
    width: 16px;
    height: 16px;
    border: 1px solid rgb(255 255 255 / 0.05);
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent;
}

.checkboxHeader{
    width: 16px;
    height: 16px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: transparent
}

/* .checkboxButton:hover{
    background-color: red;
} */

.checkboxButton:hover .checkbox{
    background-color: rgb(255 255 255 / 0.05);
}

.checkboxButton .checkbox.selected{
    background-color: rgb(var(--primary));
}

.checkboxButton .checkbox svg{
    width: 12px;
    height: 12px;
}

.tableWrapOuter{
    position: relative;
}

.tableTop{

}

.loaderWrap{
    position: absolute;
    left: 0;
    right: 0;
}

.sortableTableHeader{
    border: none;
    padding: 0;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
}

.sortableTableHeader:hover .tableHeaderText{
    color: rgb(255 255 255 / 0.8);
}

.sortableTableHeader .sortIcon{
    display: flex;
    flex-direction: column;
}

.sortableTableHeader .sortIcon svg{
    width: 6px;
    height: 6px;
}

.sortableTableHeader .sortIcon .active{
    color: white;
}

.noResultsWrap{
    display: flex;
    width: 100%;
    height: 400px;
    justify-content: center;
    align-items: center;
    position: relative;
    z-index: 0;
}

.noResultsWrap .noResultsWrapInner{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.noResultsWrap .emptyIcon{
    height: 25px;
    margin-bottom: 16px;

}

.noResultsWrap .emptyIcon svg{
    width: 100%;
    height: 100%;
}

.noResultsWrap .title {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
}

.noResultsWrap .description{
    font-size: 14px;
    color: rgb(255 255 255 / 35%);
    text-align: center;
    max-width: 350px;
    line-height: 1.4em;
    margin-top: 4px;
    font-weight: 300;
}

.noResultsWrap .description a.supportEmail{
    color: rgb(var(--primary));
    transition: opacity 200ms;
}

.noResultsWrap .description a.supportEmail:hover{
    opacity: 0.8;
}

.noResultsWrap .errorBtn{
    padding: 4px 20px;
    margin-top: 8px;
}
