.selectorWrap{
    background-color: rgb(var(--background));
}

.selectorHeader{
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    background: var(--ndp02);
}

.selectorHeader .selectorHeaderButton{
    cursor: pointer;
    width: calc(100%/3);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--border-00);
    padding: 6px 0;
    color: var(--alpha-text);
    white-space: nowrap;
    flex-shrink: 0;
    min-width: 75px;
}

.selectorHeader .selectorHeaderButton.selected{
    background-color: rgb(var(--primary) / 10%);
    color: rgb(var(--primary));
    border: 1px solid var(--primary-border);

}

.selectBtn{
    border: 1px solid var(--border-01);
    padding: 4px 8px;
    cursor: pointer;
    margin-right: -1px;
    z-index: 0;
    position: relative;
    height: 36px;
    border-radius: 5px;
    white-space: nowrap;   
    align-items: center;
    display: flex; 
    gap: 8px;
    outline: none;
}

.selectBtn.selected{
    background-color: rgb(var(--primary) / 10%);
    border: 1px solid var(--primary-border);
    color: rgb(var(--primary));
}

.selectBtn .selectIcon{
    width: 15px;
    height: 15px;
}

.lastWrap{
    display: flex;
    align-items: center;
    padding: 10px 12px;
    gap: 8px;
}

.lastDateBtn{
    background-color: var(--ndp02);
    border: 1px solid var(--border-00);
    border-radius: 5px;
    height: 26px;
    padding: 0 30px 0 10px;
    font-size: 14px;
    font-weight: 400;
    transition: border 200ms;
    overflow-x: hidden;
    cursor: pointer;
    position: relative;
}

.lastDateArrow{
    position: absolute;
    right: 10px;
    top: 50%;
    width: 10px;
    height: 10px;
    border: 1px solid var(--border-00);
    border-top: none;
    border-right: none;
    transform: translateY(-50%), rotate(45deg);
    transition: border 200ms;
}

.bottomWrap{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 8px 8px;
    background: var(--ndp02);
    /* margin-top: 8px; */
    gap: 8px;
 }


 .applyButton{
    background-color: rgb(var(--primary));
    color: white;
    padding: 7px 12px;
    font-size: 13px;
    cursor: pointer;
    border: none;
 }

 .outlineButton{
    background-color: transparent;
    color: var(--alpha-text);
    border: 1px solid var(--border-01);
    padding: 7px 12px;
    font-size: 13px;
    cursor: pointer;
 }
