
/* tooltip */


.normalTooltipWrap{
    display: flex;
    flex-direction: column;
    background-color: var(--sdp02);
    padding: 8px;
    border-radius: 2px;

}

.normalInfoWrap{
    display: flex;
    align-items: flex-start;
    gap: 7px;
}


.tooltipText{
    font-size: 12px;
    color: white;
    line-height: 1em;
    display: flex;
    align-items: center;
}

.tooltipTitle{
    max-width: 150px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.tooltipTextSub{
    font-size: 12px;
    color: rgb(var(--alpha) / 60%)
}

.tooltipWrap {
    display: flex;
    flex-direction: column;
}

.tooltipTime {
    display: flex;
    align-items: center;
    font-size: 11px;
}

.tooltipValueWrap{
    color: var(--alpha-text);
    padding-bottom: 8px;
    font-size: 12px;
}

.tooltipValue{
    font-weight: 650;
    margin-left: 2px;
}

.dot{
    min-width: 10px;
    min-height: 10px;
    border-radius: 5px;
    margin-top: 1px;
}