.outerContent{
    overflow-y: auto;
    height: calc(var(--doc-height) - var(--header-size));
    position: relative;
}

.content{
    padding: 36px;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
    position: relative;
}

.content.noPadding{
    padding: 0;
}

.content.column{
    display: flex;
    flex-direction: column;
    gap: 24px;
}