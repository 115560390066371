.wrap{
    min-height: var(--doc-height);
}

.stats{
    display: flex;
    align-items: center;
    gap: 16px;
    margin-bottom: 24px;
}

.stats .stat{
    width: calc(100% / 3);
    border: 1px solid rgb(255 255 255 / 5%);
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 16px;
}

.stats .stat .statIcon{
    background-color: rgb(var(--primary) / 20%);
    color: rgb(var(--primary));
    border-radius: 50%;
    width: 55px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.stats .stat .statIcon svg{
    width: 24px;
    height: 24px;
}

.stats .stat .statText{
    display: flex;
    flex-direction: column;
}

.stats .stat .statLoader{
    /* display: flex;
    flex-direction: column; */
}

.stats .stat .statText .statTitle{
    font-size: 18px;
    color: rgb(255 255 255 / 40%);
}

.stats .stat .statText .statValue{
    font-size: 60px;
    font-weight: 600;
    color: white;
}

.userContainer{
    display: flex;
    align-items: center;

}

.userContainer .userText{
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.userContainer .userIconContainer{
    min-width: 24px;
    min-height: 24px;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 16px;
}

.userContainer .userIconContainer .userIdenticon{
    width: 24px!important;
    height: 24px!important;
}

.userContainer .userIconContainer .userIcon{
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.userContainer .userName{
    font-size: 14px;
    color: rgb(255 255 255 / 40%);
    margin-left: 8px;
}

/* table top */

.tableTop{
    background-color: rgb(255 255 255 / 0.025);
    padding: 12px 16px 12px 16px;
}

.tableTop .tableTopLeft{
    display: flex;
    align-items: center;
    gap: 8px;
}

.tableTop .searchWrap{
    position: relative;
    width: 300px;
}

.tableTop .searchWrap .searchIconWrap{
    position: absolute;
    top: 50%;
    left: 12px;
    width: 12px;
    height: 12px;
    transform: translateY(-50%);
    color: rgb(255 255 255 / 0.3);
}

.tableTop .searchWrap .searchIconWrap svg{
    width: 100%;
    height: 100%;
}

.tableTop .tableTopButton{
    border: none;
    background-color: rgb(255 255 255 / 4%);
    color: white;
    border-radius: 5px;
    height: 36px;
    font-size: 13px;
    font-weight: 400;
    outline: none;
    transition-property: background-color, color, border;
    transition-duration: 100ms;
    overflow: hidden;
    padding: 0 16px;
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;
    border:  1px solid rgb(255 255 255 / 0%);
}

.tableTop .tableTopButton:hover{
    /* background-color: rgb(255 255 255 / 0.075); */
    border: 1px solid rgb(var(--primary) / 70%);
    /* box-shadow: 0 0 5px 3px rgb(var(--primary) / 10%); */
}

.tableTop .tableTopButton .tableTopButtonIconWrap{
    width: 15px;
    height: 15px;
}

.tableTop .tableTopButton .tableTopButtonIconWrap svg{
    width: 100%;
    height: 100%;
}

.tableTop .searchInput{
    border: 1px solid rgb(255 255 255 / 0%);
    background-color: rgb(255 255 255 / 4%);
    color: white;
    border-radius: 5px;
    height: 36px;
    font-size: 13px;
    font-weight: 400;
    outline: none;
    transition-property: background-color, color, border;
    transition-duration: 100ms;
    overflow: hidden;
    padding: 0 8px;
    padding-left: 30px;
    width: 100%;
}

.tableTop .searchInput::placeholder{
    color: rgb(255 255 255 / 0.3);
}

.tableTop .searchInput:hover{
    border: 1px solid rgb(var(--primary) / 70%);
}

.tableTop .searchInput:focus{
    background-color: transparent;
    border: 1px solid rgb(var(--primary) / 70%);
    box-shadow: 0 0 5px 3px rgb(var(--primary) / 10%);
}

.tableWrap{
    border: 1px solid rgb(255 255 255 / 0.05);
    border-radius: 4px;
}

.tableHeaders{
    background-color: rgb(255 255 255 / 0.025);
    border-bottom: 1px solid rgb(255 255 255 / 0.025);
    width: fit-content;
}

.tableHeaderCell{
    font-size: 13px;
    color: rgb(255 255 255 / 0.5);
}

.tableRow{
    border-bottom: 1px solid rgb(255 255 255 / 0.05);
    width: fit-content;
}

.tableRow:last-child{
    border-bottom: none;
}

.tableRow:hover{
    /* background-color: rgb(255 255 255 / 0.025);
    cursor: pointer; */
}

.tableCell{
    padding: 0 16px;
}


.contactRow{
    display: flex;
    align-items: center;
    gap: 12px;
}

.contactSource{
    width: 16px;
    height: 16px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(255 255 255 / 0.5);
    transition-property: color;
    transition-duration: 100ms;
}

.contactSource:hover{
    color: rgb(255 255 255);
}

.contactSource svg{
    width: 100%;
    height: 100%;
}

.contactSourceMore{
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    font-weight: 500;
    color: rgb(255 255 255 / 0.5);
    margin-left: -2px;
}

.eventBottom{
    display: flex;
    justify-content: center;
    padding: 32px 0;
}

.eventBottomInner{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.loadBtn{
    border: 1px solid rgb(255 255 255 / 0.1);
    width: fit-content;
    color: rgb(255 255 255);
    padding: 12px 16px;
    border-radius: 4px;
    white-space: nowrap;
    cursor: pointer;
    transition-property: background-color, border-color;
    transition-duration: 200ms;
    outline: none;
    margin-top: 16px;
}

.loadBtn:hover{
    background-color: rgb(255 255 255 / 0.05);
    border: 1px solid rgb(255 255 255 / 0.05);
}

.stockLoadingButton{
    display: flex;
    align-items: center;
    gap: 8px;
}


.lists{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    gap: 8px;
    height: 100%;
    padding: 0 8px;
    /* transition-property: background-color;
    transition-duration: 100ms; */
}

/* .lists:hover{
    cursor: pointer;
    background-color: rgb(255 255 255 / 0.025);
} */

.lists .list{
    display: flex;
    align-items: center;
    gap: 5px;
    border-radius: 999px;
    padding: 2px 8px;
    border: 1px solid rgb(255 255 255 / 0.1);
    opacity: 1;
    transition: opacity 100ms;
}

.lists .list:hover{
    opacity: 0.8;
    cursor: pointer;
}

.lists .list .listDot{
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: rgb(255 255 255 / 0.5);
}

.lists .list .listName{
    font-size: 12px;
}

.filterBtn{
    display: flex;
    align-items: center;
    border: 1px solid rgb(255 255 255 / 0%);
    background-color: rgb(255 255 255 / 4%);
    color: white;
    border-radius: 5px;
    height: 36px;
    font-size: 13px;
    font-weight: 400;
    outline: none;
    transition-property: background-color, color, border;
    transition-duration: 100ms;
    overflow: hidden;
    padding: 0 12px;
    cursor: pointer;
}

.filterBtn .filterIconWrap{
    width: 15px;
    height: 15px;
    color: white;
    margin-right: 8px;
}

.filterBtn .filterIconWrap svg{
    width: 100%;
    height: 100%;
}

.filterBtn:hover{
    border: 1px solid rgb(var(--primary) / 70%);
}


.filterBtn .filterCount{
    margin-left: 8px;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    background-color: rgb(255 255 255 / 0.1);
    color: white;
    font-size: 11px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dropdownTitle{
    font-size: 11px;
    color: rgb(255 255 255 / 0.5);
    padding: 0 12px;
    margin: 8px 0;
}

.filterDropdown{
    width: 172px;
    display: flex;
    flex-direction: column;
    background-color: rgb(255 255 255 / 0.025);
}

.filterDropdown .filterDropdownItem{
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 12px 12px;
    transition-property: background-color;
    transition-duration: 100ms;
    border: none;
    outline: none;
}

.filterDropdown .filterDropdownItem.filterDropdownItemActive{
    background-color: rgb(255 255 255 / 0.05);
}

.filterDropdown .filterDropdownItem.filterDropdownItemActive .filterText, .filterDropdown .filterDropdownItem.filterDropdownItemActive .filterIcon{
    color: rgb(255 255 255);
}

.filterDropdown .filterDropdownItem:hover .filterText, .filterDropdown .filterDropdownItem:hover .filterIcon{
    color: rgb(255 255 255);
}

.filterDropdown .filterDropdownItem:hover{
    background-color: rgb(255 255 255 / 0.05);
    cursor: pointer;
}

.filterDropdown .filterDropdownItem .filterIcon{
    width: 14px;
    height: 14px;
    color: rgb(255 255 255 / 0.5);
}

.filterDropdown .filterDropdownItem .filterIcon svg{
    width: 100%;
    height: 100%;
}

.filterDropdown .filterDropdownItem .filterText{
    font-size: 13px;
    color: rgb(255 255 255 / 0.5);
}

.filterDropdown .filterDropdownFooter{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 8px;
    padding: 8px;
}

.filterDropdown .filterDropdownFooter .filterDropdownFooterBtn{
    padding: 4px 8px;
    border: none;
    border-radius: 4px;
    white-space: nowrap;
    cursor: pointer;
    transition-property: background-color, border-color;
    transition-duration: 200ms;
    outline: none;
}


.filterDropdown .filterDropdownFooter .filterDropdownFooterBtn.outline{
    border: 1px solid rgb(255 255 255 / 0.05);
    background-color: transparent;
    color: white;
}


.filterDropdown .filterDropdownFooter .filterDropdownFooterBtn.primary{
    border: 1px solid rgb(255 255 255 / 0.05);
    background-color: rgb(var(--primary) / 100%);
    color: white;
}

.filterDropdown .filterInnerDropdown{
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: rgb(255 255 255 / 0.025);
    border-radius: 4px;
    max-height: 150px;
    overflow: auto;
}

.filterDropdown .filterInnerDropdown .filterInnerDropdownItem{
    display: flex;
    align-items: center;
    border: none;
    gap: 8px;
    padding: 12px 12px;
    transition: background-color 100ms;
} 

.filterDropdown .filterInnerDropdown .filterInnerDropdownItem:hover{
    background-color: rgb(255 255 255 / 0.05);
    cursor: pointer;
}

.filterDropdown .filterInnerDropdown .filterInnerDropdownItem:hover .filterInnerCheckbox{
    background-color: rgb(255 255 255 / 0.1);
}

.filterDropdown .filterInnerDropdown .filterInnerDropdownItem .filterInnerCheckbox{
    width: 16px;
    height: 16px;
    border: 1px solid rgb(255 255 255 / 0.1);
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.filterDropdown .filterInnerDropdown .filterInnerDropdownItem .filterInnerCheckbox svg{
    width: 10px;
    height: 10px;
}

.filterDropdown .filterInnerDropdown .filterInnerDropdownItem .filterInnerCheckbox.filterInnerCheckboxActive{
    background-color: rgb(var(--primary) / 100%);
    border: 1px solid rgb(var(--primary) / 100%);
}

.filterDropdown .filterInnerDropdown .filterInnerDropdownItem.filterInnerDropdownItemActive .filterInnerText{
    color: rgb(255 255 255);
}

.filterDropdown .filterInnerDropdown .filterInnerDropdownItem .filterInnerText{
    color: rgb(255 255 255 / 50%);
}


.listDropdown{
    width: 200px;
    max-height: 200px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding: 4px;
}

.listDropdown .listDropdownTitle{
    font-size: 10px;
    padding: 4px 8px;
    color: rgb(255 255 255 / 50%);
    font-weight: 400;
}

.listDropdown .listOptionWrap{
    padding: 4px 8px;
    display: flex;
    align-items: center;
    border-radius: 4px;
    transition-property: background-color;
    transition-duration: 100ms;
}

.listDropdown .listOptionWrap:hover{
    background-color: rgb(255 255 255 / 0.025);
    cursor: pointer;
}

.listDropdown .listOptionTag{
    transition-property: background-color;
    transition-duration: 100ms;
    padding: 4px 6px;
    border-radius: 4px;
    width: fit-content;
    font-size: 11px;
}

.listDropdownCellEditable{
    display: flex;
    align-items: center;
    gap: 8px;
}