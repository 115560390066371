.inputWrap {
    position: relative;
}

.inputWrap .input {
    border-radius: 8px;
    border: 1px solid rgb(255 255 255 / 2.5%);
    background-color: rgb(255 255 255 / 2.5%);
    color: white;
    border-radius: 5px;
    height: 36px;
    font-size: 13px;
    font-weight: 400;
    outline: none;
    transition-property: background-color, color, border;
    transition-duration: 100ms;
    overflow: hidden;
    width: 100%;
    padding: 0 8px;
    padding-left: 32px;
}

.inputWrap .input:hover{
    border: 1px solid rgb(var(--primary) / 70%);
}

.inputWrap .input:focus{
    background-color: transparent;
    border: 1px solid rgb(var(--primary) / 70%);
    box-shadow: 0 0 5px 3px rgb(var(--primary) / 10%);
}

.inputIcon {
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    color: var(--input-placeholder);
    position: absolute;
}

.resultWrap {
    width: 100%;
    height: 350px;
    overflow-y: auto;
    overflow-x: hidden;
}
/* 
.result{
    border-bottom: 1px solid var(--border-00);
}

.result:last-child{
    border-bottom: none;
}

a.result:hover{
    background-color: var(--ndp02);
} */

.resultInner {
    display: flex;
    color: white;
    align-items: center;
    gap: 20px;
    padding: 12px 16px;
    transition: background-color 100ms;
}

.resultInner:hover {
    background-color: var(--ndp02);
}

.resultIcon {
    border-radius: 50%;
    width: 28px;
    height: 28px;
    justify-self: center;
    padding: 2px;
    background-color: var(--ndp02);
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--border-01);
    color: var(--gamma-text);
}

.resultIcon svg {
    width: 18px;
    height: 18px;
}

.resultText {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.resultTitle {
    font-size: 13px;
    font-weight: 500;
}

.resultDescription {
    font-size: 12px;
    color: var(--gamma-text);
}

.actionBtnOuter {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 12px 0;
    align-items: center;
}

.actionBtnsWrap {
    display: flex;
    gap: 4px;
}

.actionBtnsWrap .actionBtn {
    border: 1px solid var(--border-01);
    border-radius: 999px;
    cursor: pointer;
    padding: 5px 12px;
    transition: background-color 200ms;
}

.actionBtnsWrap .actionBtn.selected {
    background-color: var(--ndp02);
}

.actionBtnsWrap .actionBtn:hover {
    background-color: var(--ndp02);
}

.commandTab {
    background-color: rgb(255 255 255 / 10%);
    padding: 4px 6px;
    border-radius: 4px;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    color: rgb(255 255 255);
    position: absolute;
}

.clearBtn {
    /* border-radius: 999px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 12px;
    height: 24px;
    cursor: pointer;
    border: 1px solid var(--border-01);
    transition: background-color 100ms; */
    border: 1px solid var(--border-01);
    border-radius: 999px;
    cursor: pointer;
    padding: 5px 8px;
    transition: background-color 200ms;
    display: flex;
    align-items: center;
    justify-self: center;
    gap: 4px;
}

.clearBtn:hover {
    background-color: var(--ndp02);
}

.clearBtn svg {
    width: 16px;
    height: 16px;
}

.noResults {
    display: flex;
    justify-content: center;
    font-size: 13px;
    align-items: center;
    height: 100%;
    margin-top: -16px;
}

.searchButton {
    display: flex;
    color: white;
    align-items: center;
    gap: 20px;
    padding: 12px 16px;
    transition: background-color 100ms;
    cursor: pointer;
}

.searchButton:hover {
    background-color: var(--ndp02);
}