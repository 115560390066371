.wrap{
    display: flex;
}

.pageWrap {
    margin: 0 auto;
    overflow: auto;
    background: rgb(var(--background));
    width: 100%;
    flex: 1;
    padding: 48px 36px;
    height: calc(var(--doc-height) - var(--header-size));
}

.innerWrap {
}

.copyBtn{
    display: flex;
    align-items: center;
    border: none;
    gap: 8px;
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    transition-property: color;
    transition-duration: 0.2s;
}

.copyBtn:hover{
    color: rgb(var(--primary));
}

.copyIcon{
    height: 16px;
    width: 16px;
}

.copyIcon svg{
    height: 100%;
    width: 100%;
}

.mono {
    font-family: var(--mono-font);
    font-size: 12px;
}

.sidebar {
    max-width: 248px;
    width: 248px;
    min-width: 248px;
    display: flex;
    flex-direction: column;
    height: calc(var(--doc-height) - var(--header-size));
    position: sticky;
    top: 0;
    bottom: 0;
    border-right: 1.5px solid rgb(255 255 255 / 5%);
    background-color: rgb(255 255 255 / 2.5%);
}

.sidebar .line{
    background-color:  var(--border-00);
    margin: 16px 0;
    width: 100%;
    height: 1.5px;
}

.sidebar .userWrap{
    display: flex;
    outline: none;
    padding: 0 10px 0 0;
    margin-top: 0;
    min-height: 60px;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
}

.sidebar .topUtil{
    padding: 0 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 17px;
}

.sidebar .sidebarNewButton:hover{
    background-color: rgb(var(--primary));
}

.sidebar .sidebarNewButton:hover{
    background-color: rgb(var(--primary) / 80%);
}

.sidebar .sidebarAddDashboard{
    background-color: transparent;
    height: 30px;
    margin-top: 16px;

}

.sidebar .sidebarAddDashboard:hover{
    background-color: var(--ndp02);
}

.sidebar .sideLinkWrap{
    display: flex;
    align-items: center;
    position: relative;
    color: white;
    border-radius: 5px;
    z-index: 1;
    margin: 0 8px 2px;
}

.sidebar a.sideLink, .sidebar button.sideLink{
    display: flex;
    align-items: center;
    padding: 0 8px;
    cursor: pointer;
    flex: 1;
    height: 100%;
    min-height: 32px;
    font-weight: 400;
    color: white;
    border-radius: 5px;
}

.sidebar button.sideLink{
    border: none;
    background-color: transparent;
    width: calc(100% - 16px);
    text-align: left;
}

.sidebar .sideLink.selected{
    background-color: rgb(255 255 255 / 10%);
}

.sidebar .sideLink:hover{
    background-color: rgb(255 255 255 / 10%);
}

.sidebar .sideLinkIcon{
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(255 255 255 / 40%)
}

.sidebar .sideLinkIcon svg{
    width: 18px;
    height: 18px;
}

.sidebar .bottom{
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.sidebar .dashboardWrap{
    display: flex;
    padding: 0 16px;
    flex-direction: column;
}

.sidebar .sidebarTitle{
    font-size: 13px;
    line-height: 14px;
    color: rgb(255 255 255 / 40%);
    margin: 16px 16px 8px 16px;
}

.sidebar .sideLinkText{
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    margin-left: 11px;
    flex: 1;
    min-width: 0;
}

.logoutBtnWrap{
    display: flex;
    align-items: center;
    position: relative;
    color: white;
    border-radius: 5px;
    z-index: 1;
    margin: 0 8px 2px;
}


.logoutBtn{
    display: flex;
    align-items: center;
    padding: 0 8px;
    cursor: pointer;
    flex: 1 1;
    min-height: 36px;
    font-weight: 400;
    color: white;
    border-radius: 5px;
    width: 100%;
    border: none;
}


.logoutBtn:hover{
    background-color: rgb(var(--alpha-red) / 15%)
}

.logoutIcon{
    width: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(var(--alpha-red));
}


.logoutIcon svg{
    width: 18px;
    height: 18px;
}

.logoutText{
    font-size: 13px;
    line-height: 16px;
    font-weight: 400;
    margin-left: 11px;
    flex: 1 1;
    min-width: 0;
    text-align: left;    
    color: rgb(var(--alpha-red));

}

.largeTitle{
    font-size: 20px;
    font-weight: 500;
}

.subtitle{
    font-size: 14px;
    font-weight: 400;
    color: rgb(255 255 255 / 50%);
    margin-top: 4px;
}

.subtitle a{
    color: rgb(var(--primary));
    text-decoration: underline;
}

.bigBlock{
}

.block{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border-radius: 5px;
    padding: 24px 0;
}

.settingLine{
    background-color: var(--border-01);
    height: 1px;
    width: 100%;
}

.settingSubtitle{
    font-size: 14px;
    font-weight: 500;
    color: var(--alpha-text);
    margin-bottom: 4px;
}

.settingThirdTitle{
    font-size: 14px;
    font-weight: 500;
    color: var(--gamma-text);
    margin-top: 4px;
}

.redBtn{
    background-color: rgb(var(--alpha-red));
}

.iconWrap{
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border: none;
    overflow: hidden;
    padding: 0;
    cursor: pointer;
}

.iconImg{
    width: 100%;
    height: 100%;
}

.hiddenInput{
    visibility: none;
    position: absolute;
    left: -1000px;
}

.card.card{
    width: 400px;
}

.card .cropWrap{
    position: relative;
    display: inline-block;
    cursor: crosshair;
    overflow: hidden;
    max-width: 100%;
}

.card .imagePreview{
    width: 100%;
    height: 100%;
}

.customInput.error {
    border: 1px solid rgb(var(--alpha-red));
    color: rgb(var(--alpha-red));
    background-color: var(--beta-red);
}

.errorWrap{
    position: absolute;
}

.errorTxt {
    transition: max-height 0.2s ease;
    color: rgb(var(--alpha-red));
    margin-left: 8px;
    margin-top: 7px;
    font-size: 13px;
    margin-bottom: 6px;
}


.inputWrap{
    width: 100%;
    display: flex;
    align-items: center;
    margin-top: 16px;
    gap: 16px;
}

.teamBtn{
    min-width: 100px;
}

.userWrap{
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: rgb(var(--alpha) / 2.5%);
    border-radius: 5px;
    padding: 16px;
    /* width: 100%; */
    margin-top: 16px;
}

.userWrap .col.flex{
    display: flex;
    align-items: center;
}

.userWrap .userTextWrap{
    display: flex;
    flex-direction: column;
    gap: 2px;
}

.userWrap .memberName{
    font-size: 14px;
    font-weight: 550;
}

.userWrap .memberEmail{
    font-size: 14px;
    font-weight: 400;
    color: var(--gamma-text);
}

.userWrap .roleText{
    background-color: var(--ndp01);
    border-radius: 3px;
    padding: 2px;
}

.innerWrap .saveBtn{
    padding: 6px 16px;
}

.inviteBtn{
    border: none;
    color: white;
    background-color: rgb(255 255 255 / 10%);
    padding: 8px 16px;
    border-radius: 4px;
    margin-top: 8px;
    cursor: pointer;
    transition-property: background-color, border-color, color;
    transition-duration: 0.2s;

}

.inviteBtn:disabled{
    opacity: 0.5;
    cursor: not-allowed;
}

.inviteBtn:disabled:hover{
    background-color: rgb(255 255 255 / 10%);
    color: white;
}

.inviteBtn:hover{
    background-color: rgb(255 255 255 / 20%);
    color: white;
    /* color: rgb(var(--primary) / 50%);
    border-color: rgb(var(--primary) / 50%); */
}

.modalCard{
    padding: 0!important;
}

.modalCard .domainInput{
    height: 40px;
    background-color: rgb(255 255 255 / 2.5%);
    border-radius: 4px;
    border: 1px solid rgb(255 255 255 / 5%);
    padding: 0 12px;
    outline: none;
    color: white;
}

.modalCard .domainInput:focus{
    border: 1px solid rgb(255 255 255 / 15%);
}

.modalCard .domainInput.error{
    border: 1px solid rgb(var(--alpha-red));
    color: rgb(var(--alpha-red));
    background-color: var(--beta-red);
}


.modalCard .modalTitle{
    text-transform: uppercase;
    font-weight: 500;
}

.modalCard .modalSubtitle{
    color: rgb(255 255 255 / 50%);
    margin-top: 4px;
}

.modalCard .inputLabel{
    margin-bottom: 8px;
    font-weight: 400;
    font-size: 12px;
}

.modalCard .modalTop{
    padding: 16px;
}

.modalCard .modalBottom{
    padding: 16px;
}

.modalCard .modalWrap{
    padding: 16px;
}

.modalCard .modalInputWrap{
    display: flex;
    flex-direction: column;
}

.modalLine{
    height: 1px;
    width: 100%;
    background-color: rgb(255 255 255 / 5%);
}

.modalBottom{
    padding: 16px 8px 0;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.modalBottom .modalButton{
    padding: 8px 16px;
    background-color: rgb(var(--primary));
    border-radius: 4px;
    border: none;
    color: white;
    cursor: pointer;
    transition-property: background-color, border-color, color;
    transition-duration: 0.2s;
    margin-left: auto;
}

.modalBottom .modalButton:hover{
    background-color: rgb(var(--primary) / 50%);
    color: white;
}

.domainTextWrap{
    display: flex;
    flex-direction: column;
}

.domainTextWrap .domainDate{
    font-size: 12px;
    color: rgb(255 255 255 / 50%);
    margin-top: 4px;
}

.domainRemoveBtn{
    border: none;
    padding: 0;
    color: white;
    transition: all 0.2s ease;
}

.domainRemoveBtn:hover{
    color: rgb(255 255 255 / 70%);
}

.removeIconWrap{
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.removeIconWrap svg{
    width: 16px;
    height: 16px;
}

.domainWrap{
    display: flex;
    background-color: rgb(var(--alpha) / 2.5%);
    margin-top: 16px;
    padding: 16px;
    border-radius: 4px;
    justify-content: space-between;
}


.errorTxt {
    color: rgb(var(--alpha-red));
    margin-left: 8px;
    margin-top: 7px;
    font-size: 13px;
}

.teamLimits{
    font-size: 14px;
    color: var(--gamma-text);
}

